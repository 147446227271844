<template>
<div>
		
		<h1 align="center">欢迎您</h1>
		<div class="go" v-if="username == '' ">
			<!-- <router-link to="/login" class="font">去登录</router-link>
			<br>
			<router-link to="/register" class="font">去注册</router-link> -->

			<ul>
				<li><router-link to="/login" class="font">去登录</router-link></li>
				<li><router-link to="/register" class="font">去注册</router-link></li>
			</ul>
			
		</div>
		<div v-else>
				<h4 align="center">{{ username }}来到学以致用平台</h4>
				<h4 align="center">学习成长值：{{score}}</h4>
				<h4 align="center">诲人不倦值：{{points}}</h4>
				<h4 align="center"><van-button @click="go_out" round style="width: 50%; background-color: #00508f; color: white;">登出</van-button></h4>
				<!-- <el-collapse style="width: 50%;" v-model="activeNames">
			      <el-collapse-item :title="score" name="1">
			        <div>
			          Consistent with real life: in line with the process and logic of real
			          life, and comply with languages and habits that the users are used to;
			        </div>
			        <div>
			          Consistent within interface: all elements should be consistent, such
			          as: design style, icons and texts, position of elements, etc.
			        </div>
			      </el-collapse-item>
				</el-collapse> -->

				<!-- {{menulist}} -->

				<el-tree :data="menulist" :props="defaultProps" node-key="id"  @node-click="getinfo">

					<template #default="{node,data}">

						<span class="custom-tree-node">
							<span>{{node.label}}</span>
							

						</span>
						
						
					</template>

				</el-tree>

				
			<!--	<div style="margin-left: 33%;">
				<el-row class="tac">
				    <el-col :span="12">
				      
				      <el-menu
				        default-active="2"
				        align='center'
				        background-color='#d3d3d3'
				        style="border-right: 0px; "
				      >
				      		

				      	<div v-for="item in data">
				      		
				      		 <el-sub-menu v-if="item.son"> 
				      			<template #title>
				      				<el-menu-item :index="item.id">{{item.name}}</el-menu-item>
				      			</template>
				      		</el-sub-menu>

				      		<el-menu-item v-else :index="item.id" >
				      			{{item.name}}
				      		</el-menu-item> 


				      		<el-sub-menu v-if="item.son" :index="item.did">
				                <template #title>
				                    
				                    <span>{{item.dname}}</span>
				                </template>
				                <left  :data="item.son" />
				                
				            </el-sub-menu>

				            <el-menu-item v-else @click="getinfo(item.did)" :index="item.did">{{item.dname}}</el-menu-item>

				      	</div>


				      	</el-menu>
				    </el-col>
				</el-row>-->

				        <!-- <el-sub-menu index="1">
				          <template #title>
				            
				            <span>Navigator One</span>
				          </template>
				          <el-menu-item-group title="Group One">
				            <el-menu-item index="1-1">item one</el-menu-item>
				            <el-menu-item index="1-2">item one</el-menu-item>
				          </el-menu-item-group>
				          <el-menu-item-group title="Group Two">
				            <el-menu-item index="1-3">item three</el-menu-item>
				          </el-menu-item-group>
				          <el-sub-menu index="1-4">
				            <template #title>item four</template>
				            <el-menu-item index="1-4-1">item one</el-menu-item>
				          </el-sub-menu>
				        </el-sub-menu>
				        <el-menu-item index="2">
	
				          <span>Navigator Two</span>
				        </el-menu-item>
				        <el-menu-item index="3" disabled>
				          
				          <span>Navigator Three</span>
				        </el-menu-item>
				        <el-menu-item index="4">
				          
				          <span>Navigator Four</span>
				        </el-menu-item> -->
				      

		<!-- </div> -->

		</div>
		{{}}

	
</div>	
</template>


<script>

import menutree from "./menutree.vue"
	
export default{

	name:"left",
	props:["data"],

	components:{
		"menutree" : menutree,
	},

	data() {
		return{

			username : "",
			menulist:"",
			score:"",
			points:"",
			defaultProps:{
				children : 'children',
				label:'label'
			},

		}

	},

	// 父子组件传参
	setup(props,{emit}){

		function getinfo(data,node){

			
			// console.log(data)

			if (node.isLeaf === true){
				// console.log(data)
				// alert("123123")

				emit('val',data)

			}else{

				
			}

		}
		return{

			getinfo

		}

	},

	methods:{

		go_out(){
			// 删除浏览器存储的信息
			localStorage.removeItem("username")
			localStorage.removeItem("uid")
			// location.reload()
			this.$router.push({path:"/login"})

		},

		// 获取菜单数据
		get_menu(){

			this.myaxios("/menu/","get",{"uid":localStorage.getItem("uid")})
			.then(data=>{
				this.menulist = data.data
			})

		},

		// 获取新菜单数据
		get_newMenu(){

			this.myaxios("/newmenu/","get",{"uid":localStorage.getItem("uid")})

			.then(data=>{
				this.menulist = data.data
			})

		},

		// 积分获取
		get_score:function(){
			this.myaxios("/integral/","get",{"uid":localStorage.getItem("uid")})
			.then(data=>{
				console.log(data.data)

				this.score = data.data.score
				this.points = data.data.points
			})
		}
		
	},
	created(){

	},
	mounted(){
		// 判断浏览器中是否已存在用户名
		if(localStorage.getItem("username") == null ){

			this.username = ""

		}else{

			this.username = localStorage.getItem("username")
			this.get_newMenu()
			this.get_score()



		}
	},

}

</script>

<style scoped>

@import url("../assets/style.css");


/*.font {

	font-size: 20px;
	color: black;

}*/

li{

	margin-bottom: 50px;
	/*margin-left: 50px;*/
	cursor: pointer;

}

.go{

	margin-bottom: 50px;
	margin-left: 50px;

}

.tac{
	width: 100%;
}




</style>