// 引入ali-oss
let OSS = require('ali-oss')

/**
 *  [accessKeyId] {String}：通过阿里云控制台创建的AccessKey。
 *  [accessKeySecret] {String}：通过阿里云控制台创建的AccessSecret。
 *  [bucket] {String}：通过控制台或PutBucket创建的bucket。
 *  [region] {String}：bucket所在的区域， 默认oss-cn-hangzhou。
 */
// crnt.oss-accelerate.aliyuncs.com
let client = new OSS({
  region: 'oss-cn-beijing',
  secure: false,  // secure: 配合region使用，如果指定了secure为true，则使用HTTPS访问  
  accessKeyId: 'LTAI5tHZUE4QrYmuHyXb9DoJ',
  accessKeySecret: 'yZXdGQHQuooXleMiJYwiiaxgYCxEqA',
  bucket: 'crnt-study-server'
})

export const ClientOss = client


/** 
*  上传文件，大小不能超过5GB 
* @param {string} ObjName OSS的储存路径和文件名字 
* @param {string} fileUrl 本地文件 
* @retruns Promise 
*/
export const put = async (ObjName, fileUrl) => {  
    try {    
      let result = await client.put(`${ObjName}`, fileUrl)    
      // ObjName为文件名字,可以只写名字，就直接储存在 bucket 的根路径，如需放在文件夹下面直接在文件名前面加上文件夹名称    
      return result  
    } catch (e) {    
     console.log(e)  
    }
  }
  // 上传成功之后，转换真实的地址
  export const signatureUrl= async (ObjName) => {
    try {    
      let result = await client.signatureUrl(`${ObjName}`)    
      return result  
    } catch (e) {    
      console.log(e)  
    }
  }

  