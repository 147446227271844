<template>
<div>
	<!-- <el-row>
		<el-col :span="12">
	      <h5 class="mb-2">Custom colors</h5>
	      <el-menu
	        active-text-color="#ffd04b"
	        background-color="#545c64"
	        class="el-menu-vertical-demo"
	        default-active="2"
	        text-color="#fff"
	        @open="handleOpen"
	        @close="handleClose"
	      >
	        
	      		<div v-for="item in data">
	      			
	      			<el-sub-menu v-if="item.son" :index="item.id">

				        <template #title>
				            <menutree v-for="child in item.son" :data="child" :key="child.id" />
				        </template>

				    </el-sub-menu>

				    <el-menu-item v-else @click="getinfo(item.did)" :index="item.did">{{item.label}}</el-menu-item>

	      		</div>


	      </el-menu>
	  </el-col>
	</el-row> -->



	<el-tree :data="menulist" :props="defaultProps" node-key="id"  @node-click="getinfo">

		<template #default="{node,data}">

			<span class="custom-tree-node">
				<span>{{node.label}}</span>
				

			</span>
			
			
		</template>

	</el-tree>

	
</div>	
</template>


<script>

import menutree from "./menutree.vue"
	
export default{

	name:"left",
	props:["data"],

	components:{
		"menutree" : menutree,
	},

	data() {
		return{

			username : "",
			menulist:"",
			score:"",
			points:"",
			defaultProps:{
				children : 'children',
				label:'label'
			}

		}

	},

	// 父子组件传参
	setup(props,{emit}){

		function getinfo(data,node){




			if (node.isLeaf === true){
				// console.log(data)

				emit('data',data)

			}else{


			}

			

			// emit('val',infoid)

		}
		return{

			getinfo

		}

	},

	methods:{

		go_out(){
			// 删除浏览器存储的信息
			localStorage.removeItem("username")
			localStorage.removeItem("uid")
			// location.reload()
			this.$router.push({path:"/login"})

		},

		// 获取菜单数据
		get_menu(){

			this.myaxios("/menu/","get",{"uid":localStorage.getItem("uid")})
			.then(data=>{
				this.menulist = data.data
			})

		},

		// 获取新菜单数据
		get_newMenu(){

			this.myaxios("/newmenu/","get",{"uid":localStorage.getItem("uid")})

			.then(data=>{
				this.menulist = data.data
			})

		},

		// 积分获取
		get_score:function(){
			this.myaxios("/integral/","get",{"uid":localStorage.getItem("uid")})
			.then(data=>{
				console.log(data.data)

				this.score = data.data.score
				this.points = data.data.points
			})
		},
		handleNodeClick:function(data,node){

			// console.log(node)
			console.log(data)
		}
		
	},
	created(){

	},
	mounted(){
		// 判断浏览器中是否已存在用户名
		if(localStorage.getItem("username") == null ){

			this.username = ""

		}else{

			this.username = localStorage.getItem("username")
			// this.get_menu()
			this.get_newMenu()
			this.get_score()



		}
	},

}

</script>

<style scoped>

@import url("../assets/style.css");


/*.font {

	font-size: 20px;
	color: black;

}*/

li{

	margin-bottom: 50px;
	/*margin-left: 50px;*/
	cursor: pointer;

}

.go{

	margin-bottom: 50px;
	margin-left: 50px;

}

/*.tac{
	width: 100%;
}*/



</style>