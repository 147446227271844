<template>
  <div class="fileupload">
    <div class="fileupload-title">
      <el-button type="primary" for="file" @click="$refs.file.click()">文件上传</el-button>
      <input
        style="display:none"
        type="file"
        id="file"
        name="file"
        ref="file"
        multiple="multiple"
        @change="fileChange($event)"
      />
    </div>
    
    <div class="fileupload-content">
      <el-table :data="fileUploadList" border stripe>
        <el-table-column label="文件名称" prop="name"></el-table-column>
        <el-table-column label="上传进度">
          <template #default="scope">
            <div>
                {{scope.row}}
                <el-progress :status="scope.row.isSuccess==1?'success':scope.row.isSuccess==2?'exception':null" :percentage="scope.row.percent"></el-progress>
                }
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>


<script>

// import {Plus} from '@element-plus/icons-vue'
// import { ref } from 'vue'
// import { ElMessage } from 'element-plus'
// import { Plus } from '@element-plus/icons-vue'
// import type { UploadProps } from 'element-plus'
	
export default{
	// 注册组件
	components:{

	},
	// 数据变量
	 data() {
        return {

          fileUploadList: []

        };
    },




	// 方法
	methods:{


        fileChange(event) {
              console.log(event)



              let filelist = event.target.files;
              let formData = new FormData();
              if (filelist.length > 8) {
                Message.success({
                  message: "上传文件个数不能超过8个"
                });
                return;
              }
              if (filelist.length > 0) {
                for (let i = 0; i < filelist.length; i++) {
                  formData.append("fileList", filelist[i]);
                  let filedata = {
                    name: filelist[i].name.substring(0, filelist[i].name.length-4),
                    size: filelist[i].size,
                    date: filelist[i].lastModified,
                    isSuccess:0,//是否上传成功,0上传中，1成功，2失败
                    percent: 0
                  };

                  this.fileUploadList.push(filedata);

                  console.log(this.fileUploadList)

                  let config = {
                    timeout: 120000,
                    onUploadProgress: function(progress) {
                      this.fileUploadList[i].percent = Math.round(
                        (progress.loaded * 100) / progress.total
                      );
                    }.bind(this)
                  };
                  this.uploadFile(formData, config,this.fileUploadList[i]);
                }
              }
    },

    uploadFile(formdata, conf,loadItem) {

        console.log(formdata,conf,loadItem)


        const axiosinstance = this.axios.create({withCredentials:false})

        axiosinstance({

            method:'post',
            url:'http://localhost:8000/upload/',
            data:formdata

        }).then(data=>{
            // this.progressPercent = 100;

            loadItem.isSuccess=1;
            
            console.log(data)
            if(data.data.code === 403){
                this.$toast.fail(data.data.msg)
                loadItem.isSuccess=2;
            }else{
                loadItem.isSuccess=1;
                this.$toast.success(data.data.msg)
            }

        })

      // AddMusics(formdata, conf).then(res => {
      //   if (res.success) {
      //     loadItem.isSuccess=1;
      //     Message.success({
      //       message: "上传成功"
      //     });
      //   }else{
      //     loadItem.isSuccess=2;
      //      Message.warning({
      //       message: "上传失败"
      //     });
      //   }
      // });
    }



	},
	// 生命周期钩子函数
	// 用来在一个实例被创建之后执行
	created(){

	},
	//模板中的html渲染到了html页面中，一般用作于axios请求
	mounted(){

        // this.getmenus()	

    }
}
        



</script>

<style scoped>

/*@import url("../assets/style.css");*/



.avatar-uploader .el-upload {
  border: 1px dashed var(--el-border-color);
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  text-align: center;
}

.avatar-uploader .avatar {
  width: 178px;
  height: 178px;
  display: block;
}

</style>