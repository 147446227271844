<template>

<div>
	

	
		<!-- <el-form :model="form">
		
	     <el-select v-model="form.select" placeholder="please select your zone">
	        <el-option v-for="item in select" :label="item.name" :value="item.dvalue" />
	        
	      </el-select>

	      <el-button type="primary" @click="onSubmit">Create</el-button>
	    
	    </el-form> -->

	    <!-- <div><el-button>添加</el-button></div> -->
	    <el-table  height="800" :data="data" style="width: 100%">
	    	
	    	<!-- <el-table-column>
	    		<template #default="scope">
	    			
	    			

			    </template>
	    	</el-table-column> -->


	    	<el-table-column prop="name" label="名称" width="300" />

	    	<el-table-column label="问题" width="300">
	    		<template #default="scope">
	    			
	    			<el-link @click="DrawerShow(scope.row)" type="primary">问题查看</el-link>

			    </template>
	    	</el-table-column>


		    
		    
		    <el-table-column prop="username" label="作者" width="180"/>


		    <el-table-column label="类型" >
	    		<template #default="scope">
	    			
	    			

	    			{{changeInfo(scope.row.makestate)}}

			    </template>
	    	</el-table-column>

			<el-table-column label="视频大小" >
                <template #default="scope">

                    {{scope.row.time}} <br> {{scope.row.size}}

                </template>


            </el-table-column>



		    	<!-- <template slot-scope="scope">
		    		<span></span>
		    	</template>

		    </el-table-column> -->
		    
		    <!-- <el-table-column prop="level" label="所属菜单级别" /> -->
		
		    <el-table-column align="right">
		      <template #header>
				<el-radio-group v-model="flag">
                    <el-radio label="1" >视频名称</el-radio>
                    <el-radio label="2" >作者</el-radio>
                </el-radio-group>
		      	<el-input style="width: 200px;" @change="postV" v-model="search" size="small" placeholder="请输入关键字" />
		      </template>

		      <template #default="scope">
		      	
				<el-button type="success" @click="addVscore(scope.row)" size="small">添加分数</el-button>
				
		      	<el-button size="small" @click="VideoPut(scope.row)">
		      		修改
		      	</el-button>

		        <el-button
		          size="small"
		          type="danger"
		          @click="MpTrue(scope.row)"
		          >删除</el-button
		        >


	      </template>

		     </el-table-column>
		</el-table>

		<el-dialog destroy-on-close width="30%" v-model="addScoreShow" title="添加分数">
			
			<span>
				<el-input type="number" v-model="addScoreNumber" placeholder="请输入需要添加的分数"></el-input>
			</span>
			
			<template #footer>
				<span class="dialog-footer">
					<el-button>取消</el-button>
					<el-button @click="postVscore" type="primary">确认</el-button>
				</span>
			</template>

		</el-dialog>

		<el-dialog
		destroy-on-close 
        width="50%" 
        v-model="MpShow" title="视频信息删除"
		>


			该操作将无法逆转，是否删除？

			<template #footer>
			      <span class="dialog-footer">
			        <el-button @click="MpShow = false">取消</el-button>
			        <el-button type="primary" @click="delVideo()"
			          >确认</el-button
			        >
			      </span>
		    </template>
			
		</el-dialog>


		<el-dialog destroy-on-close 
                width="50%" 
                v-model="videoShow" title="视频信息修改">

			<div>

				<p>视频名称：<el-input v-model="vname"></el-input></p>
				<p>作者：<el-input v-model="username"></el-input></p>
				视频类型：<br><el-select v-model="makeState" >
                  <el-option label="自制视频" :value="2" />
                  <el-option label="非自制视频" :value="1" />
              </el-select>

			</div>
			

			<template #footer>
			      <span class="dialog-footer">
			        <el-button @click="drawer = false">取消</el-button>
			        <el-button type="primary" @click="UpdateVideo()"
			          >保存</el-button
			        >
			      </span>
		    </template>

		</el-dialog>


		

		<el-dialog append-to-body
                destroy-on-close 
                width="80%" 
                v-model="drawer" title="问题详情">


            <div>
            	
            	<el-dialog
            	append-to-body
				destroy-on-close 
		        width="40%" 
		        v-model="QsShow" title="视频信息删除"
				>


					该操作将无法逆转，是否删除？

					<template #footer>
					      <span class="dialog-footer">
					        <el-button @click="QsShow = false">取消</el-button>
					        <el-button type="primary" @click="delQuestion()"
					          >确认</el-button
					        >
					      </span>
				    </template>

					
				</el-dialog>

            </div>



            <div>
            	
           		<el-dialog
                v-model="answerShow"
                width="40%"
                title="修改问题"
                append-to-body
                destroy-on-close
              >
                <!-- {{form}} -->

                

	                <el-form :model="form">

	                    <el-form-item label="问题">
	                        <el-input  autosize type="textarea" v-model="form.content" />
	                    </el-form-item>
	                    <div v-for="(j,k) in form.answer">
	                    <el-form-item :label="'选项'+(k+1)">
	                        <el-input v-model="j.text" />
	                    </el-form-item>
	                    </div>
	                    <el-form-item label="正确答案">
	                        
	                        <span v-for="j in form.answer">
	                            <el-radio style="padding-right: 10px;" v-model="form.answer_id" :label="j.id" size="large">选项{{j.id}}</el-radio>
	    
	                        </span>
	                        
	                        
	                    </el-form-item>
	                    <div style="text-align: center;">
	                    <el-button @click="addAnswer()">
	                        +&nbsp;添加选项
	                    </el-button>
	                    <el-button @click="delAnswer()">
	                        -&nbsp;删除选项
	                    </el-button>
	                	</div>
	                    
	                </el-form>

	            	
	            
	            
	            	<br><br>
	                

	                <template #footer>
	                  <span class="dialog-footer">
	                    <el-button @click="onUpdate">确认</el-button>
	                    <el-button type="primary" @click="answerShow = false"
	                      >取消</el-button
	                    >
	                  </span>
	                </template>

              
            	</el-dialog>


            </div>



		 	<span v-for="(i,k) in qlist" style="font-size: 18px;" >

                <span style="color:rgb(0, 0, 143);">问题{{k+1}}：{{i.content}}</span><br>


                <span v-for="(j,key) in i.answer">
                    选项{{key+1}}：{{j.text}}<br>
                </span>
                <span style="color:red;">正确答案：选项{{i.answer_id}}</span><br>
                操作：<el-button type="success" @click="AnswerPut(k,i.id)">修改</el-button ><el-button @click="QuTrue(i)" type="danger">删除</el-button>
                <br><br>
            </span>
		 	<template #footer>
			      <span class="dialog-footer">
			        <el-button @click="drawer = false">取消</el-button>
			        <el-button type="primary" @click="onUpdate()"
			          >保存</el-button
			        >
			      </span>
		    </template>

	 	</el-dialog>


		 
	



</div>	
</template>


<script>
import { fail } from "assert"

	
export default{
	// 注册组件
	components:{

	},
	// 数据变量
	data() {
		return{

			//弹窗状态
			drawer:false,

			data:"",
			qlist:"",
			answerShow:false,

			form:{content:"",answer:"",answer_id:""},
			qid:"",

			videoShow:false,
			// 视频名称
			vname:"",
			// 作者
			username:"",
			// 视频类型
			makeState:"",
			vid:"",
			MpShow:false,
			QsShow:false,
			delQid:"",
			delVid:"",

			search:"",


			addScoreShow:false,
			addScoreNumber:"",
			scoreVid:"",

			flag:"1",

			




			
		}

	},
	// 方法
	methods:{

		addVscore(info){
			this.scoreVid = info.id
			this.addScoreShow = true
			
		},

		postVscore(){
			this.myaxios("/AddVideoScore/","post",{"uid":localStorage.getItem("uid"),"vid":this.scoreVid,"score":this.addScoreNumber})
			.then(data=>{
				this.$toast.success("成功")
				this.addScoreShow=false
				this.scoreVid=""
				this.addScoreNumber=""
			})
		},

		

		MpTrue(info){
			this.MpShow = true
			this.delVid = info.id
		},

		QuTrue(info){
			this.QsShow = true
			this.delQid = info.id
		},

		postV(){
			this.myaxios("/videoOperation/","post",{"name":this.search,"flag":this.flag})

			.then(data=>{

				this.data = data.data

			})
		},

		changeInfo(value){
			if(value == "1"){
				return "非自制"
			}
			else if(value == "2"){
				return "自制"
			}
		},






		// 添加答案字段
        addAnswer:function(){

            

            var ks = this.form.answer.length

            var dicts = {id:ks+1,text:""}

            this.form.answer.push(dicts)

        },

        // 删除答案字段
        delAnswer:function(){

            // var index = this.form[k].answer.indexOf(k)

            // console.log(this.form[k].answer.length)

            if(this.form.answer.length === 1){

                this.$toast.fail("不能删了，再删就没有了")

                return

            }else{
                
                this.form.answer.splice((this.form.answer.length)-1,1)
            }

            // console.log(index)

            

        },


		// 问题弹出
		DrawerShow(info){

			

			this.getQuestion(info.id)
			this.drawer = true


		},


		// 修改弹出

		AnswerPut(id,qid){

			this.answerShow = true
			this.form["content"] = this.qlist[id].content
			this.form["answer"] = this.qlist[id].answer
			this.form["answer_id"] = this.qlist[id].answer_id
			this.qid = qid


		},


		// 视频弹窗户
		VideoPut(info){

			
			this.vid = info.id
			this.vname = info.name
			this.username = info.username
			this.makeState = info.makestate
			this.videoShow = true


		},



		// 问题请求

		getQuestion(vid){
			this.myaxios("/videoOperation/","delete",{"vid":vid})
			.then(data=>{
				
				var lists = data.data
                for(let i=0; i<lists.length; i++){

                        // alert(lists[i].answer)
                    console.log(lists[i].answer)

                    lists[i].answer = JSON.parse(lists[i].answer)
                }

                this.qlist = lists
			})
		},


		// 删除问题
		delQuestion:function(){

			this.myaxios("/questionOperation/","post",{"qid":this.delQid})
			.then(data=>{

				if(data.code == 200){
					this.QsShow = false
					this.drawer = false
					this.$toast.success("成功")
					this.search = ""
					this.getVideo()

				}

			})

		},


		// 删除视频信息
		delVideo:function(){

			this.myaxios("/questionOperation/","delete",{"vid":this.delVid})
			.then(data=>{

				if(data.code == 200){
					this.MpShow = false
					this.$toast.success("成功")
					this.search = ""
					this.getVideo()

				}

			})

		},


		// 修改提交
		onUpdate(){

			
			this.myaxios("/videoOperation/","put",{"qid":this.qid,"question":JSON.stringify(this.form)})
			.then(data=>{

				this.answerShow = false
				this.drawer = false
				this.search = ""
				this.getVideo()
				
			})

		},

		// 视频修改提交
		UpdateVideo:function(){

			console.log(this.vid)
			console.log(this.vname)
			console.log(this.username)
			console.log(this.makeState)

			if(this.vid == "" || this.vname == "" || this.username == ""){
				console.log("有空")
				return false;
			}

			this.myaxios("/questionOperation/","put",{"vid":this.vid,"name":this.vname,"username":this.username,"makestate":this.makeState})
			.then(data=>{
				if(data.code == 200){
					this.videoShow = false
					this.$toast.success("成功")
					this.search = ""
					this.getVideo()

				}
			})


		},

		
	

		onSubmit(){

			console.log(this.form)

		},

		getVideo(){
			this.myaxios("/videoOperation/","get")

			.then(data=>{

				this.data = data.data


			})
		},

		

		

		
	},
	// 生命周期钩子函数
	// 用来在一个实例被创建之后执行
	created(){

	},
	//模板中的html渲染到了html页面中，一般用作于axios请求
	mounted(){
		this.getVideo()
	},

}

</script>

<style scoped>

/*@import url("../assets/style.css");*/

</style>