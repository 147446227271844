<template>
    <div>
        <van-notice-bar  color="#1989fa" scrollable background="#ecf9ff" left-icon="info-o">
			<span>查看为技术部对应目录下的视频观看记录。</span>
		</van-notice-bar>
        <el-row :span="24">
            <el-col :span="1"></el-col>
            <el-col :span="22">
                <!-- <el-col :span="24"> -->
                    <p>学习成长积分共计：<el-tag class="ml-2" type="danger">{{ num }}</el-tag></p>
                <!-- </el-col> -->
            </el-col>
            <el-col :span="1"></el-col>
            <el-col :span="1">
            
            </el-col>
            <el-col :span="22">
                <el-table  :data="data" height="90vh"
                @selection-change="handleSelectionChange"
                >
                    <el-table-column   type="selection" />
                    <el-table-column prop="vname" label="视频名称" />
                    <el-table-column width="160" prop="create_time" label="观看时间" />
                    <el-table-column width="120" prop="score" label="学习成长积分" />
                    <el-table-column prop="tname" label="视频对应目录" />
                </el-table>
            </el-col>
            <el-col :span="1"></el-col>
        </el-row>
        
        
    
    
    </div>	
    </template>
    
    
    <script>
    import { ElLoading } from 'element-plus'
    
    export default{
        
        components:{
    
            
    
        },
    
        data() {
            return{
    
                data:"",
                num:"",
    
            }
    
        },
    
    
        methods:{
    
            handleSelectionChange(val){
                var num = 0
                console.log(val)
                // return sumNum
                this.MsgNumber = val.length
                for(let i of val){
                    // console.log("循环---",i.id)
                    num+=i.score
                }
                this.num = num

            },
    
    
            getData(){
                const loading = ElLoading.service({
                    lock: true,
                    text: '😅努力加载中。。。',
                    background: 'rgba(0, 0, 0, 0.7)',
                })
                this.myaxios("/SKillHandler/","get",{"uid":localStorage.getItem("uid")})
                .then(data=>{
                    this.data = data.data
                    loading.close()
                })
            }
    
    
            
    
        },
        created(){
    
    
            
    
        },
        mounted(){
    
            this.getData()
    
        },
         
    
    }
    
    </script>
    
    <style scoped>
    
    @import url("../assets/style.css");
    
    
    /*video::-webkit-media-controls-timeline {
            display: none;
        }*/
    
    h4{
        color: red;
    }
    
    /* p{
        text-align: center;
        font-size: 50px;
    } */
    
    .divps{
        padding-top: 50px;
        padding-left: 144px;
    }
    
    .el-dialog__body {
    
        text-align: center;
    }
    
    /*.comment{
        position: absolute;
            top: 20%;
            bottom: auto;
            left: 0;
            right: 0;
    }*/
    
    </style>