<template>
    <!-- <van-notice-bar
            left-icon="volume-o"
            text="主办："
            scrollable 
        /> -->
    <van-notice-bar left-icon="volume-o" :scrollable="false">
        <van-swipe
            vertical
            class="notice-swipe"
            :autoplay="3000"
            :touchable="false"
            :show-indicators="false"
        >
            <van-swipe-item>主办：北京创想智控科技有限公司</van-swipe-item>
            <van-swipe-item>旗下赞助：创想学以致用积分商城</van-swipe-item>
            <van-swipe-item>旗下赞助：创想2024中长期激励平台</van-swipe-item>
        </van-swipe>
    </van-notice-bar>

    <div class="lotteryDraw">
        
        <div class="left">
            <div class="absolute">
                <span style="margin-right:20px;color: tomato;">*统统99拿到家</span>
                <!-- <el-tabs v-model="activeName" class="demo-tabs" @tab-click="selectChange">
                    <el-tab-pane v-for="item in options" :key="item.id" :label="item.label" :name="item.id"></el-tab-pane>
                </el-tabs> -->
            </div>
            <div class="container">
                <div class="prize-list" ref="prizeWrap" :style="bgColor">
                    <div class="prize-item" v-for="(item, index) in state.prizeList" :style="prizeStyle(index)" :title="item.name">
                        <img :src="item.url" alt="">
                        <p >{{ item.name.length>=5?item.name.slice(0,4)+'...':item.name }}</p>
                    </div>
                </div>
                <div class="btn" @click="start"></div>
            </div>
        </div>
        <div class="right">
            <div v-if="activeName == '1'">
                <el-select v-model="name" @change="getScore" style="width:100%;" clearable filterable placeholder="请选择输入查询名称" size="large">
                    <el-option v-for="item in option" :key="item.id" :label="item.username"  :value="item.id" />
                </el-select>
            </div>
            <div v-else>
                <el-select v-model="groupData.companyName" style="width:100%;"  clearable filterable placeholder="请选择输入查询名称" size="large">
                    <el-option v-for="item in companyOptions" :key="item.id" :label="item.label" :value="item.label"/>
                </el-select>
            </div>
             <!-- 公司兑换按钮 -->
             <div v-if="activeName != '1' && shopInfo" style="margin-top:10px;">
                <el-button type="primary" size="small" @click="companyExchange">兑换</el-button>
            </div>
            <!-- 个人展示信息 -->
            <div v-if="userInfo && isShow && activeName == '1'">
                <el-descriptions
                    class="margin-top"
                    :column="2"
                    border
                    style="margin-top: 10px;"
                >
                    <el-descriptions-item>
                        <template #label>
                            <div class="cell-item">
                                <el-icon><User /></el-icon>
                            </div>
                        </template>
                        {{ userInfo.username }}
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template #label>
                            <div class="cell-item">
                                <el-icon><OfficeBuilding /></el-icon>
                            </div>
                        </template>
                        {{ userInfo.company }}
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template #label>
                            <div class="cell-item">
                                学习成长
                            </div>
                        </template>
                        {{ userInfo.use_score }}
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template #label>
                            <div class="cell-item">
                                诲人不倦
                            </div>
                        </template>
                        {{ userInfo.use_points }}
                    </el-descriptions-item>
                </el-descriptions>
                <!-- <el-icon><OfficeBuilding /></el-icon> -->

                
                <div  style="margin-top:10px;">
                    <!-- <el-button type="success" size="small" @click="isCash">兑换</el-button> -->
                    <!-- <van-button type="success" block @click="isCash">兑换</van-button> -->
                </div>
                
                <!-- <el-button type="error" size="small" ></el-button> -->
            </div>
            
            <div v-if="shopInfo && isShow" style="margin-top:10px;">
                <!-- <div style="margin-top:10px;" v-show="activeName == '1'">
                    <span style="font-weight: bold;">兑换所需诲人不倦值：</span>
                    <span style="color:#409eff;">{{shopInfo.points}}</span>
                </div>
                <div style="margin-top:10px;" v-show="activeName == '1'">
                    <span style="font-weight: bold;">兑换所需学习成长值：</span>
                    <span style="color:#409eff;">{{shopInfo.score}}</span>
                </div> -->
                
                <div>
                    <el-tag style="width: 100%;font-size: 18px;height: 40px" type="primary" effect="plain">
                    {{ shopInfo.name}}
                    </el-tag>
                </div>
                <el-image style="width: 100%; height: 100%;margin-top: 10px;" :src="shopInfo.url"  :zoom-rate="1.2"
                    :max-scale="7" :min-scale="0.2" :preview-src-list="[shopInfo.url]"  fit="cover" />
                <van-button type="success" block @click="isCash">兑换</van-button>
                
            </div>
        </div>

        <div class="right" style="display: flex;justify-content: space-between;padding-left:20px;">
            <div class="list" style="margin-right: 20px;">
                <div style="margin-right:20px;color: tomato;display: flex;justify-content: space-between;align-items: center;margin-top: 114px;">
                    <!-- <span>*中奖名单</span> -->
                    <div>
                        <!-- <el-button type="primary" size="small" @click="reload">刷新</el-button> -->
                        <!-- <el-button type="primary" size="small" @click="copyURL">复制链接</el-button> -->
                    </div>
                </div>
                <div class="list-info">

                
                <div class="mt10 flex" v-for="(item, idx) in tableData" :key="item">
                    <div class="name">
                        <!-- <span :class="['round', idx > 2 ? '' : 'active']">{{ idx + 1 }}</span> -->
                        <span class="ml10" style="margin-left: 35px;">{{ item.username}}</span>
                    </div>
                    
                    <div class="num" style="margin-right: 15px;">
                        {{item.name}}
                    </div>
                </div>
                </div>
            </div>
            <!-- <div class="list">
                <div style="color: tomato;">*公司榜(点击查看详情)</div>
                <div class="mt10 flex" v-for="(item, idx) in companyData" :key="item" @click="openDialog(item)">
                    <div class="name">
                        <span :class="['round', idx > 2 ? '' : 'active']">{{ idx + 1 }}</span>
                        <span class="ml10">{{ item.name}}</span>
                    </div>
                    <div class="num">{{item.num}}</div>
                </div>
            </div> -->
        </div>


        <!-- 兑换弹窗 -->
        <el-dialog v-model="isCashShow" title="是否兑换" width="40%">
            <span>
                <!-- <div>
                    <el-radio style="padding-right: 10px;" @change="isCashEdit" v-model="event" label="1" size="large">学习成长值:{{shopInfo.score}}</el-radio>
                    <el-radio style="padding-right: 10px;" @change="isCashEdit" v-model="event" label="2" size="large">诲人不倦值:{{shopInfo.points}}</el-radio>
                </div> -->
                
                <!-- <span v-if="!scoreInfo.ismath">
                    <b>{{ scoreInfo.name }}&nbsp;&nbsp;&nbsp;&nbsp;</b>本次兑换共花费{{ scoreInfo.shop_score }}{{ scoreInfo.event }}，您是否确认兑换？
                </span>
                <span v-else>
                    <b>{{ scoreInfo.name }}&nbsp;&nbsp;&nbsp;&nbsp;</b>本次兑换共花费{{ scoreInfo.all_score }}{{ scoreInfo.event }}，距离兑换还需  {{ scoreInfo.math_score.toFixed(2) }}，由于您的积分不足，在商品基础上增加  {{ scoreInfo.use_score.toFixed(2) }}
                </span> -->

                <span>
                    恭喜您获得了<b>{{ scoreInfo.name }}&nbsp;&nbsp;&nbsp;&nbsp;</b>，您是否确认兑换？
                </span>

                

            </span>

            <template #footer>
            <span class="dialog-footer">
                <el-button type="primary" @click="PostShow">确认</el-button>
                <el-button @click="isCashShow = false">取消</el-button>
            </span>
            </template>
        </el-dialog>
        <!-- 公司兑换奖品 -->
        <el-dialog v-model="groupData.visible" title="是否兑换" width="40%">
            <div>
                <div>恭喜{{ groupData.companyName }}的同事抽到了{{ shopInfo.name}}</div>
                <el-image style="width: 100px; height: 100px;margin-top: 10px;" :src="shopInfo.url"  :zoom-rate="1.2"
                    :max-scale="7" :min-scale="0.2" :preview-src-list="[shopInfo.url]"  fit="cover" />
            </div>
            <template #footer>
                <span class="dialog-footer">
                    <el-button type="primary" @click="confirmExchange">确认兑换</el-button>
                    <el-button @click="groupData.visible = false">取消</el-button>
                </span>
            </template>
        </el-dialog>
        <!-- 公司对讲详情 -->
        <el-dialog v-model="dialogVisible" title="详情" width="40%">
            <div style="display: flex;align-items: center;margin-right: 15px;flex-wrap: wrap;">
                <div v-for="item in companyChildData"  style="margin-top:10px;min-width: 180px;">
                    <div>
                        <span style="font-weight: bold;">用户：</span>
                        <span style="color:#409eff;">{{item.username}}</span>
                    </div>
                    <div>
                        <span style="font-weight: bold;">所属公司：</span>
                        <span style="color:#409eff;">{{item.company}}</span>
                    </div>
                    <div>
                        <span style="font-weight: bold;">描述：</span>
                        <span style="color:#409eff;">{{item.msg}}</span>
                    </div>
                </div>
            </div>
            
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="dialogVisible = false">取消</el-button>
                </span>
            </template>
        </el-dialog>

    </div>



</template>

<script setup>

// import Toast from '@vant/weapp/toast/toast';

import { Toast } from 'vant';
import { ElLoading,ElMessage,ElMessageBox } from 'element-plus';
import { onMounted, onUnmounted, ref, reactive, toRefs, computed, defineComponent } from 'vue';
import { copyText } from 'vue3-clipboard'
import {GetUserScore,GetUserList,GetNewShop,PostNewShop,PutNewShop,GetNewShopInfo,getCompanyList,copyShareURL} from "../utils/api";
import { OfficeBuilding,User } from '@element-plus/icons-vue'


// Toast("Xx")

const name = ref("")
const option = ref([])
const userInfo = ref("")
const shopInfo = ref("")
const event = ref("1") // 1学习成长 2诲人不倦
const scoreInfo = ref("") // 确认兑换回显
const isCashShow = ref(false) // 确认兑换弹窗
const isShow = ref(true)

const dialogVisible = ref(false)

const audio =new Audio(require('@/assets/2876549139.mp3'))

const groupData = reactive({
    companyName:'',
    visible:false,
    username:''
})

const options = ref([
    { label: '个人赛', id: '1' },
    { label: '团体赛', id: '2' },
])

const companyOptions = ref([
    { label: '北京公司', id: '1' },
    { label: '济南公司', id: '2' },
    { label: '长沙公司', id: '3' },
    { label: '成都公司', id: '4' },
    { label: '嘉兴公司', id: '5' },
    { label: '淄博公司', id: '6' },
    { label: '南京公司', id: '7' },
])

const tableData = ref([])
const activeName = ref('1')
const state = reactive({
    prizeList: [], // 后台配置的奖品数据
    isRunning: false, // 是否正在抽奖
    baseRunAngle: 360 * 20, // 总共转动角度 至少5圈
    prizeId: 0, // 中奖id
})
const prizeWrap = ref(null)
// 平均每个奖品角度
const rotateAngle = computed(() => {
    const _degree = 360 / state.prizeList.length
    return _degree
})
// 要执行总角度数
const totalRunAngle = computed(() => {
    return state.baseRunAngle + 360 - state.prizeId * rotateAngle.value - rotateAngle.value / 2
})
// 计算绘制转盘背景
const bgColor = computed(() => {
    const _len = state.prizeList.length
    const colorList = ['#5352b3', '#363589']
    let colorVal = ''
    for (let i = 0; i < _len; i++) {
        colorVal += `${colorList[i % 2]} ${rotateAngle.value * i}deg ${rotateAngle.value * (i + 1)}deg,`
    }
    return `
    background: conic-gradient(${colorVal.slice(0, -1)});
    `
})
// 每个奖品布局
const prizeStyle = computed(() => {
    const _degree = rotateAngle.value
    return (i) => {
        return `
        width: ${2 * 300 * Math.sin(_degree / 2 * Math.PI / 180)}px;
        height: 290px;
        transform: rotate(${_degree * i + _degree / 2}deg);
        transform-origin: 50% 100%;
    `
    }
})


// 获取随机数
const getRandomNum = () => {
    const num = Math.floor(Math.random() * state.prizeList.length)
    return num
}

// 新兑换奖品
const PostShow = async() =>{
    try{
        const res = await PutNewShop({uid:userInfo.value.id,sid:shopInfo.value.id,event:event.value})
        console.log("shopres",res)
        if(res.code == 200){
            shopInfo.value = ""
            scoreInfo.value = ""
            isCashShow.value = false;
            isShow.value = false;
            name.value = '';
            selectChange()
            getTableData();
            getCompanyData();
        }
        
    }catch(e){
        console.log("兑换商品--",e)
    }
}

// 兑换弹窗
const isCash = () =>{
    isCashShow.value = true
    isCashEdit()
}

// 兑换计算
const isCashEdit = () =>{
    var dict = {}
    // now_score 自身积分  shop_score 商品积分
    if (event.value == '1'){
        dict.now_score = userInfo.value.use_score
        dict.shop_score = shopInfo.value.score 
        dict.event = "学习成长"
    }else if(event.value == '2'){
        dict.now_score = userInfo.value.use_points
        dict.shop_score = shopInfo.value.points
        dict.event = "诲人不倦"
    }
    
    // 商品名称
    dict.name = shopInfo.value.name

    // 积分不足以兑换
    if ((parseFloat(dict.now_score) - parseFloat(dict.shop_score)) < 0){
        dict.math_score = parseFloat(dict.shop_score) - parseFloat(dict.now_score) // 差额
        dict.use_score = parseFloat(dict.math_score) * 0.5 // 利率
        dict.all_score = parseFloat(dict.shop_score) + parseFloat(dict.use_score) // 共花费
        dict.ismath = true
    }else{
        
        dict.math_score = parseFloat(dict.now_score) - parseFloat(dict.shop_score) // 剩余积分
        dict.ismath = false
    }

    scoreInfo.value = dict
    
}

// 抽奖
const start = () => {
    ElMessageBox.confirm( '确认抽奖吗?','提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning',
    }).then(() => {
        if (!state.isRunning) {
            audio.play()
            audio.loop = true
            state.isRunning = true;
            console.log('开始抽奖，后台请求中奖奖品')
            shopInfo.value = ""
            // 获取抽奖后的结果
            PostNewShop({uid:localStorage.getItem("uid"),event:activeName.value}).then(data=>{
                if (data.code == 200) {
                    console.log('中奖id',data.data)
                    state.prizeList.forEach((item,idx)=>{
                        if(item.name == data.data){
                            // shopInfo.value = item
                            state.prizeId = idx;
                            startRun()
                            setTimeout(() =>{
                                shopInfo.value = item;
                                isShow.value = true;
                                audio.pause()
                            },10000)
                            // setTimeout(() => {
                            //     // 兑换奖品
                            //     PutNewShop({uid:localStorage.getItem("uid"),sid:data.data}).then(data=>{
                            //         getTableData()
                            //     })
                            // }, 2000);
                        }
                    })                  
                }
            })        
        }
    })
}

// 获取抽奖记录
const getTableData = ()=>{
    GetNewShopInfo().then(data=>{if(data.code==200){tableData.value = data.data;}})
}

const startRun = () => {
    console.log(state.isRunning, totalRunAngle.value)
    // 设置动效
    prizeWrap.value.style = `
    ${bgColor.value}
    transform: rotate(${totalRunAngle.value}deg);
    transition: all 10s ease;
    `
    // 监听transition动效停止事件
    prizeWrap.value.addEventListener('transitionend', stopRun)
}

const stopRun = (e) => {
    console.log(e)
    state.isRunning = false;
    prizeWrap.value.style = `
    ${bgColor.value}
    transform: rotate(${totalRunAngle.value - state.baseRunAngle}deg);
    `;
    
}

const selectChange = () => {
    getData()
}

// 获取商品列表
const getData = () => {
    // localStorage.getItem("uid")
    GetNewShop({"state":activeName.value})
    .then(data=>{
        if (data.code == 200) {
            state.prizeList = data.data;
        }
    })
}


const companyData = ref([])
const companyChildData = ref([])

// 获取公司排行榜
const getCompanyData = (company)=>{
    getCompanyList({company}).then((res)=>{
        if(res.code == 200){
            if(!company){
                companyData.value = res.data;
            }else{
                companyChildData.value = res.data;
            }
        }
    })
}

const openDialog = (val)=>{
    getCompanyData(val.name)
    dialogVisible.value = true;
}

// 公司兑换奖品
const companyExchange = ()=>{
    if(!groupData.companyName){
        ElMessage({type:'warning',message:'请先选择公司'})
        return
    }
    groupData.visible = true;
    groupData.username = localStorage.getItem("username")    
}

const confirmExchange = async ()=>{
    await PutNewShop({company:groupData.companyName,sid:shopInfo.value.id,event:event.value});
    await getCompanyData();
    await getTableData();
    await getData()
    ElMessage({type:'success',message:'兑换成功'});
    groupData.visible = false;
    shopInfo.value = ""
    scoreInfo.value = ""
}

const reload = ()=>{
    activeName.value = '1';
    groupData.visible = false;
    shopInfo.value = "";
    scoreInfo.value = "";
    isCashShow.value = false;
    name.value = '';
    userInfo.value = '';
    getData();
    getCompanyData();
    getTableData();
}

// 复制链接
const copyURL = ()=>{
    copyShareURL().then((res)=>{
        if(res.code == 200){
            copyText(res.data, undefined, (error) => {
                if (error) {
                    ElMessage.error(`复制失败: ${error} ！`);
                } else {
                    ElMessage.success(`复制成功`);
                }
            });
        }
    })
    
}

onMounted(() => {
    getData()
    getTableData();
    getCompanyData();
    prizeWrap.value.style = `${bgColor.value} transform: rotate(-${rotateAngle.value / 2}deg)`
})


onUnmounted(() => {
    if (prizeWrap.value) {
        prizeWrap.value.removeEventListener('transitionend', stopRun)
    }
})






// 查询积分详情
const getScore = async() =>{
    try{
        const res = await GetUserScore({"uid":name.value})
        if (res.code == 200) userInfo.value = res.data
        else userInfo.value = ""
    }catch(e){
        console.log(e)
    }
}


// 获取下拉列表
GetUserList().then(data=>{option.value = data.data})





</script>

<script>
// import { onMounted, onUnmounted, ref, reactive, toRefs, computed, defineComponent } from 'vue';
// import axios from 'axios'
// import { ElMessageBox } from 'element-plus'
// import qs from 'qs'
// export default defineComponent({
//     name: "lotteryDraw",

    // setup() {
        
        

    //     return {
    //         ...toRefs(state),
    //         bgColor,
    //         prizeStyle,
    //         prizeWrap,
    //         start,
    //         tableData,
    //         selectChange,
    //         options,
    //         activeName
    //     }
    // }
// })
</script>
<style scoped>
.lotteryDraw {
    width: calc(100% - 70px);
    height: calc(100vh - 140px);
    display: flex;
    justify-content: space-between;
    padding: 20px 70px;
    padding-right: 0;
    background: #fff;
    overflow-y: auto;
    position: relative;
}
.absolute{
    position: absolute;
    left: 10px;
    top:10px;
}

.list {
    height: 100%;
    overflow-y: auto;
    width: 300px;
    min-width: 300px;   
    background-image: url("http://101.200.139.27:8000/static/中奖背景.png");
    background-size: cover;
}
.list::-webkit-scrollbar {
    width: 4px;
}
.list::-webkit-scrollbar-thumb {
    border-radius: 8px !important;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2) !important;
    background: #333 !important;
}
.list .flex {
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 10px;
}
.list .flex .round {
    display: inline-block;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    color: white;
    text-align: center;
    line-height: 25px;
    background: #ccc;
    font-size: 12px;
}
.list .active {
    background: #409eff !important;
}

.left {
    height: 100%;
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    align-items: center;
}

.right {
    padding-left: 50px;
    width: calc(100% - 550px);
}

.container {
    
    min-width: 550px;
    min-height: 550px;
    position: relative;
    top: 40px;
}

.prize-list {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 10px solid #98d3fc;
    overflow: hidden;
}

.prize-item {
    /*border: 2px solid red;*/
    position: absolute;
    left: 0;
    right: 0;
    top: -10px;
    margin: auto;
}

.prize-item img {
    width: 30%;
    height: 20%;
    margin: 40px auto 10px;
    display: block;
}

.prize-item p {
    color: #fff;
    font-size: 12px;
    text-align: center;
    line-height: 20px;
}

.btn {
    width: 160px;
    height: 160px;
    background: url('https://www.jq22.com/demo/jquerylocal201912122316/img/btn_lottery.png') no-repeat center / 100% 100%;
    position: absolute;
    left: 12px;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    cursor: pointer;
}

.btn::before {
    content: "";
    width: 41px;
    height: 39px;
    background: url('https://www.jq22.com/demo/jquerylocal201912122316/img/icon_point.png') no-repeat center / 100% 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: -33px;
    margin: auto;
}

.notice-swipe {
    height: 40px;
    line-height: 40px;
  }

.list-info {
    height: 390px;
    overflow-y: auto;
}

.list-info::-webkit-scrollbar {
    width: 4px;
}
.list-info::-webkit-scrollbar-thumb {
    border-radius: 8px !important;
    box-shadow: inset 0 0 5px #ffed74 !important;
    background: #ffed74 !important;
}

/* .list-info::-webkit-scrollbar-track {
    background-color: #ecf5ff; 
}

.list-info::-webkit-scrollbar-thumb {
    border-radius: 8px !important;
    box-shadow: inset 0 0 5px #ecf5ff !important;
    background: #ecf5ff !important;
}

.list-info::-webkit-scrollbar-thumb:hover {
    background-color: #ecf5ff;
} */

</style>