import { createStore } from 'vuex';

// 创建一个新的 store 实例
const store = createStore({
  state() {
    return {
      nid: 0,
      menuid:0,
    };
  },
  mutations: {
    // 定义一个 mutation 来修改 nid
    setNid(state, newNid) {
      state.nid = newNid;
    },
    setMenuid(state,newMenuId){
      state.menuid = newMenuId;
    }
  },
  getters: {
    // 定义一个 getter 来获取 nid
    getNid(state) {
      return state.nid;
    },
    getMenuid(state) {
      return state.menuid;
    },
  }
});

export {
  store
}
