<template>

<div>
	

	
		<!-- <el-form :model="form">
		
	     <el-select v-model="form.select" placeholder="please select your zone">
	        <el-option v-for="item in select" :label="item.name" :value="item.value" />
	        
	      </el-select>

	      <el-button type="primary" @click="onSubmit">Create</el-button>
	    
	    </el-form> -->

	    <!-- <div><el-button>添加</el-button></div> -->
	    <el-table height="800" :data="data" style="width: 100%">
	    	
	    	<el-table-column label="名称" width="300">
	    		<template #default="scope">
	    			<div v-if="scope.row.flag == 1">
	    				<font color="red">{{scope.row.name}}</font>
	    			</div>
	    			<div v-else>
	    				{{scope.row.name}}
	    			</div>
			        
			    </template>
	    	</el-table-column>


		    <!-- <el-table-column prop="name" label="名称" width="300" /> -->
		    
		    <el-table-column prop="topid" label="上级名称" width="180"/>

		    	<!-- <template slot-scope="scope">
		    		<span></span>
		    	</template>

		    </el-table-column> -->
		    
		    <el-table-column prop="level" label="所属菜单级别" />
		
		    <el-table-column align="right">
		      <template #header>
		      	<el-input style="width: 200px;" @change="postmenu" v-model="search" size="small" placeholder="请输入关键字" />
		      </template>

		      <template #default="scope">
		      	<el-button size="small" @click="handleEdit(scope.$index, scope.row)"
		          >添加子菜单</el-button
		        >
		        <el-button size="small" @click="handleUpdate(scope.$index, scope.row)"
		          >修改</el-button
		        >
		        <el-button
		          size="small"
		          type="danger"
		          @click="handleDelete(scope.$index, scope.row)"
		          >删除</el-button
		        >
	      </template>

		     </el-table-column>
		</el-table>


		<!-- <el-drawer
		    v-model="drawer"
		    title="添加子菜单"
		    :direction="direction"
		    :before-close="handleClose"
		  >
		    <span>
		    	当前菜单名称：{{}}
		    	<el-input label="名称" v-model="name" placeholder="请输入要添加的下级名称" />

		    	<br>
		    	<van-button>确认添加</van-button>	
		    </span>

		  </el-drawer> -->

		<el-dialog width="30%" v-model="drawer" title="添加子菜单">
		 	<span>
		 		<span>当前菜单名称：{{name}}<br><br></span>
		 		<span>
		 			<el-input label="名称" v-model="menuname" placeholder="请输入要添加的下级名称" />
		    		<br>
		 		</span>
		 	</span>
		 	<template #footer>
			      <span class="dialog-footer">
			        <el-button @click="drawer = false">取消</el-button>
			        <el-button type="primary" @click="putmenu()"
			          >确认</el-button
			        >
			      </span>
		    </template>

	 	</el-dialog>


		 <el-dialog width="30%" v-model="upnode" title="修改菜单">
		 	<span>
		 		<!-- <span>当前菜单名称：{{name}}<br><br></span> -->
		 		<span>
		 			<span v-show="inputShow">
						<span>当前菜单名称</span><br>
			 			<el-input label="名称" v-model="nodename"  />
		 			</span>
					<span>上级菜单名称</span><br>
					<el-input label="名称" v-model="topname"  />
					<span>修改顺序</span><br>
					<el-input type="number" label="顺序" v-model="soted"  />
		    		<br>


		 		</span>


		 	</span>
		 	<template #footer>
			      <span class="dialog-footer">
			        <el-button @click="upnode = false">取消</el-button>
			        <el-button type="primary" @click="onUpdate()"
			          >确认</el-button
			        >
			      </span>
		    </template>

	 	</el-dialog>


		 <el-dialog width="30%" v-model="denode" title="菜单删除">
		 	<span>
		 		<span>您确认删除菜单：{{name}}？<br><br></span>
		 		<span>

					 该操作将无法逆转
		 			

		    		<br>

		 		</span>


		 	</span>
		 	<template #footer>
			      <span class="dialog-footer">
			        <el-button @click="denode = false">取消</el-button>
			        <el-button type="primary" @click="onDelete()"
			          >确认</el-button
			        >
			      </span>
		    </template>

	 	</el-dialog>
	



</div>	
</template>


<script>
import { fail } from "assert"

	
export default{
	// 注册组件
	components:{

	},
	// 数据变量
	data() {
		return{

			//弹窗状态
			drawer:false,

			data:"",
			form:{

				"select":"",


			},

			search:"",
			name:"",
			menuname:"",
			// 修改状态
			upnode:false,
			// 删除状态
			denode:false,
			// 修改菜单名称 和 上级菜单名称
			nodename:"",
			topname:"",
			// 删除菜单id
			nodeid:"",
			// 修改顺序
			soted:"",
			inputShow:false,
			falg:"",



			
		}

	},
	// 方法
	methods:{


		// 修改提交
		onUpdate(key,item){

			// var regPos = /^[0-9]+.?[0-9]*/

			// if(regPos.test(this.item))

			if(this.flag == 1){
				if(this.topname == "" || this.topid == "" || this.soted == ""){
					console.log(this.nodename)
					console.log(this.topname)
					console.log(this.topid)
					console.log(this.soted)
					return fail
				}else{
					if(this.nodename == "" || this.topname == "" || this.topid == "" || this.soted == ""){
						console.log(this.nodename)
						console.log(this.topname)
						console.log(this.topid)
						console.log(this.soted)
						return fail
					}

				}
			}

			

			this.myaxios("/menuaction/","put",{"nid":this.nodeid,"name":this.nodename,"topname":this.topname,"uid":localStorage.getItem("uid"),"soted":this.soted})
			.then(data=>{
				if(data.code == 200){
					this.upnode = false
					this.$toast.success("修改成功")
				}else{
					this.upnode = true
					this.$toast.fail("抱歉，该上级菜单不存在")
				}
			})

		},

		// 删除提出
		onDelete(key,item){

			this.myaxios("/menuaction/","delete",{"nid":this.nodeid,"uid":localStorage.getItem("uid")})
			.then(data=>{
				if(data.code == 200){
					this.denode = false
					this.$toast.success("删除成功")
				}else{
					this.denode = true
					this.$toast.fail("抱歉，该菜单不存在")
				}
			})

		},

		// 删除弹窗
		handleDelete(key,item){
			this.name = item.name
			this.nodeid = item.id
			this.denode = true

		},
		// 修改弹窗
		handleUpdate(key,item){

			if(item.flag == 1){
				this.nodeid = item.id
				this.nodename = item.name
				this.topname = item.topid
				this.soted = item.soted
				this.flag = item.flag
				this.inputShow = false
			}else{
				this.nodeid = item.id
				this.nodename = item.name
				this.topname = item.topid
				this.soted = item.soted
				this.flag = item.flag
				this.inputShow = true

			}

			
		
			this.upnode = true
		},

		handleEdit(e,a){
			console.log(e)
			console.log(a)
			this.name = a.name
			this.drawer = true

		},

		onSubmit(){

			console.log(this.form)

		},

		getmenu(){
			this.myaxios("/menumsg/","get",{"uid":localStorage.getItem("uid")})

			.then(data=>{

				this.data = data.data


			})
		},

		postmenu(){


			this.myaxios("/menumsg/","post",{"name":this.search})

			.then(data=>{

				this.data = data.data
				// this.$toast.success("添加成功")

			})

		},

		putmenu(){

			this.myaxios("/menumsg/","put",{"name":this.menuname,"oldname":this.name})

			.then(data=>{
				this.drawer = false
				this.menuname = ""
				// this.data = data.data
				this.$toast.success("添加成功")

			})



		}

		
	},
	// 生命周期钩子函数
	// 用来在一个实例被创建之后执行
	created(){

	},
	//模板中的html渲染到了html页面中，一般用作于axios请求
	mounted(){
		this.getmenu()
	},

}

</script>

<style scoped>

/*@import url("../assets/style.css");*/

</style>