<template>
<div class="add">
			
			<div class="son">
				<h1 align="center">注册</h1>
				<!-- form 表单 -->
				<van-cell-group>
					<!-- 文本框 -->
					<van-field v-model="username"  label="用户名:" placeholder="请输入用户名"/><br>
					<van-field v-model="password1" type="password" label="密码:" placeholder="请输入密码"/><br>
					<van-field v-model="password2" type="password" label="密码:" placeholder="请再次输入密码确认"/><br>
					<van-field v-model="email" label="邮箱:" placeholder="请输入邮箱"/><br>
					<van-field v-model="phone" label="手机号:" placeholder="请输入手机号"/><br>
					<!-- <font color="#646566" style="padding-left: 16px;">选择所属部门：</font>
					<select v-model="type">
		                <option v-for="i,index in options" :value="i.id" :key="index">{{i.text}}</option>
		            </select> -->
		            <br>
					<!-- 按钮 -->
					<van-button @click="go_login" round style="width: 50%; background-color: #00508f; color: white;">去登陆</van-button>
					<van-button @click="register" round style="width: 50%; background-color: #00508f; color: white;">注册</van-button>

				</van-cell-group>
			</div>
			


</div>	
</template>


<script>
	
export default{
	components:{

	},

	data() {
		return{

			username:"",  //用户名
			password1:"", //密码
			password2:"", //确认密码
			email:"",     //邮箱
			phone:"",     //手机号
			department:"", //部门
			options:[
				{"text":"研发部","id":0},
				{"text":"销售部","id":1},
				{"text":"综合部","id":2},
			]
		}

	},
	methods:{
		// 注册请求
		register:function(){
			this.myaxios("/login/","post",{"username":this.username,"password1":this.password1,"password2":this.password2,"email":this.email,"phone":this.phone})
			.then(data=>{

				if(data.code === 200){
					this.$toast.success("注册成功")
				}else{
					this.$toast.fail(data.msg)
				}

			})
		},
		go_login(){
			this.$router.push({path:"/login"})
		}
	},
	created(){

	},
	mounted(){
		
	},

}

</script>

<style scoped>

@import url("../assets/style.css");



.add {
		position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        overflow-y: auto;
        /*background-color: #fff;*/
        background-image: url(../assets/login.gif);


}


.son{
	width: 30%;
	height: 30%;
	position: absolute;
    top: 20%;
    bottom: auto;
    left: 0;
    right: 0;
    margin: auto;
    /*margin-top: auto;*/
    background-color: pink;
}




</style>