<template>
<div>
		
		<h1 align="center">欢迎您</h1>
		

	
</div>	
</template>


<script>

import menutree from "./menutree.vue"
	
export default{

	name:"left",
	props:["data"],

	components:{
		"menutree" : menutree,
	},

	data() {
		return{

			nid:""
		}

	},

	setup(){

		function get_val(val){
			console.log(val)
		}

	},


	methods:{



		
	},
	created(){

		window.addEventListener('setItem', ()=> {
		    this.nid = sessionStorage.getItem('nid');
		    // alert(this.nid)
		    // this.getVideo(this.nid)
		    // this.Playback_Progress()
		    console.log(this.nid)

		})

	},
	mounted(){




		
	},

}

</script>

<style scoped>

@import url("../assets/style.css");


/*.font {

	font-size: 20px;
	color: black;

}*/

li{

	margin-bottom: 50px;
	/*margin-left: 50px;*/
	cursor: pointer;

}

.go{

	margin-bottom: 50px;
	margin-left: 50px;

}

/*.tac{
	width: 100%;
}*/




</style>