<template>
<div  class="menutree">
    <label v-for="menu in data" :key="menu.id">
    <el-sub-menu :index="menu.id+''" v-if="menu.children">
        <template #title>
        <span v-if="menu.flag" style="font-weight: 700;">{{menu.name}}</span>
        <span v-else>{{menu.name}}</span>
        </template>
        <label>
        <PhoneMenu :data="menu.children"></PhoneMenu>
        </label>
    </el-sub-menu>
    <el-menu-item v-else :index="menu.id+''" @click="getinfo(menu.id)"  >
		<span v-if="menu.flag" style="font-weight: 700">{{menu.name}}</span>
        <span v-else>{{menu.name}}</span>
    </el-menu-item>
    </label>
    <!-- <span  >123</span> -->
</div>
</template>


<script>

import PhoneMenu from "./PhoneMenu.vue"

	
export default{

	name:"PhoneMenu",
	props:["data"],

	components:{
		"PhoneMenu" : PhoneMenu,
	},

	data() {
		return{

			
			

		}

	},

	// setup(props,{emit}){
	// 	function getinfo(data,node){
	// 		// console.log(data)
	// 		emit("val",data)
	// 		console.log(data)
	// 	}
	// 	return{
	// 		getinfo
	// 	}
	// },



	methods:{

		

		go_out(){
			// 删除浏览器存储的信息
			localStorage.removeItem("username")
			localStorage.removeItem("uid")
			// location.reload()
			this.$router.push({path:"/login"})

		},

		// 获取新菜单数据
		get_newMenu(){

			this.myaxios("/newmenu/","get",{"uid":localStorage.getItem("uid")})

			.then(data=>{
				this.menulist = data.data
			})

		},

		// 积分获取
		get_score:function(){
			this.myaxios("/integral/","get",{"uid":localStorage.getItem("uid")})
			.then(data=>{
				console.log(data.data)

				this.score = data.data.score
				this.points = data.data.points
			})
		},
		handleNodeClick:function(data,node){

			// console.log(node)
			console.log(data)
		},
		add:function(e){

			console.log(e)

		},

		getinfo(id){

			console.log(id)
			this.resetSetItem('nid',id)
			// localStorage.setItem("nid",id)
			// alert(id)

		},

		
		
	},
	created(){

	},
	mounted(){
		// 判断浏览器中是否已存在用户名
		if(localStorage.getItem("username") == null ){

			this.username = ""

		}else{

			this.username = localStorage.getItem("username")
			// this.get_menu()
			// this.get_newMenu()
			// this.get_score()



		}
	},

}

</script>

<style scoped>

@import url("../assets/style.css");


/*.font {

	font-size: 20px;
	color: black;

}*/

.el-sub-menu is-opened{

	/*margin-bottom: 50px;*/
	/*margin-left: 50px;*/
	/*cursor: pointer;*/
	height: auto;

}

.go{

	margin-bottom: 50px;
	margin-left: 50px;

}


/*ul{

	height: 100%;

}*/


/*.tac{
	width: 100%;
}*/



</style>