<template>
<div>
	
				<van-cell-group>
					<!-- 文本框 -->
					<van-field v-model="username"  label="用户名:" placeholder="请输入用户名"/>
					<br>

					<van-field v-model="password" type="password" label="密码:" placeholder="请输入密码"/>
					<br>
					<!-- <font color="#646566" style="padding-left: 16px;">所属部门：</font>

					<select v-model="department">
						<option v-for='item in options' :value="item.id">{{item.text}}</option>
					</select> -->

					<br>
					<br><br>
					<!-- 按钮 -->
					<van-button  round style="width: 50%; background-color: #00508f; color: white;">去注册</van-button>
					<van-button  round style="width: 50%; background-color: #00508f; color: white;">登录</van-button>

				</van-cell-group>

	

</div>	
</template>


<script>
	
export default{
	// 注册组件
	components:{

	},
	// 数据变量
	data() {
		return{

			list_1 : [1,2,3,4,5,6,7,8,9,10],
			checked1:0,
			checked2:0,
			iptList:[
			   {label:'位1',key:''},
			   {label:'位2',key:''},
			   {label:'位3',key:''},
			   {label:'位4',key:''}
			],
			username:"",
			password:"",

		}

	},
	// 方法
	methods:{

		test:function(){
			// axios请求     请求地址                 请求方式
			this.myaxios("http://127.0.0.1:8000/test/","get")
			.then(data=>{
				// 打印请求后返回值
				console.log(data)

			})
			
		}
	},
	// 生命周期钩子函数
	// 用来在一个实例被创建之后执行
	created(){

	},
	//模板中的html渲染到了html页面中，一般用作于axios请求
	mounted(){
		
	},

}

</script>

<style scoped>

	/*@import url("../assets/stylel.css");*/

</style>