<template>
<div>

            <!-- <el-row>
                <el-col :xs="8" :sm="6" :md="4" :lg="3" :xl="1"
                >1231231321321</el-col>
                
            </el-row> -->

            <!-- <van-row>
                <van-col span="24">top</van-col>
                
            </van-row>

            <van-row>
                <van-col class="asd" span="4">
                    left
                    <br><br><br><br><br><br><br><br><br><br>
                    left
                </van-col>
                <van-col span="10" offset="4">
                    
                    <span>mian上</span>
                    <span>
                        mian下
                        <van-row>
                            <van-col offset="12" span="12">offset: 12, span: 12</van-col>
                        </van-row>
                        
                    </span>

                    </van-col>
            </van-row> -->
                <!-- <van-col span="4">span: 4</van-col>
                <van-col span="10" offset="4">offset: 4, span: 10</van-col> -->
                <!-- <van-col span="8">span: 8</van-col> -->
            

            
            <el-row :gutter="10">
                <!-- <el-col :xs="8" :sm="6" :md="4" :lg="3" :xl="1"
                >1</el-col> -->
                
                <el-col :span="24"
                >2
                    <el-row :gutter="10">
                        <el-col :xl="16" :lg="24" :xs="24" :sm="16"
                        >16</el-col>
                        <el-col class="hidden-sm-only" :xl="8" :lg="24"  :xs="24" :sm="8"
                        >
                            <el-button>危险</el-button>
                        </el-col>
                        <!-- <el-col :span="16">16</el-col>
                        <el-col :span="8">8</el-col> -->


                        <el-col :span="16">

                            <el-card class="box-card">
                                <template #header>
                                <div class="card-header">
                                    <span>Card name</span>
                                    <el-button class="button" text>Operation button</el-button>
                                </div>
                                </template>
                                <div v-for="o in 4" :key="o" class="text item">{{ 'List item ' + o }}</div>
                            </el-card>
                            
                        </el-col>

                        <el-col :span="8">

                            <el-card class="box-card">
                                <template #header>
                                <div class="card-header">
                                    <span>Card name</span>
                                    <el-button class="button" text>Operation button</el-button>
                                </div>
                                </template>
                                <div v-for="o in 4" :key="o" class="text item">{{ ' TWO List item ' + o }}</div>
                            </el-card>
                            
                        </el-col>



                    </el-row>
                </el-col>
                
            </el-row>

            


    <!-- <div class="card-wrapper"> -->

        <!-- <el-button @click="add()">全部反转</el-button> -->

        <!-- <div class="card-list"> -->

            <!-- <div v-for="(i,k) in list " class="card-list-item card1 move3">
                <div class="card-list-item-wrapper" @click=getAward(k)>
                    <div class="card-list-item-front " ></div>
                    <div class="card-list-item-back">
                        <div class="card-list-item-back-text">{{i.id}}</div>
                    </div>
                </div>
            </div> -->

            <!-- <div class="card-list-item card1 move1">
                <div class="card-list-item-wrapper" @click=getAward(1)>
                    <div class="card-list-item-front " ></div>
                    <div class="card-list-item-back">
                        <div class="card-list-item-back-text">123</div>
                    </div>
                </div>
            </div>


            <div class="card-list-item card2 move2">
                <div class="card-list-item-wrapper" @click=getAward(2)>
                    <div class="card-list-item-front"></div>
                    <div class="card-list-item-back">
                        <div class="card-list-item-back-text">点我翻牌</div>
                    </div>
                </div>
            </div>
            <div class="card-list-item card3 move3">
                <div class="card-list-item-wrapper" @click=getAward(3)>
                    <div class="card-list-item-front"></div>
                    <div class="card-list-item-back">
                        <div class="card-list-item-back-text">点我翻牌</div>
                    </div>
                </div>
            </div> -->
        <!-- </div> -->
    <!-- </div> -->
</div>  
</template>


<script>
import { add } from "lodash"
import 'element-plus/theme-chalk/display.css'

    
export default{
    // 注册组件
    components:{

    },
    // 数据变量
    data() {
        return{
            
            whether:false,
            flop:'点击翻牌',
            really:'',
            implement: 0,
            surplus:false,
            biutin:'点击翻牌',

            list:[{"id":1},{"id":2},{"id":3},{"id":4},{"id":5},{"id":6},{"id":7},{"id":8}]


        }

    },

    // 方法
    methods:{

        // 打乱顺序
        add(){
            
            var l = this.list.length
            var arr = this.list
            var index, temp
            while(l>0){
                index = Math.floor(Math.random()*l)
                temp = arr[l-1]
                arr[l-1] = arr[index]
                arr[index] = temp
                l--
            }
            this.list = arr
            // alert(this.list)
            console.log(this.list)
            // return arr

        },
        getAward(index){
            console.log(index)
            let a = Math.floor(Math.random() * 3 + 1);
            let frontDom = document.getElementsByClassName("card-list-item-front")[index]
            
            console.log(frontDom)
            console.log(a)

            let backDom = document.getElementsByClassName("card-list-item-back")[index];
            
            frontDom.style.background = "#00000"+index

            frontDom.setAttribute("class","card-list-item-front choosed");
            backDom.setAttribute("class","card-list-item-back choosed");

            

        }

       
    },
    // 生命周期钩子函数
    // 用来在一个实例被创建之后执行
    created(){

    },
    //模板中的html渲染到了html页面中，一般用作于axios请求
    mounted(){
        
        add()

    },

}

</script>

<style scoped>




@media screen and (max-width: 100px;) {

    .asd{
        display: none;
    }
    
}

/* @import url("../assets/style.css"); */

        .card-wrapper{
                    width: 640px;
                    height: 350px;
                    position: absolute;
                    top: 70px;
                    left: calc( 50% - 320px);
                    padding: 10px 15px 0;
        }
        .card-list{
            width: 100%;
            height: 242px;
            perspective:1000;
            perspective-origin: center center;
            -webkit-perspective:1000;	/* Safari 和 Chrome */
            -webkit-perspective-origin:  center center;	/* Safari 和 Chrome */
            transform-style: preserve-3d;
            position: relative;
        }
        .card-list-item{
            width: 192px;
            height: 242px;
            transform-style: preserve-3d;
            overflow: hidden;
               
        }
        .card-list-item-wrapper{
            width: 100%;
            height: 100%;
            position: relative;
        }
        .card-list-item-back,.card-list-item-front{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-repeat: no-repeat;
            background-size: 100% 100%;
            -webkit-backface-visibility: hidden;
            -moz-backface-visibility: hidden;
            -o-backface-visibility: hidden;
            -ms-backface-visibility: hidden;
            backface-visibility: hidden;
            -webkit-transform-style: preserve-3d;
            -moz-transform-style: preserve-3d;
            -o-transform-style: preserve-3d;
            -ms-transform-style: preserve-3d;
            transform-style: preserve-3d;
        }
        .card-list-item-back{
            background-image: url('./2.png');
            transform: rotateY(0deg);
            transition: all .8s cubic-bezier(0.68,-.55,.265,1.55);
            overflow: hidden;
            position: relative;
        }
        .card-list-item-back-text{
            width: 132px;
            height: 38px;
            background: #C92400;
            opacity: 0.65;
            border-radius: 19px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 162px;
            left: 30px;
            font-size: 24px;
            font-weight: 400;
            color: #FFE6DF;
        }
        .card-list-item-front{
            background-image: url('./1.png');
            font-size: 36px;
            font-weight: 600;
            color: #B82B0B;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            transform: rotateY(180deg);
            transition: all .8s cubic-bezier(0.68,-.55,.265,1.55);
            overflow: hidden;
        }
        .card-list-item-back.choosed{
            transform: rotateY(180deg);
        }
        .card-list-item-front.choosed{
            transform: rotateY(0deg);
        }
        .card1{
            /* position: absolute;
            top: 4px;
            left: 0; */
            float: left;
        }
        .card2{
            position: absolute;
            top: 4px;;
            left: 205px;
        }
        .card3{
            position: absolute;
            top: 4px;
            left: 410px;
        }
        .move1{
            animation:move1 1s ease-in-out 3;
        }
        .move2{
            animation:move2 1s ease-in-out 3;
        }
        .move3{
            animation:move3 1s ease-in-out 3;
        }
        @keyframes move1{
            0%   {left:0px; top: 4px;z-index: 9;transform: scale(1);}
            50%  {left:253px; top: 2px;z-index: 99;transform: scale(1.04);}
            100% {left:0px; top: 4px;z-index: 9;transform: scale(1);}
        }
        @keyframes move2{
            0%   {left:205px;top: 4px;z-index: 9;transform: scale(1);}
            25%  {left:230px;top: 6px;z-index: 5;transform: scale(1.03);}
            50%  {left:205px;top: 4px;z-index: 9;transform: scale(1.03);}
            75%  {left:180px;top: 6px;z-index: 5;transform: scale(1.03);}
            100% {left:205px;top: 4px;z-index: 9;transform: scale(1);}
        }
        @keyframes move3{
            0%   {left:410px;top: 4px;z-index: 9;z-index: 9;transform: scale(1);}
            50%  {left:157px;top: 2px;z-index: 99;z-index: 999;transform: scale(1.04);}
            100% {left:410px;top: 4px;z-index: 9;z-index: 9;transform: scale(1);}
        }
        @keyframes lighter {
            0%   {font-size: 24px;font-weight: 400;color: #FFE6DF;}
            50%  {font-size: 25px;font-weight: 500;color: #fcfaf9;}
            100% {font-size: 24px;font-weight: 400;color: #FFE6DF;}
        }
        .card-text-ani{
            animation:lighter 0.75s ease-in-out infinite;
        }
        .prize-notice {
            width: 450px;
            height: 48px;
            background: #C8ECFF;
            border-radius: 24px;
            line-height: 48px;
            position: absolute;
            top: 280px;
            right: calc(50% - 225px) ;
            align-items: center;
            overflow: hidden;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding-left: 35px;
        }
        .prize-notice-img{
            width: 26px;
            height: 23px;
            margin-right: 19px;
        }
        .slider-frame{
        height: 48px!important;
        line-height: 48px!important;
        }
        .my-carousel .v-item {
            width: 370px;
            height: 100%;
            font-size: 26px;
            display: flex;
            font-weight: normal;
            justify-content: flex-start;
            align-items: center;
            box-sizing: border-box;
            margin-left: 0;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }






</style>