<template>
<div>
	<h1>学习进度</h1>
	<!-- <div class="demo-collapse"> -->
		
	<!-- </div> -->
	<el-tree :data="data" :props="defaultProps" node-key="id"  @node-click="handleNodeClick">

		<template #default="{node,data}">

			<span class="custom-tree-node">
				<span>{{node.label}}</span>
				

			</span>
			
			
		</template>

	</el-tree>



	<el-dialog
    v-model="dialogVisible"
    :title="content"
    width="700px"
    :before-close="handleClose"
  >

    <span><video  v-show="is_show" id="media" width="600" height="300"  :src="src" controls="container" /></span>
    <div v-show="question_show">
			<ul>
				<li v-for="i,x in question">

					{{x+1}}.{{ i.content }}<br>
					<van-radio-group v-model="checked[x]" direction="horizontal">
					<span v-for="j in i.answer">
					

						<br><van-radio  :name="j.id">{{j.text}}</van-radio>


					</span>
					</van-radio-group>

				</li>
			</ul>

	</div>

	<van-button v-show="button_show" plain type="primary" style="margin: 5px;" round @click="postquestion">提交</van-button>

	<table v-show="table_show" border="1" cellspacing="0">
			<tr>
				<th colspan="2"><font color="red">错误提示</font></th>
			</tr>
			<tr>
				<td>问题</td>
				<td>正确答案</td>
			</tr>
			<tr v-for="i in answer">
				<td>{{i.content}}</td>
				<td>{{i.answer_true.text}}</td>
			</tr>
			
	</table>

    <template #footer>
      <span class="dialog-footer">
        <!-- <el-button @click="dialogVisible = false">重新观看</el-button> -->
        <el-button type="primary" @click="dialogVisible = false"
          >Close</el-button
        >
      </span>
    </template>
  </el-dialog>


	<!-- <div v-show="question_show">
			<ul>
				<li v-for="i,x in question">

					{{x+1}}.{{ i.content }}<br>
					<van-radio-group v-model="checked[x]" direction="horizontal">
					<span v-for="j in i.answer">
					

						<br><van-radio  :name="j.id">{{j.text}}</van-radio>


					</span>
					</van-radio-group>

				</li>
			</ul>

	</div>
	<van-button v-show="button_show" plain type="primary" style="margin: 5px;" round @click="postquestion">提交</van-button> -->



	

</div>	
</template>


<script>





	
export default{
	// 注册组件
	components:{

	},
	// 数据变量
	data() {
		return{

			data: "",
			defaultProps:{children:"children",label:"label",isLeaf:"f_FullName"},
			// 弹窗状态
			dialogVisible:false,
			// 提交按钮
			button_show : false,
			// 问题显示状态
			question_show : false,
			// 问题列表
			question:"",
			// 单选框选项
			checked:{
				// 0:0,
				// 1:0,
				// 2:0,

			},
			src:"",
			is_show:true,
			lid:"",
			did:"",
			cid:"",
			vid:"",
			answer:"",
			table_show:false,
			content:""

		}

	},
	// 方法
	methods:{
		get_study(){
			this.myaxios("/study/","get")
			.then(data=>{
				this.data = data
			})
		},

		handleNodeClick(data,node){

			// var res = this.$refs.intertree.store.nodesMap[0]

			// console.log(data,node)

			// console.log(node.isLeaf)

			// console.log(data.id)

			// console.log(data.label)

			if(node.isLeaf === true){
				this.myaxios("/study/","post",{"uid":localStorage.getItem("uid"),"did":localStorage.getItem("did"),"sname":data.label})
				.then(data=>{
					console.log(data)
					if(data.code === 200){
						this.dialogVisible = true
						this.question_show = false
						this.button_show = false
						this.is_show = true
						this.table_show = false
						this.lid = data.lid
						this.did = data.did
						this.cid = data.cid
						this.vid = data.vid
						// 请求视频及问题
						this.myaxios("/study/","put",{"lid":data.lid,"did":data.did,"cid":data.cid,"vid":data.vid}).then(res=>{
								console.log(res)
								var questionlist = ""
								questionlist = res.data
								console.log(questionlist)
								this.src = "http://101.200.139.27:8000/static/" + res.filename
								// console.log(this.src)
								// this.levelname =data.levelname
								// this.coursename = data.coursename
								// this.videoname = res.video_name
								this.content = res.video_name
								
								for(let i=0;i<questionlist.length;i++){
									
									questionlist[i].answer = JSON.parse(questionlist[i].answer)
									// this.levelname = questionlist[i].levelname
									// this.coursename = questionlist[i].course
								
								}
								console.log(questionlist)
								this.question = questionlist
								for(let x=0;x<res.leng;x++){
									this.checked[x] = 0
								}
								console.log(this.checked)
								this.Playback_Progress()


						})

					}else if(data.code === 403){

						this.$toast.fail(data.msg)

					}
					
					
				})
			}

		},



		//视频进度判断
		Playback_Progress(){
			if(this.is_show==true){
				document.getElementById('media').addEventListener("ended",()=>{
					// this.$toast.success("播放完毕,请回答下列问题")
					// this.checked = {}
					this.is_show = false
					this.question_show = true
					this.button_show = true
				})
			}
		},


		postquestion:function(){

			console.log(this.checked)


			for (var k in this.checked){
				// alert(k)
				if(this.checked[k] === 0){
					this.$toast.fail("请填写完整答案")
					return
				}
				
				
			}
			this.show = true
			var lists = [];

			for (var k in this.checked){

				for (var j in this.question){
					var dicts = {};
					

					dicts["question_id"] = this.question[k]["content_id"]
					dicts["answer"] = this.checked[k]

					
				}
				lists.push(dicts)
			}

			this.myaxios("/study/","delete",{"questionlist":JSON.stringify(lists),"lid":this.lid,"cid":this.cid,"did":this.did,"vid":this.vid})
			.then(data=>{

				if(data.code === 200){

					
					this.success_show = true

					this.button_show = false

					this.dialogVisible = false

					this.$toast.success("回答正确！")

					// this.post_score()

					// this.Jump_stage()

				}else{

					this.$toast.fail("抱歉")
					
					this.table_show = true

					this.button_show = false

					this.answer = data.data

				}
				
			})


		},

		
	},
	
	// 生命周期钩子函数
	// 用来在一个实例被创建之后执行
	created(){

	},
	//模板中的html渲染到了html页面中，一般用作于axios请求
	mounted(){
		this.get_study()
		// this.Playback_Progress()
		// this.get_score()
		
	},

}

</script>

<style scoped>

@import url("../assets/style.css");

video::-webkit-media-controls-timeline {
        display: none;
    }

</style>