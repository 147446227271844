<template>
<div>
    
    <p>
      <el-button @click="addscoure()">积分下载</el-button>
      &nbsp;
      <el-button @click="addcomment()">评论导出</el-button>
    </p>

    <el-radio-group @change="chflag" v-model="flag">
        <el-radio-button :label="3">审核中</el-radio-button>
        <el-radio-button :label="1">审核通过</el-radio-button>
        <el-radio-button :label="2">审核拒绝</el-radio-button>
    </el-radio-group>



     <el-table height="800" :data="data" >
            <!-- <el-table-column prop="video_name" label="视频名称"  /> -->

            <el-table-column label="视频名称">
                <template #default="scope">
                    
                    <el-link @click="getVideomsg(scope.row)" >{{scope.row.video_name}}</el-link>

                </template>
            </el-table-column>


            <el-table-column prop="topname" label="上级菜单名称" />
            
            <el-table-column  label="详情">

                <template #default="scope">

                    <!-- <el-link v-if="scope.row.atype == 3"  @click="questioninfo(scope.row)" >查看原视频</el-link> -->
                    <!-- {{scope.row.state}} -->
                    <span v-if="scope.row.state == '审核中'">
                        
                        <el-link  @click="questioninfo(scope.row)" ><span style="color: red;">请审批</span></el-link>    

                    </span>

                    

                    <span v-if="scope.row.state == '审核通过'">审核通过</span>

                    <span v-if="scope.row.state == '审核拒绝'">审核拒绝</span>

                </template>
                
            </el-table-column>

            


            <el-table-column label="审核类型">
                <template #default="scope">

                    {{change_state(scope.row.atype)}}
                    

                </template>
            </el-table-column>

            <el-table-column prop="filestate" label="视频类型" />

            <el-table-column label="视频大小" >
                <template #default="scope">

                    {{scope.row.filetime}} <br> {{scope.row.filesize}}

                </template>


            </el-table-column>    

            <el-table-column prop="state" label="审核状态" />

                

            <el-table-column prop="audit" label="审核人" />
            <el-table-column prop="username" label="上传人" />
            <el-table-column prop="msg" label="拒绝描述" />
            <el-table-column label="详细时间" width="180" >
                <template #default="scope">


                    <span v-if="flag == 3">
                        上传时间：{{scope.row.start_time}}
                    </span>
                    <span v-else>
                        上传时间：{{scope.row.start_time}} <br> 通过时间：{{scope.row.create_time}}
                    </span>
                    

                </template>


            </el-table-column>  
            
            
            <el-table-column width="300"  align="right">
              <template #header>
                <el-input  @change="postmenu" v-model="search" size="small" placeholder="请输入关键字" />
              </template>

              <template #default="scope">
                

                <div v-if="scope.row.state == 2">
                    已拒绝
                </div>
                
                <div v-if="scope.row.state == 1">
                    已通过
                </div>
                <div v-if="scope.row.state == '审核中'">

                    <el-dropdown>
                  <el-button size="small">
                    分配审核员<el-icon class="el-icon--right"><arrow-down /></el-icon>
                  </el-button>
                  <template #dropdown>
                    <el-dropdown-menu>
                      <el-dropdown-item @click="Allot(scope.row,i.id)" v-for="i in userList">{{i.username}}</el-dropdown-item>
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>


                </div>
                
          </template>

             </el-table-column>
        </el-table>


        <el-dialog
            v-model="dialogVisible"
            title="视频播放"
            width="50%"
            append-to-body
            destroy-on-close
          >
            <span><video style="width:100%;" :src="urls" controls="controls"></video></span>
            <!-- <template #footer>
              <span class="dialog-footer">
                <el-button @click="dialogVisible = false">Cancel</el-button>
                <el-button type="primary" @click="dialogVisible = false"
                  >Confirm</el-button
                >
              </span>
            </template> -->
          </el-dialog>


        <el-dialog
            v-model="ftext"
            title="拒绝详情"
            width="50%"
            append-to-body
            destroy-on-close
          >
            <span>
                <el-input v-model="content" type="textarea" :rows="4"  />
            </span>
            <template #footer>
              <span class="dialog-footer">
                <el-button @click="FalseAudit()">确认</el-button>
                <el-button type="primary" @click="ftext = false"
                  >取消</el-button
                >
              </span>
            </template>
          </el-dialog>


        <el-dialog
            v-model="fscore"
            title="诲人不倦值"
            width="30%"
            style="text-align: center;"
          >
            <span style="text-align: center;">
              <el-select v-model="score" placeholder="请选择该视频类型">
                  <el-option label="外部视频" value="1" />
                  <el-option label="自制视频" value="0" />
              </el-select>
            </span>

            <!-- <span>
                <el-input v-model="score" placeholder="请输入您为该视频打出的分数" :rows="4" />
            </span> -->
            <template #footer>
              <span class="dialog-footer">
                <el-button @click="PassAudit()">确认</el-button>
                <el-button type="primary" @click="fscore = false"
                  >取消</el-button
                >
              </span>
            </template>
          </el-dialog>


        <el-dialog
            v-model="qinfo"
            title="问题查看"
            width="50%"
            append-to-body
            style="text-align: center;"
            destroy-on-close
          >


            <!-- <template #default> -->
            <div>
              <el-dialog
                v-model="atrue"
                width="30%"
                title="通过"
                append-to-body
                destroy-on-close
              >
                <!-- {{this.atype}} -->
                <b>类型说明：</b>
                <ul>
                  <li>（1）视频录制剪辑后做智享会分享后上传视频的，10分/分钟</li>
                  <li>（2）直接开智享会分享后上传视频的，8分/分钟</li>
                  <li>（3）自己录制剪辑直接上传视频的，5分/分钟</li>
                  <li>（4）部门内部培训录制视频上视频的，5分/分钟</li>
                  <li>（5）外部视频上传视频的，1分/分钟，市场部李苗上传到通用宣传模块的视频算外部视频</li>
                </ul>
                <br/>
                <div v-if="atype === 1 || atype === 4">
                    <el-select v-model="score" placeholder="请选择该视频类型">
                      <el-option label="1" value="1" />
                      <el-option label="2" value="2" />
                      <el-option label="3" value="3" />
                      <el-option label="4" value="4" />
                      <el-option label="5" value="5" />
                    </el-select>
                </div>

                <div v-if="atype === 2 || atype === 5 || atype === 3">
                    <el-input placeholder="请打出您满意的分数" v-model="score" />
                </div>
                

                <template #footer>
                  <span class="dialog-footer">
                    <el-button @click="PassAudit">确认</el-button>
                    <el-button type="primary" @click="atrue = false"
                      >取消</el-button
                    >
                  </span>
                </template>

              
              </el-dialog>
            </div>
            <!-- </template> -->

            <div>
              <el-dialog
                v-model="afalse"
                width="30%"
                title="拒绝"
                append-to-body
                destroy-on-close
              >
              
                <el-select v-model="atypes" placeholder="请选择拒绝形式">
                  <el-option label="正常驳回" value="2" />
                  <el-option label="将删除该文件" value="1" />
                </el-select>
                <span>
                    <el-input v-model="content" type="textarea" :rows="4"  />
                </span>

                <template #footer>
                  <span class="dialog-footer"> 
                    <el-button @click="FalseAudit">确认</el-button>
                    <el-button type="primary" @click="aflse = false"
                      >取消</el-button
                    >
                  </span>
                </template>



              </el-dialog>
            </div>

            



            <div v-if="atype == 3">

                <span><video style="width:100%;" :src="qlist" controls="controls"></video></span>

                
                
            </div>
            <div v-else>
                
                <span v-for="(i,k) in qlist">

                问题{{k+1}}：{{i.content}}<br>


                <span v-for="(j,key) in i.answer">
                    选项{{key+1}}：{{j.text}}<br>
                </span>
                正确答案：选项{{i.answer_id}}<br>
                <br>
            </span>


            </div>


            

            <template #footer>
              <span class="dialog-footer">
                <el-button @click="PassTrue()">通过</el-button>
                <el-button type="primary" @click="PassFalse()"
                  >拒绝</el-button
                >
              </span>
            </template>
          </el-dialog>

          




</div>  
</template>


<script>
import { da } from "element-plus/es/locale";
import { ElLoading } from 'element-plus'
import { showSuccessToast, showFailToast } from 'vant';

    
export default{
    // 注册组件
    components:{

    },
    // 数据变量
    data() {
        return{


            data:"",
            userList:"",
            stateList:[],
            content:"",
            // 视频弹出状态
            dialogVisible:false,

            urls:"",
            ftext:false,

            aid:"",
            // 诲人不倦值状态
            fscore:false,
            // 诲人不倦值
            score:"",
            nflag:"",
            qinfo:false, // 问题弹窗
            qlist:"", // 问题列表
            flag:"",  // 切换标志
            atype:"", // 审核类型
            atrue:false, // 通过弹出
            afalse:false,//拒绝弹出
            atypes:"",//拒绝选项


        }

    },

    // 方法
    methods:{

        PassTrue(){

            this.atrue = true

        },

        PassFalse(){

            this.afalse = true

        },

        questioninfo(info){

            this.aid = info.id

            if(info.atype == 3 ){

                this.atype = 3

                this.myaxios("/msginfo/","get",{"aid":info.id,"atype":3})
                .then(data=>{

                    this.qlist = data.data
                    this.qinfo = true

                })

            }else{

                this.myaxios("/msginfo/","get",{"aid":info.id})
                .then(data=>{

                    this.atype = info.atype
                    console.log(data.data)

                    var lists = data.data
                    for(let i=0; i<lists.length; i++){

                        // alert(lists[i].answer)
                        console.log(lists[i].answer)

                        lists[i].answer = JSON.parse(lists[i].answer)
                    }

                    this.qlist = lists
                    this.qinfo = true

                })

            }


            
            

        },

        chflag(){

            this.getMessage(this.flag)

        },

        addscoure(){
          
          this.myaxios("/download/","get")
            .then(data=>{

                // window.location.href(data.url)
                console.log(data.url)
                window.location.href = data.url;

            })

        },

        addcomment(){
          this.myaxios("/download/","post")
          .then(data=>{
                console.log(data.url)
                window.location.href = data.url;
          })
        },
        
        // 拒绝弹窗
        fdelete(e){

            this.ftext = true

            this.aid = e.id

        },

        // 通过弹窗
        tpass(e){

            // alert("123")

            this.fscore = true

            this.aid = e.id


        },


        // 播放视频

        getVideomsg(info){

            console.log(info)

            if(info.atype == 1 || info.atype == 4){

              this.myaxios("/AuditVideoHandler/","get",{"flag":this.flag,"video_name":info.video_name})
              .then(data=>{
                this.urls = data.data
                this.dialogVisible = true
              })

                // this.urls = "http://139.9.170.205:8000/static/audit/" + info.video_name
                // this.urls = "http://crnt-study-server.oss-cn-beijing.aliyuncs.com/audit/" + info.video_name

            }

            else if(info.atype == 2){

                // this.urls = "http://139.9.170.205:8000/static/video/" + info.video_name
                this.urls = "http://101.200.139.27:8000/static/video/" + info.video_name
                this.dialogVisible = true

            }

            else if(info.atype == 3){

                // this.urls = "http://139.9.170.205:8000/static/replace/" + info.video_name
                this.urls = "http://101.200.139.27:8000/static/replace/" + info.video_name
                this.dialogVisible = true

            }


            

        },

        // 过滤
        change_state(value){


            // alert(value)

            if(value == 0){

                return "无法恢复拒绝"

            }
            else if(value == 1){

                return "新视频新试题"
            }
            else if(value == 2){

                return "已存在视频上传试题"

            }

            else if(value == 3){

                return "视频替换"

            }

            else if(value == 4){

                return "新视频新试题重新上传"

            }else if(value == 5){
                return "已存在视频重新上传试题"
            }


        },


        handleEdit(e,a){
            console.log(e)
            console.log(a)
            // this.drawer = true

        },

        // 获取审核信息
        getMessage(flag){

            this.myaxios("/audit/","get",{"uid":localStorage.getItem("uid"),"flag":flag})
            .then(data=>{


                this.data = data.data

                console.log(data)

            })

        },

        // 获取经理人员
        getLeader(){

            this.myaxios("/upload/","get")
            .then(data=>{

                this.userList = data.data

            })

        },

        // 分配
        Allot(e,uid){

            console.log(e)

            this.myaxios("/audit/","put",{"uid":uid,"aid":e.id})

            .then(data=>{
                showSuccessToast("分配成功，通知已发送")
                // this.$toast.success("分配成功，通知已发送")
                this.getMessage(3)

            })

            // console.log(e)

        },

        // 通过
        PassAudit:function(){

            if(this.score == ""){
                
                return false;
            }

            const loading = ElLoading.service({
                lock: true,
                text: '😅努力上传中。。。',
                background: 'rgba(0, 0, 0, 0.7)',
            })


            this.myaxios("/audit/","post",{"uid":localStorage.getItem("uid"),"aid":this.aid,"event":this.score})

            .then(data=>{

                if(data.code == 200){

                    loading.close()
                    this.atrue = false
                    this.qinfo = false
                    this.score = ""
                    showSuccessToast(data.msg)
                    // this.$toast.success(data.msg)
                    this.getMessage(3)
                    

                }
                else{
                    loading.close()
                    this.atrue = false
                    this.qinfo = false
                    this.score = ""
                    showFailToast(data.msg)
                    // this.$toast.fail(data.msg)
                }

            })

        },


        // 拒绝
        FalseAudit:function(){

            if(this.atypes == ""){
                return
            }
            if(this.content == ""){
                return
            }

            this.myaxios("/audit/","delete",{"uid":localStorage.getItem("uid"),"aid":this.aid,"content":this.content,"atype":this.atypes})

            .then(data=>{

                if(data.code == 200){

                    this.afalse = false
                    this.qinfo = false
                    showFailToast("拒绝成功")
                    // this.$toast.fail("拒绝成功")
                    this.ftext = false
                    this.getMessage(3)

                }

            })

        },

       
    },
    // 生命周期钩子函数
    // 用来在一个实例被创建之后执行
    created(){

    },
    //模板中的html渲染到了html页面中，一般用作于axios请求
    mounted(){

        this.flag = 3
        
        this.getMessage(3)
        this.getLeader()

    },

}

</script>

<style scoped>

@import url("../assets/style.css");

</style>