<template>
<div class="main">
    <div class="info" v-for="item in datalist">
        
        <div class="left" @click="subimt(item.nid,item.video_name)">
            <el-image style="width: 100%; height: 80%" :src="url" fit="contain" />
        </div>
        <div class="right">
            <div class="title">{{ item.video_name }}</div>
            <!-- {{ item }} -->
            <div class="content">
                <!-- {{ item }} -->
            </div>
            <div class="foot">
                <div>视频时长:{{ item.video_mint }}分钟</div>
                <div>收藏时间:{{ item.create_time }}</div>
            </div>
        </div>
    </div>

</div>	
</template>
    


<script setup>
import {ref } from "vue";
import { PostVideoCollection,GetVideoCollection } from "../../utils/api"
import { ElLoading,ElMessage } from 'element-plus'

const datalist = ref([])
const url = "http://101.200.139.27:8000/static/colletion.png"

GetVideoCollection(
    {uid:localStorage.getItem("uid")}
).then(data=>{
    datalist.value = data.data
})

</script>

<script>
export default {

    // computed: {
	// 	// 使用计算属性获取 nid
	// 	nid() {
	// 	    return this.$store.getters.getMenuid;
	// 	}
	// },


	// computed: {
	// 	// 使用计算属性获取 nid
	// 	...mapGetters(['getMenuid']),
	// 	superNid() {
	// 		return this.getMenuid;
	// 	}
	// },


	// 方法
	methods: {
        subimt(vid,name){
            localStorage.setItem("videoName",name)
            this.$store.commit("setMenuid",vid)
            console.log("xiug")
        },
    }

}
</script>

    
<style scoped>
    
/* @import url("../assets/style.css"); */

.main{
    width: 100%;
    /* height: 100%; */
    overflow: hidden;
}

.info{
    width: 100%;
    /* height: 30vh; */
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.info .left{
    width:25%;
    /* background: blue; */
    text-align: center;
    display: flex;
    align-items: center;
}

.info .right{
    width:73%;
    /* background: yellow; */

}

.info .right .title{
    font-size: 26px;
    font-weight: bold;
    margin-top: 12px;
    margin-left: 5px;
    height: 20%;
}

.info .right .content{
     height: 50%;
}

.info .right .foot{
    font-size: 20px;
    display: flex;
    justify-content: space-between;
    height: 20%;
    margin-bottom: 20px;
    margin-left: 5px;
}
    
</style>