<template>
<div>
	
    <div>
        <el-row :gutter="20">
            <!-- <el-col :span="24">
                <el-dropdown style="float: right;" @command="getShopInfo">
			    <span style="padding-left:20px ;"  class="el-dropdown-link">
			      兑换记录<el-icon class="el-icon--right"><arrow-down /></el-icon>
			    </span>
			    <template #dropdown>
			      <el-dropdown-menu  >
			        <el-dropdown-item v-for="item in infolist" command="a">商品名称：{{item.name}}--兑换类型：{{item.event}}</el-dropdown-item>
			        
			      </el-dropdown-menu>

			    </template>
			  </el-dropdown>
            </el-col> -->
            <el-col :span="24">
                <van-notice-bar  color="#1989fa" scrollable background="#ecf9ff" left-icon="info-o">
                    <!-- <span>积分商城可用学习成长分：去年没用完的学习成长分+本年度超过中长期激励要求的学习成长分数;积分商城可用诲人不倦分：去年没用完的诲人不倦分+本年度超过中长期激励要求的诲人不倦分数</span> -->
                    <span>创想积分商城欢迎您~~</span>
                </van-notice-bar>
            </el-col>
            
            <el-col :span="24">
                <el-row :span="24">
                    <el-col :span="18">
                    
                        <el-descriptions
                                    class="margin-top"
                                    :column="4"
                                    :size="size"
                                    border
                                >
                                    
                                    <el-descriptions-item>
                                        <template #label>
                                            <div class="cell-item">
                                                去年剩余学习成长积分
                                            </div>
                                        </template>
                                        {{ scoreDict.xueyi1 }}
                                    </el-descriptions-item>

                                    <el-descriptions-item>
                                        <template #label>
                                            <div class="cell-item">
                                                今年获得学习成长积分
                                            </div>
                                        </template>
                                            {{ scoreDict.xueyi2 }}
                                    </el-descriptions-item>

                                    <el-descriptions-item>
                                        <template #label>
                                            <div class="cell-item">
                                                中长期学习成长积分指标
                                            </div>
                                        </template>
                                            {{ scoreDict.xueyi3 }}
                                    </el-descriptions-item>

                                    <el-descriptions-item>
                                        <template #label>
                                            <div class="cell-item">
                                                积分商城可用学习成长积分
                                            </div>
                                        </template>
                                            {{ scoreDict.use_score }}
                                    </el-descriptions-item>

                                    <el-descriptions-item>
                                        <template #label>
                                            <div class="cell-item">
                                                去年剩余诲人不倦积分
                                            </div>
                                        </template>
                                            {{ scoreDict.huiren1 }}
                                    </el-descriptions-item>
                                    
                                    <el-descriptions-item>
                                        <template #label>
                                            <div class="cell-item">
                                                
                                                今年中长期人才培育积分

                                            </div>
                                        </template>
                                            {{ scoreDict.huiren2 }}
                                    </el-descriptions-item>

                                    <el-descriptions-item>
                                        <template #label>
                                            <div class="cell-item">
                                                中长期人才培育积分指标
                                            </div>
                                        </template>
                                            {{ scoreDict.huiren3 }}
                                    </el-descriptions-item>

                                    <el-descriptions-item>
                                        <template #label>
                                            <div class="cell-item">
                                                积分商城可用诲人不倦积分
                                            </div>
                                        </template>
                                            {{ scoreDict.use_points }}
                                    </el-descriptions-item>
                                    
                        </el-descriptions>
                    </el-col>
                
                    <el-col :span="6">
                        <div class="comment" style="height: 100%;
                            padding: 0;margin: 0;margin-left: 2px;
                            display: flex;justify-content: center;align-items: center;"
                            @click="showVouchers"
                        >
                            <div>
                                <van-badge :content="vouchersList.useNum">
                                    <div style="margin-top: 2px;font-size: 20px;">优惠卷{{vouchersList.useNum}}张可用</div>
                                    <div style="font-size: 13px;">点我可查看优惠卷详情</div>
                                </van-badge>
                            </div>
                        </div>
                    </el-col>
                </el-row>
            </el-col>
        </el-row>

        <!-- <el-row :span=""> -->

        <!-- </el-> -->
        
        <van-barrage v-model="list" delay="900" duration="8000">
            <div class="video">

            

        <el-row :gutter="20">
            <el-col :span="24">
                <el-tabs
                    v-model="shop_event"
                    type="card"
                    class="demo-tabs"
                    @tab-click="eventchange"
                    
                >   
                    
                    <!-- <div >
                        {{ item }} -->
                        <el-tab-pane v-for="item in eventList"  :label="item.info" :name="item.event"></el-tab-pane>
                    <!-- </div> -->

                    <!-- <el-tab-pane  label="日用品" name="日用品"></el-tab-pane>

                    <el-tab-pane label="日用品" name="日用品"></el-tab-pane>
                    <el-tab-pane label="办公类" name="办公类"></el-tab-pane>
                    <el-tab-pane label="电子类" name="电子类"></el-tab-pane>
                    <el-tab-pane label="工服类" name="工服类"></el-tab-pane>
                    <el-tab-pane label="运动休闲类" name="运动休闲类"></el-tab-pane>
                    <el-tab-pane label="家用类" name="家用类"></el-tab-pane>
                    <el-tab-pane label="公司典藏实用类" name="公司典藏实用类"></el-tab-pane> -->
                </el-tabs>
            </el-col>
        </el-row>

        <el-row :gutter="20">

            <el-col :span="24">
                <div class="shopList">
                    <div v-for="item in this.shoplist" class="item">
                        <el-card :body-style="{ padding: '20px' }">
                            <el-image
                            flt="cover"
                            :src="item.url"
                            class="image"
                            style="width: 200px; height: 200px"
                            />
                            <div style="padding: 14px">
                                <span>{{item.name}}</span><br/>
                                <span>剩余全部兑换次数{{item.using_num}}</span><br/>
                                <span>剩余个人兑换次数{{item.self_num}}</span><br/>
                                <div class="bottom" style="margin-bottom: 10px;">
                                    <time class="time">学习成长值：{{ item.score }}&nbsp;</time>
                                    <el-button type="primary" @click="delShows(item.id,item.name,1,item.score)" text class="button">兑换</el-button>
                                </div>
                                <div class="bottom">
                                    <time class="time">诲人不倦值：{{ item.points }}&nbsp;</time>
                                    <el-button type="primary" @click="delShows(item.id,item.name,2,item.points)" text class="button">兑换</el-button>
                                </div>
                            </div>
                        </el-card>
                    </div>
                </div>
            </el-col>
            <!-- <el-col :span="6">
                <el-card>
                    112312
                </el-card>
            </el-col> -->

            <!-- {{shoplist}} -->
            <!-- <el-col :span="6" v-for="item in this.shoplist" style="margin-bottom: 20px;" >
            
                <el-card :body-style="{ padding: '20px' }">
                    <el-image
                    flt="cover"
                    :src="item.url"
                    class="image"
                    style="width: 200px; height: 200px"
                    />
                    <div style="padding: 14px">
                        <span>{{item.name}}</span><br/>
                        <span>剩余全部兑换次数{{item.using_num}}</span><br/>
                        <span>剩余个人兑换次数{{item.self_num}}</span><br/>
                        <div class="bottom" style="margin-bottom: 10px;">
                            <time class="time">学习成长值：{{ item.score }}&nbsp;</time>
                            <el-button @click="delShows(item.id,item.name,1)" text class="button">兑换</el-button>
                        </div>
                        <div class="bottom">
                            <time class="time">诲人不倦值：{{ item.points }}&nbsp;</time>
                            <el-button @click="delShows(item.id,item.name,2)" text class="button">兑换</el-button>
                        </div>
                    </div>
                </el-card>
            
            </el-col> -->


        </el-row>

            </div>
        </van-barrage>


        <el-dialog
            v-model="delShow"
            title="兑换"
            width="50%"
            append-to-body
            destroy-on-close
          >
            <span>
                
                <!-- 是否使用{{changes(event)}}兑换{{name}}? -->
                {{ errorMsg }}
                <div v-show="inputShow">
                    <el-row :span="24">

                        <el-col :span="4">优惠卷</el-col>
                        <el-col :span="20">
                            <el-button type="primary" size="small" @click="selectVouchers">选择</el-button>
                            <span v-if="VourDict!={}" style="margin-left: 10px;color: #1989fa;">{{ VourDict.name }}</span>
                        </el-col>

                        <el-col :span="4">联系人</el-col>
                        <el-col :span="20"><el-input placeholder="请输入联系人姓名" v-model="username" /></el-col>
                        <el-col :span="4">联系电话</el-col>
                        <el-col :span="20"><el-input placeholder="请输入联系人电话" v-model="phone" /></el-col>
                        <el-col :span="4">收货地址</el-col>
                        <el-col :span="20"><el-input placeholder="请输入联系人地址" v-model="address" /></el-col>
                        <el-col :span="4">备注</el-col>
                        <el-col :span="20"><el-input placeholder="请输入备注" v-model="remark" /></el-col>
                        <el-col :span="4">图片</el-col>
                        <el-col :span="20">
                            <van-uploader v-model="fileList" :after-read="afterRead"  :max-count="1" />
                        </el-col>

                    </el-row>
                </div>

            </span>
            <template #footer>
              <span class="dialog-footer" v-show="inputShow">
                <el-button @click="isShop()">确认</el-button>
                <el-button type="primary" @click="delShow = false"
                  >取消</el-button
                >
              </span>
            </template>
          </el-dialog>


          <el-divider>
            <!-- <el-icon><star-filled /></el-icon> -->
            <el-icon><BellFilled /></el-icon>
        </el-divider>

          <div class="comment-age">
            <div class="comment" v-for="item in comment">
                <div class="title">
                    <el-tag type="primary"  effect="plain">{{ item.create_time }}</el-tag>
                </div>
                <div class="content">
                    {{ item.content }}
                </div>
                <div v-if="item.child" class="child">
                    <div v-for="i in item.child">
                        <el-tag effect="dark" type="success">小创</el-tag><el-tag type="success" effect="plain">{{ i.create_time }}</el-tag>
                        <div class="content">
                            {{ i.content }}
                        </div>
                    </div>
                </div>
            </div>
          </div>

          <div style="margin-top: 10px;">
            <el-input @keyup.enter="AddComment" :rows="6" v-model="commentContet" type="textarea" placeholder="商品好不好用啊？有没有心怡的商品没有上架啊？快来讲讲吧😁，回车即可发送哦😆"/>
          </div>
        
        
        <el-dialog
            v-model="VourcShow"
            title="优惠卷查看"
            width="50%"
            append-to-body
            destroy-on-close
        >
          <div>
            <!-- {{ vouchersList }} -->
            <van-tabs v-model:active="Vouactive" @change="getVoucherList">
                <van-tab :title="`可用（${vouchersList.useNum}）`" name="1"></van-tab>
                <van-tab :title="`不可用（${vouchersList.notNum}）`" name="2"></van-tab>
            </van-tabs>
            <!-- <el-radio-group v-model="radio1" class="ml-4"> -->
            <div v-for="item in vouchersList.data">
                <!-- {{ item }} -->
                
      <!-- <el-radio value="1" size="large">Option 1</el-radio> -->
      
    
                <div class="Vour-item">
                    <div class="header" >
                        <div :style="{'color':(Vouactive==1 ? '#1989fa':'black')}" style="width: 30%;text-align: center;margin-top: 25px;">
                            <div style="width: 100%;">
                                <span style="font-size: 30px; font-weight: 600">{{item.score}}</span>积分
                            </div>
                            <div style="width: 100%;">
                                <div>无门槛</div>
                                <span v-if="item.state=='1'">
                                    抵用学习成长积分
                                </span>
                                <span v-else>
                                    抵用诲人不倦积分
                                </span>
                            </div>

                        </div>
                        <div style="width: 60%">
                            <div style="font-size: 22px;font-weight: 600;margin-top: 35px">
                                {{ item.name }}
                            </div>
                            <div style="margin-top: 3px;">
                                {{ item.create_time }} - {{ item.finish_time }}
                            </div>
                        </div>
                        <div style="width:10%;display: flex;align-items: center;"
                        >
                            <input v-if="VouSelectShow" class="custom-radio" type="radio" name="fruit" :value="item.id" v-model="VouRadio">
                            <!-- <el-radio value="2" size="large"></el-radio> -->
                        </div>
                    </div>
                    
                    <div style="border-top: solid;border-color: rgba(0, 0, 0, 0.3);">
                        {{ item.remark }}
                    </div>

                </div>

            </div>
            <el-button v-if="VouSelectShow" type="primary" block @click="submitVour">确认</el-button>
            <!-- </el-radio-group> -->

          </div>

        </el-dialog>

    </div>

</div>	
</template>



<script>
import { fa } from 'element-plus/lib/locale'
import { pid } from 'process'
import { BellFilled } from '@element-plus/icons-vue'
import { showSuccessToast, showFailToast } from 'vant';

	
export default{
    
	// 注册组件
	components:{
        BellFilled
	},
	// 数据变量
	data() {
		return{

			shoplist:"",
            infolist:"",

            itemid:"",
            event:"",
            name:"",
            delShow:false,
            shop_event:"0",
            errorMsg:"",
            // errorShow:false,
            inputShow:false,
            username:"",
            address:"",
            phone:"",
            remark:"",
            scoreDict:"",
            list:[
                { id: 100, text: '积分商城' },
                { id: 101, text: '快乐学习' },
                { id: 102, text: '高端礼物' },
                { id: 103, text: 'CRNT' },
                { id: 104, text: '老铁666！！' },
                { id: 105, text: 'Atiny' },
                { id: 106, text: '老板，求多上架商品' },
            ],

            comment:[
                {
                    create_time:"2023-02-03",
                    content:"上架一台MacBook Pro M3+1TB+32G",
                    child:{
                        create_time:"2023-02-03",
                        content:"好的 一会就上",
                    }
                },
                {
                    create_time:"2023-02-03",
                    content:"上架一台MacBook Pro M3+1TB+32G",
                    child:{
                        create_time:"2023-02-03",
                        content:"好的 一会就上",
                    }
                }
            ],

            commentContet:"",

            eventList:[],

            // 图片
            fileList:[],
            percentage:0,
            fileName:"",
            vouchersList:[],
            VourcShow:false,
            Vouactive:1,
            VouRadio:"",
            // 选择优惠卷显示
            VouSelectShow:false,
            // 优惠卷值选择
            VourDict:{},
            shopScore:"",
            isVourActive:false, // 是否查询使用优惠卷后的分数

		}

	},
	// 方法
	methods:{

        // 优惠卷详情查看
        showVouchers(){
            this.VourcShow = true
            this.VouSelectShow = false
        },

        // 选择优惠卷
        selectVouchers(){
            this.VouSelectShow = true
            this.VourcShow = true
        },

        submitVour(){
            console.log('lst--',this.vouchersList)
            console.log('rt--',this.VouRadio)
            console.log('rt--',this.shopScore)
            for(let i of this.vouchersList.data){
                console.log("ii--",i)
                if(i.id == this.VouRadio){
                    this.VourDict = i
                    break;
                }
            }
            console.log("dict",this.VourDict)

            if(this.VourDict.state == 1 && this.event != 1){
                this.$message({
                    showClose: true,
                    message:'请选择诲人不倦优惠卷',
                    type: 'error',
                })
                this.VourDict = {}
                return
            }
            else if(this.VourDict.state == 2 && this.event != 2){
                this.$message({
                    showClose: true,
                    message:'请选择学习成长优惠卷',
                    type: 'error',
                })
                this.VourDict = {}
                return
            }
            else if(this.VourDict.score > this.shopScore){
                this.$message({
                    showClose: true,
                    message:'抱歉抵用积分不能大于商品积分',
                    type: 'error',
                })
                this.VourDict = {}
                return
            }
            
            // 查询使用优惠卷后的分数
            if(this.isVourActive){
                this.GoShop(this.VourDict.id)
            }

            this.VourcShow = false
        },

        // 附件上传
        afterRead(file){
            console.log("file---",file)
            console.log("filelist--",this.fileList)
            let data = new FormData()        
            data.append('file',file.file)
            file.status = 'uploading';
            file.message = '上传中...';
            const axiosinstance = this.axios.create({withCredentials:false})
            axiosinstance({
                method:'post',
                url:'http://101.200.139.27:8000/ShowImgUpload/',
                // url:'http://10.40.92.219:8000/ShowImgUpload/',
                data:data,
                onUploadProgress:progressEvent=>{
                    let process = (progressEvent.loaded / progressEvent.total * 100 | 0)
                    console.log(process)
                    this.percentage = process
                }
            }).then(data=>{
                if(data.data.code == 200){
                    file.status = 'done';
                    this.fileName = data.data.filename
                    this.percentage = 0
                }
            }).catch(e=>{
                showFailToast("上传失败")
                file.status = 'done';
                this.fileList.value = []
            })
        },

        // 获取商品筛选项
        getShopOptions(){
            this.myaxios("/ShopCommint/","delete")
            .then(data=>{
                this.eventList = data.data
                this.shop_event = 0
            })
        },


        // 获取商城积分
        getincenScore(){
            this.myaxios("/ShopSelect/","get",{"uid":localStorage.getItem("uid")})
            .then(data=>{
                this.scoreDict = data.data
            })
        },

        eventchange(){
            // console.log("xxx",this.shop_event)
            this.getShop()
        },

        changes(value){
            if(value == "1"){
                return "学习成长"
            }
            if(value == "2"){
                return "诲人不倦"
            }
        },

        delShows(pid,name,event,score){
            // 商品积分
            this.shopScore = score

            this.itemid = pid
            this.event = event
            this.name = name 
            this.inputShow = false
            this.errorMsg = ""
            this.isVourActive = false // 点击是否查询优惠卷默认folase
            this.GoShop()
        },

        
        // 商品分数
        GoShop(VourId){
            this.myaxios("/shopmag/","put",{"uid":localStorage.getItem("uid"),"pid":this.itemid,"event":this.event,"vourid":VourId})
            .then(data=>{
                console.log(data)
                // 弹窗选择优惠卷
                if(data.code == 405){
                    this.isVourActive = true
                    this.selectVouchers() // 优惠卷弹窗
                }
                else if(data.code == 200){
                    this.delShow = true // 提示弹窗
                    this.inputShow = true
                    this.errorMsg = data.msg
                    // this.$toast.success("兑换成功")
                }else{
                    this.delShow = true // 提示弹窗
                    this.inputShow = false
                    this.errorMsg = data.msg
                    // this.$toast.fail(data.msg)
                }

            })

        },

        isShop(){

            if(this.username == undefined || this.username == ""){
                this.$message({
                    showClose: true,
                    message:'请填写联系人',
                    type: 'success',
                })
                return
            }

            if(this.phone == undefined || this.phone == ""){
                this.$message({
                    showClose: true,
                    message:'请填写电话',
                    type: 'success',
                })
                return
            }

            if(this.address == undefined || this.address == ""){
                this.$message({
                    showClose: true,
                    message:'请填写地址',
                    type: 'success',
                })
                return
            }

            


            this.myaxios("/shopmag/","delete",
            {"uid":localStorage.getItem("uid"),"pid":this.itemid,"event":this.event,
             "username":this.username,"phone":this.phone,"address":this.address,
             "remark":this.remark,"img":this.fileName,
             "vourid":this.VourDict.id
            })
            .then(data=>{

                
                console.log(data)
                if(data.code == 200){
                    this.inputShow = false
                    this.delShow = false
                    this.username = ""
                    this.phone = ""
                    this.address = ""
                    this.$message({
                        showClose: true,
                        message:'物品备货中，请您耐心等待',
                        type: 'success',
                    })
                    
                    this.getShop()

                }else{
                    // this.delShow = 
                    this.errorMsg = data.msg
                    this.$message({
                        showClose: true,
                        message:data.msg,
                        type: 'error',
                    })
                }

            })
        },

		test:function(){
			// axios请求     请求地址                 请求方式
			this.myaxios("http://127.0.0.1:8000/test/","get")
			.then(data=>{
				// 打印请求后返回值
				console.log(data)

			})
			
		},
        getShop:function(){

            this.myaxios("/shopmag/","get",{"event":this.shop_event,"uid":localStorage.getItem("uid")})
            .then(data=>{
                this.shoplist = data.data
            })


        },

        // 兑换记录
        getShopInfo:function(){

            // alert("123")

            this.myaxios("/shopview/","get",{"uid":localStorage.getItem("uid")})
            .then(data=>{

                this.infolist = data.data

            })


        },

        // 评价获取
        GetComment(){
            this.myaxios("/ShopCommint/","get")
            .then(data=>{
                if(data.code == 200){
                    this.comment = data.data
                    let delay = 0;
                    for (let i of data.data) {
                        setTimeout(() => {
                            this.list.push({
                                id: i.id,
                                text: i.content
                            });
                            console.log(this.list);
                        }, delay);
                        delay += 3000; // 累加延迟时间
                    }
                }
            })
        },

        // 评价提交
        AddComment(){
            if(this.commentContet == "" || this.commentContet == undefined){
                this.$message({
                    showClose: true,
                    message:"老哥，回车前记得先输入文字😥",
                    type: 'error',
                })
                return;
            }
            this.myaxios("/ShopCommint/","post",{"uid":localStorage.getItem("uid"),"content":this.commentContet})
            .then(data=>{
                if(data.code == 200){
                    this.$message({
                        showClose: true,
                        message:"小创已收到，请敬请期待🥰",
                        type: 'success',
                    })
                    this.commentContet = ""
                    this.GetComment()
                }
            })
        },

        // 获取优惠卷列表
        getVoucherList(){
            this.myaxios("/VouChers/","get",{"uid":localStorage.getItem("uid"),"event":this.Vouactive})
            .then(data=>{
                this.vouchersList = data
            })
        }

        
	},
	// 生命周期钩子函数
	// 用来在一个实例被创建之后执行
	created(){

	},
	//模板中的html渲染到了html页面中，一般用作于axios请求
	mounted(){
        this.getShopOptions()
		this.getShop()
        // this.getShopInfo()
        this.getincenScore()
        this.getVoucherList()
        this.GetComment()
        
	},

}

</script>

<style scoped>

@import url("../assets/style.css");


.Vour-item {
    background-color: white;
    width: 95%;
    height: 150px;
    margin: 10px;
    padding: 10px;
    background-color: #fff; /* 设置背景色 */
    border-radius: 5px; /* 设置圆角半径 */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); /* 设置阴影 */
}

.Vour-item .header {
    display: flex;
    height: 120px;
}

.custom-radio {
  width: 20px; /* Adjust the width */
  height: 20px; /* Adjust the height */
  accent-color: rgb(25, 137, 250); /* Change the color */
}

/* For older browsers that don't support accent-color */
.custom-radio:checked {
  background-color: rgb(25, 137, 250);
  border-color: rgb(25, 137, 250);
}


.image {
  width: 100%;
  height: 100%;
  display: block;
}

.shopList{
    display: flex;
    flex-wrap: wrap;
    justify-content:space-between;
    
}

.item{
    margin-top: 10px;
}


.comment-age{
    height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
    /* text-align: center; */
    /* overflow-x: auto; */
    display: flex;
    justify-content: center; /* 水平居中 */
    flex-wrap: wrap;
    /* align-items: center;  */
    margin-top: 10px;
}


.comment{
    background-color: white;
    width: 95%;
    height: auto;
    margin: 10px;
    padding: 10px;
    background-color: #fff; /* 设置背景色 */
    border-radius: 15px; /* 设置圆角半径 */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); /* 设置阴影 */
    
}

.child{
    padding: 10px;
    margin-left: 20px;
    margin-top: 10px;
    border-left : 1px solid rgba(0, 0, 0, 0.3);
    border-bottom : 1px solid rgba(0, 0, 0, 0.3);
}

.comment .title{
    font-weight: 400;
    font-size: 18px;
    margin-top: 20px;
}

.comment .content{
    text-indent: 2em;
    margin-top: 2px;
}

.comment-age::-webkit-scrollbar-thumb {
    border-radius: 8px !important;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2) !important;
    background: #333 !important;
}

.comment-age::-webkit-scrollbar {
    width: 4px;
}

::v-deep .el-divider__text{
    background-color: transparent;
}


</style>