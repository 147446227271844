<template>
<div>
	
    <el-row :span="24" style="background-color: white;" >
        <el-col :span="12">

            <van-image @click="sss" v-model="isCollapse" style="padding-top: 10px; width: 126px; height: 30px;" src="http://101.200.139.27:8000/static/logo.png"/>
            
            
								
        </el-col>
        <el-col :span="12">
            <div style="padding-top: 13px;">

                <el-select v-model="seach" filterable placeholder="请输入选择点播视频">
                    <el-option
                        v-for="item in SearChList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                        />
		        </el-select>

            </div>
        </el-col>
    </el-row>
    <el-row :span="24">
        <el-col :span="24"></el-col>
        <el-col :span="24">
            
            <!-- <video ref="video"    id="media" width="600" height="300"    :src="src" controls="container" /> -->
            <!-- x5-video-player-type="h5" -->
            <div v-show="VideoShow">
              <video id="media" 
                webkit-playsinline="true" playsinline="true"   x5-playsinline="" 
                x-webkit-airplay="allow"
                x5-video-player-type="h5" 
                x5-video-player-fullscreen="true" 
                x5-video-orientation="portraint"

                style="object-fit: contain; width: 100vw;height: 30vh;z-index: 2;"  
                controls="controls" :src="src"   />
            </div>
        </el-col>
        <br/>
        <el-col :span="24">
          <el-row :span="24" style="margin-top: 5%;" >
              <el-col :span="8">
                <p>
                    <van-tag plain type="primary">学习成长值</van-tag>
                    <van-tag mark type="primary">{{scorelist.score}}</van-tag>
                </p>

                <p>
                  <van-tag plain type="primary">诲人不倦值</van-tag>
                  <van-tag mark type="primary">{{scorelist.points}}</van-tag>
                </p>
                
              </el-col>
              <el-col :span="8"></el-col>
              <el-col :span="8">
                <div style="text-align: center; padding-top: 19px;">
          
                    <van-icon @click="add" name="chat-o" badge="99+" size="2rem" />
                  </div>
              </el-col>
          </el-row>
        </el-col>
        
        
        
    </el-row>

    <el-row>
        
        <div v-for="(i,j) in question" v-show="question_show">
            
            <el-col :span="24">
                <p style="margin-left: 10px;">
                    <!-- {{x+1}}.{{ i.content }}<br/> -->

                    <font color="#00508f"><b>{{j+1}}.{{i.content}}</b></font>
                    
                </p>
            </el-col>
            <el-col :span="24">
                    {{item}}

                    <van-radio-group  v-model="checked[j]">
						<span  v-for="(item,key) in i.answer">
								
							<van-radio style="margin-left: 20px; margin-top: 5px;" :name="item.id">{{item.text}}</van-radio>

						</span>
					</van-radio-group>


            </el-col>
            
            
        </div>
            <el-col :span="24">
                <el-row :span="24" v-show="button_show">
                    <el-col :span="12">
                        <div style="text-align: center; margin-top: 10px;">
                            <el-rate v-model="value" :colors="colors" />
                            
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div style="text-align: center; margin-top: 10px;">
                            <!-- <el-button type="success" icon="Check" circle /> -->

                            <el-button @click="postquestion"  round type="primary">提交</el-button>
                        </div>
                    </el-col>
                </el-row>
            </el-col>
            <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
            <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
            <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
            <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
            <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
            <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
            <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>

            <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
            <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
            v
            v1312 
          <!-- <el-col :span="24" v-for="i in ftext">
            <br/><br/>
            <p style="margin-left: 10px;">错误问题：{{i["content"]}}</p>
            <p style="margin-left: 10px;">正确答案：<span style="color:red;">{{i["answer"]}}</span></p>
          </el-col> -->

    </el-row>

    

    <!-- v-show="isCollapse" -->
    <el-menu
        v-show="isCollapse"
        default-active="2"
        class="el-menu-vertical-demo"
        @open="handleOpen"
        @close="handleClose"
        style="
        width: 100vw;
        position: fixed;
        z-index: 1;
        height: 100vh;
        top: 0;
        left: 0;"
      >
        <el-menu-item @click="isCollapse=false,VideoShow=true">
          <span ><van-image  style=" width: 313px; height: 54px;" src="http://101.200.139.27:8000/static/contentlogo.png"/></span>
          <!-- <div style="padding-left: 70%;">X</div> -->
        </el-menu-item>

        <PhoneMenu @val="getinfo" :data="menuList" />


      </el-menu>

    <el-dialog width="80%" v-model="dialogFormVisible" title="提示：">
				 	<span>您是否确认提交</span>
				 	<template #footer>
					      <span class="dialog-footer">
					        <el-button @click="dialogFormVisible = false">取消</el-button>
					        <el-button type="primary" @click="post_answer"
					          >确认</el-button
					        >
					      </span>
					    </template>

				 </el-dialog>

    
    <van-popup v-model:show="show" position="bottom" :style="{ height: '30%' }" >
      <el-col :span="24" v-for="i in ftext">
            <br/><br/>
            <p style="margin-left: 10px;">错误问题：{{i["content"]}}</p>
            <p style="margin-left: 10px;">正确答案：<span style="color:red;">{{i["answer"]}}</span></p>
      </el-col>
    </van-popup>

    
    <div ref="container">
      <van-popup
        v-model:show="commentshow"
        closeable
        round
        position="bottom"
        title="评论"
        :style="{ height: '80%' }"
        :close="VideoShow=true"
      > 
        <div style="text-align: center; padding: 10px;">评论</div>
        <div v-for="i in data">
          <van-cell :title="i.username" :value="i.create_time" :label="i.content" />
        </div>
        <van-sticky :container="container" position="bottom">
          <el-row :span="24">
            <el-col :span="18">
              <el-input v-model="content" placeholder="匿名评论--"></el-input>
            </el-col>
            <el-col :span="6">
              <div style="text-align: center;">
                <el-button @click="postComment" type="primary">发送</el-button>
              </div>
              
            </el-col>
          </el-row>
          
        </van-sticky>

        
      </van-popup>
    </div>

</div>	
</template>


<script>

import PhoneMenu from "./PhoneMenu.vue"

	
export default{
    
	// 注册组件
	components:{

    "PhoneMenu":PhoneMenu,

	},
	// 数据变量
	data() {
		return{
          isCollapse:false,
          VideoShow:true,
          currTime: null,
			    maxTime:0,
          question:"",
          checked:{},
          menuList:"",
          src:"http://101.200.139.27:8000/static/video/焊缝跟踪器总体介绍p.mp4",
          seach:"",
          SearChList:"",
          question_show:false,
          dialogFormVisible:false,
          value:"",
          button_show:false,
          ftext:"",
          show:false,
          commentshow:false,
          data:"",
          content:"",
          nid:"",
          scorelist:"",
          vid:""
		}

	},

  watch:{

		// seach:{
			seach:function(newVal,oldVal){
				console.log(newVal,oldVal)
				this.resetSetItem('nid',newVal)
			},
			// deep:true,
			// immediate:true,
		// }

    

	},

	// 方法
	methods:{

      sss(){
        // alert(this.VideoShow)
        this.VideoShow=false
        // alert(this.VideoShow)
        this.isCollapse=true
      },

      add(){
        // alert(this.VideoShow)
        this.VideoShow=false
        this.commentshow = true
      },

      getscore:function(){
        this.myaxios("/score/","delete",{"uid":localStorage.getItem("uid"),"vid":this.vid})
        .then(data=>{
          this.scorelist = data.data
        })
      },

      postComment(){

            if(this.content === ""){
                this.$toast.fail("抱歉，评论不能为空白！")
                return
            }

            this.myaxios("/comment/","post",{"uid":localStorage.getItem("uid"),"nid":this.nid,"content":this.content})

            .then(data=>{
                this.content = ""
                this.getComment(this.nid)
            })

        },

      postQuestion(){

      },

      getinfo(item){
        console.log(item)
			},

      // 视频进度判断
      Playback_Progress(){
          this.question_show=false
          document.getElementById('media').addEventListener('play', ()=>{ //播放
              console.log("开始播放");
              this.ftext = ""
              this.question_show=false
              this.button_show = false
          });
          document.getElementById('media').addEventListener("ended",()=>{
            this.$toast.success("播放完毕,请回答下列问题")
            // this.is_show = false
            this.question_show = true
            this.button_show = true
            
          })
        
      },

     
     // 诲人不倦值
      put_score(){
          this.myaxios("/score/","put",{"uid":localStorage.getItem("uid"),"score":this.value,vid:this.vid})
          .then(data=>{
            console.log(data)
          })
		  },

      // 学习成长值
      post_score:function(){

          this.myaxios("/score/","post",{"uid":localStorage.getItem("uid"),"vid":this.vid})
          .then(data=>{
            console.log(data)
            if(data.code == 405){
              this.$toast.fail(data.msg)
            }
          })

		  },


    //非空验证
		postquestion:function(){

			console.log(this.checked)

			for (var k in this.checked){
				
				if(this.checked[k] === 0){
					this.$toast.fail("请填写完整答案")
					return
				}
								
			}

			if(this.value == ""){

				this.$toast.fail("请为该视频打分")
				return

			}

			this.dialogFormVisible = true

		},



    //提交
		post_answer:function(){

			// alert(this.value)

			// 诲人不倦值上传
			this.put_score()

			var lists = [];
			// 构造问题
			for (var k in this.checked){

				for (var j in this.question){
					var dicts = {};

					dicts["id"] = this.question[k]["id"]
					dicts["answer"] = this.checked[k]	
				}
				lists.push(dicts)
			}
			
			this.dialogFormVisible = false
			// alert(lists)
				
			this.myaxios("/question/","post",{"qlist":JSON.stringify(lists),"uid":localStorage.getItem("uid")})
			.then(data=>{

				if(data.code === 200){
					// this.dialogFormVisible = false

					this.$toast.success("恭喜！全部回答正确")
					this.button_show = false

					//进入积分上传
					this.post_score()

					// 星值清空
					this.value = ""

				}else{

					this.$toast.fail("“抱歉，您没有全部回答正确，请重新观看视频。”")

					this.ftext = data.data
          this.show = true
					this.value = ""
					this.button_show = false

				}
				
			})			

		},

    // 学习成长值


		

    


        // 请求视频问题
      getVideo:function(nid){
        //菜单隐藏
        this.isCollapse=false
        this.question_show=false
        this.button_show=false
        this.myaxios("/question/","get",{"uid":localStorage.getItem("uid"),"nid":nid})

        .then(data=>{

          this.vid = data.vid
          this.src = "http://101.200.139.27:8000/static" + data.filename
          // this.VideoShow=true
          //任达
          // this.src = "http://127.0.0.1:8000/static" + data.filename

          var questionlist
          questionlist = data.qlist
          // console.log(this.src)
          for(let i=0;i<questionlist.length;i++){
            
            questionlist[i].answer = JSON.parse(questionlist[i].answer)
            
          
          }
          console.log(questionlist)
          this.question = questionlist
          for(let x=0;x<data.leng;x++){
            this.checked[x] = 0
          }
				// console.log(this.checked)
				// this.userVideo()
				// this.getscore()
				// this.handleClick()


			})

		},

    

    SelectMenu(){
      this.myaxios("/PhoneManage/","get",{"uid":localStorage.getItem("uid")})
      .then(data=>{
        this.menuList = data.data
      })
    },


    getSearch:function(){
			this.myaxios("/supermenu/","put",{"uid":localStorage.getItem("uid")})
			.then(data=>{
				this.SearChList = data.data
			})
		},

    getComment(nid){

            this.myaxios("/comment/","get",{"nid":nid})
            .then(data=>{
                this.data = data.data
            })

        },

        

        
	},
	// 生命周期钩子函数
	// 用来在一个实例被创建之后执行
	created(){
        this.getSearch()
        this.SelectMenu()

        window.addEventListener('setItem', ()=> {
          let nid 
          nid = sessionStorage.getItem('nid');
          this.nid = nid
          // alert(this.nid)
          this.getVideo(nid)
          this.getComment(nid)
          this.Playback_Progress()
          this.getscore()

	    	})
       


        // this.getVideo(1862)
        

	},
	//模板中的html渲染到了html页面中，一般用作于axios请求
	mounted(){
		
	},

}

</script>

<style scoped>

@import url("../assets/style.css");

body{
    background-color:  #d9ecff;

}



.image {
  width: 100%;
  height: 100%;
  display: block;
}




</style>