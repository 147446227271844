import axios from "axios";
import qs from "qs";

// axios.defaults.baseURL = 'http://101.200.139.27:8000'  //正式
// axios.defaults.baseURL = 'http://10.40.92.219:8000' //测试

//post请求头
// axios.defaults.headers.post["Content-Type"] = "application/json;charset=UTF-8";

//允许跨域携带cookie信息
// axios.defaults.withCredentials = true; 
//设置超时
axios.defaults.timeout = 15000;

axios.interceptors.request.use(
    config => {
        
        // if(config.url.includes('/rest/2.0/xpan/file')){
        //     config.headers['Host'] = 'pan.baidu.com'
        //     config.headers['Content-Type'] = 'application/json;charset=UTF-8;aa'
        //     config.headers['Token'] = '56666'
        // }
        // console.log('headers--------',config)
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(
    response => {
        return Promise.resolve(response);
    },
    error => {
        return Promise.reject(error)
    }
);
export default {

    requests(url,methods,data){
        return new Promise((resolve, reject) => {
            if(methods == "post" || methods == "put"){
                axios({
                    method: methods,
                    url,
                    data: qs.stringify(data),
                })
                .then(res => {
                    resolve(res.data)
                })
                .catch(err => {
                    reject(err)
                });
            }else{
                axios({
                    method: methods,
                    url,
                    params: data,
                })
                .then(res => {
                    resolve(res.data)
                })
                .catch(err => {
                    reject(err)
                })
            }
            
        })
    }
};
