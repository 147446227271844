<template>
<div>
		<div v-show="xx">当前所处阶段：{{ levelname }} --- {{coursename}} 之 {{videoname}}
</div>

		<el-dialog
		    v-model="dialogVisible"
		    :title="content"
		    width="700px"
		    :before-close="handleClose"
		  >
		  	<span>抱歉，您暂时无观看该视频权限，我们将为您跳至您当前进度</span>


		</el-dialog>
		<div v-show="qx">
			<p>
				抱歉，请选择其他视频
			</p>
		</div>
		


		<br><br><br>
		<video  v-show="is_show" id="media" width="600" height="300"  :src="src" controls="container" />
		<br><br><br>
		<span v-show="is_show">
		为该视频点亮您满意的<svg v-show="is_show" style="width: 20px; height: 20px;" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" data-v-ba633cb8=""><path fill="currentColor" d="M283.84 867.84 512 747.776l228.16 119.936a6.4 6.4 0 0 0 9.28-6.72l-43.52-254.08 184.512-179.904a6.4 6.4 0 0 0-3.52-10.88l-255.104-37.12L517.76 147.904a6.4 6.4 0 0 0-11.52 0L392.192 379.072l-255.104 37.12a6.4 6.4 0 0 0-3.52 10.88L318.08 606.976l-43.584 254.08a6.4 6.4 0 0 0 9.28 6.72z"></path></svg>：<van-rate  v-model="value"/></span>
		<!-- <van-button @click="Jump_stage">11</van-button> -->

	
		<div v-show="question_show">
			
			<ul>
				<li v-for="i,x in question">

					{{x+1}}.{{ i.content }}<br>
					<van-radio-group v-model="checked[x]" direction="horizontal">
					<span v-for="j in i.answer">
					

						<br><van-radio  :name="j.id">{{j.text}}</van-radio>


					</span>
					</van-radio-group>

				</li>
			</ul>

		</div>
		<van-button v-show="button_show" plain type="primary" style="margin: 5px;" round @click="postquestion">提交</van-button>

		<van-button v-show="table_show" plain type="primary" style="margin: 5px;" round @click="show_manage">重新观看</van-button>
		<!-- <van-button v-show="success_show" plain type="primary" style="margin: 5px;" round @click="Jump_stage">下个视频</van-button> -->

		<!-- {{answer}} -->

		<van-popup v-model:show="show">
			<div style="padding:20px;">
				<!-- <font color="red"> -->
				<h4 align="center">确认提交</h4>
				<!-- </font> -->

	           	<van-button style="width: 85px; margin: 5px;" plain type="primary" @click="post_stuAnswer">确认</van-button>

	           	<van-button style="width: 85px; margin: 5px;" plain type="primary" @click="show=fales">再看看</van-button>
			</div>
           	

        </van-popup>
			




</div>	
</template>


<script>
import { ElLoading } from 'element-plus'

export default{
	components:{

		

	},

	data() {
		return{

			// state:false,
			// 视频状态
			is_show:true,
			// 问题状态
			question_show:false,
			// 正确跳转状态
			success_show : false,
			// 提交按钮
			button_show : false,
			// 问题列表
			question:"",
			// 单选框选项
			checked:{
				// 0:0,
				// 1:0,
				// 2:0,

			},
			//弹窗状态
			show:false,
			//返回错误问题答案列表
			answer:"",
			//表格状态
			table_show:"",
			//阶段名称
			levelname: "",
			//课程名称
			coursename: "",
			//视频路径
			src:"",

			videoname:"",
			vtime:"",
			value:"",
			newVal:"",
			vid :"",
			cid:"",
			did:"",
			lid:"",
			// 提示
			qx:false,
			// 默认描述文字
			xx:false,
			// 视频民
			stuname:"",
	
		}

	},


	methods:{

		openFullScreen2:function(){

			const loading = ElLoading.service({
				    lock: true,
				    text: '抱歉，您暂时没有观看该视频的权限，我们将为您跳转至您当前阶段中',
				    background: 'rgba(0, 0, 0, 0.7)',
				  })


			 setTimeout(() => {

			    loading.close()

			  }, 5000)


		},

		


		// 积分增长
		post_score:function(){

			// alert(this.vtime)
			this.myaxios("/integral/","post",{"uid":localStorage.getItem("uid"),"videotime":this.vtime})
			.then(data=>{
				console.log(data)
			})

		},

		// 诲人不倦值
		put_score:function(){
			this.myaxios("/integral/","put",{"uid":localStorage.getItem("uid"),"score":this.value})
			.then(data=>{
				console.log(data)
			})
		},


		// 获取问题
		get_question:function(){

			this.myaxios("/question/","get",{"uid":localStorage.getItem("uid")})
			.then(data=>{
				var questionlist = ""
				questionlist = data.data
				this.src = "http://192.168.2.74:8000/static/" + data.filename
				// console.log(this.src)
				this.levelname =data.levelname
				this.coursename = data.coursename
				this.videoname = data.video_name

				
				for(let i=0;i<questionlist.length;i++){
					
					questionlist[i].answer = JSON.parse(questionlist[i].answer)
					// this.levelname = questionlist[i].levelname
					// this.coursename = questionlist[i].course
				
				}
				console.log(questionlist)
				this.question = questionlist
				for(let x=0;x<data.leng;x++){
					this.checked[x] = 0
				}
				console.log(this.checked)

			})

		},

		// 提交问题
		postquestion:function(){

			console.log(this.checked)


			for (var k in this.checked){
				// alert(k)
				if(this.checked[k] === 0){
					this.$toast.fail("请填写完整答案")
					return
				}
				
				
			}
			this.show = true

		},


		post_stuAnswer:function(){

			var lists = [];

			for (var k in this.checked){

				for (var j in this.question){
					var dicts = {};
					

					dicts["question_id"] = this.question[k]["content_id"]
					dicts["answer"] = this.checked[k]

					
				}
				lists.push(dicts)
			}

			// alert(this.cid,this.lid)

			this.myaxios("/study/","delete",{"questionlist":JSON.stringify(lists),"lid":this.lid,"cid":this.cid,"did":this.did,"vid":this.vid})
				.then(data=>{

					if(data.code === 200){

						
						this.$toast.success("恭喜！全部回答正确")
						this.success_show = true

						this.button_show = false
						//进入积分上传
						this.post_score()
						//进入下一阶段
						this.Jump_stage(this.lid,this.cid,this.did,this.vid)
						// 星值清空
						this.value = ""

					}
					else{

						this.$toast.fail("“抱歉，您没有全部回答正确，请重新观看视频。”")

						this.show = false

						this.table_show = true

						this.button_show = false

						this.value = ""

					}
					
				})

		
		},


		// 确认提交
		post_answer:function(){

			var lists = [];

			for (var k in this.checked){

				for (var j in this.question){
					var dicts = {};
					

					dicts["question_id"] = this.question[k]["content_id"]
					dicts["answer"] = this.checked[k]

					
				}
				lists.push(dicts)
			}


			
			this.show = false
			// alert(lists)
				
			this.myaxios("/question/","post",{"questionlist":JSON.stringify(lists),"uid":localStorage.getItem("uid")})
			.then(data=>{

				if(data.code === 200){

					this.$toast.success("恭喜！全部回答正确")
					this.success_show = true

					this.button_show = false
					//进入积分上传
					this.post_score()
					//进入下一阶段
					this.Jump_stage()
					// 星值清空
					this.value = ""

				}else{

					this.$toast.fail("“抱歉，您没有全部回答正确，请重新观看视频。”")

					this.table_show = true

					this.button_show = false

					this.value = ""

				}
				
			})			

		},
		// 进入下个阶段
		Jump_stage:function(lid,cid,did,vid){

			this.myaxios("/question/","put",{"uid":localStorage.getItem("uid"),"lid":lid,"cid":cid,"did":did,"vid":vid})
			.then(data=>{

				if(data.code === 200){

					location.reload();

					// this.get_question()

				}
				else if(data.code === 201){

					// alert("123123")

					this.resetSetItem('stuname',data.name)

					// location.reload();


					this.study_jodge(data.name)

					this.table_show = false
					this.question_show = false
					this.show = false
					this.is_show = true


				}

				

			})

		},

		//视频进度判断
		Playback_Progress(){
			if(this.is_show==true){
				document.getElementById('media').addEventListener("ended",()=>{
					this.$toast.success("播放完毕,请回答下列问题")
					// this.checked = {}
					this.is_show = false
					this.question_show = true
					this.button_show = true

					if(this.value != ""){
						this.put_score()
					}

					
				})
			}
		},
		//重新观看
		show_manage:function(){

			this.table_show = false
			this.question_show = false
			// this.get_question()
			this.study_jodge(this.newVal)
			this.is_show = true
			

			
		},

		study_jodge:function(sname){

			// this.is_show = false
			this.question = ""
			this.checked = {}

			this.myaxios("/study/","post",{"uid":localStorage.getItem("uid"),"did":localStorage.getItem("did"),"sname":sname})
				.then(data=>{
					// 权限判断
					if(data.code === 200){

						this.qx = false
						this.xx = true

						this.checked = {}

						this.lid = data.lid
						this.cid = data.cid
						this.did = data.did
						this.vid = data.vid
						
						// 请求视频及问题
						this.myaxios("/study/","put",{"lid":data.lid,"did":data.did,"cid":data.cid,"vid":data.vid})
						.then(data=>{

							var questionlist = ""
							questionlist = data.data
							this.src = "http://101.200.139.27:8000/static/" + data.filename
							// console.log(this.src)
							this.levelname =data.levelname
							this.coursename = data.coursename
							this.videoname = data.video_name

							// alert(this.is_show)

							
							for(let i=0;i<questionlist.length;i++){
								
								questionlist[i].answer = JSON.parse(questionlist[i].answer)
							
							}
							console.log(questionlist)
							this.question = questionlist
							for(let x=0;x<data.leng;x++){
								this.checked[x] = 0
							}
							console.log(this.checked)

						})
						

					}else if(data.code === 403){



						// this.openFullScreen2()

						this.is_show = false

						this.xx = false

						this.qx = true

						this.$toast.fail(data.msg)



					}	
					
				})




		},


		get_userinfo(){

			this.myaxios("/newmenu/","post",{"uid":localStorage.getItem("uid")})

			.then(data=>{
				this.stuname = data.name
				this.resetSetItem('stuname',this.stuname)
			})

		},


		

	},
	created(){


		window.addEventListener('setItem',()=>{

			

			this.newVal = sessionStorage.getItem("stuname")
			this.question_show = false
			this.button_show = false
			this.is_show = true
			this.study_jodge(this.newVal)
			this.Playback_Progress()
		})

	},
	mounted(){


		// this.get_userinfo()
		this.newVal = sessionStorage.getItem("stuname")
		this.study_jodge(this.newVal)


		// alert(localStorage.getItem("stuid"))

		// var stuname = localStorage.getItem("stuname")
		
		// if(stuname != null){
			// this.study_jodge(stuname)
		// }

		// window.addEventListener('setItemEvent',event=>{


		// 	console.log("变成为：",event.newValue)

		// 	if(event.key === 'stuname'){
		// 		console.log(event.newValue)
		// 	}
		// })




		// alert(localStorage.getItem("did"))

		// if(localStorage.getItem("did") === "8"){

		// 	this.$toast.success("管理员您好！")


		// }else{
		// 	this.get_question()	
		// 	this.Playback_Progress()
		// }

		
		// this.get_question()	
		// this.Playback_Progress()
		// this.gettion()

		// var times 
		// var elevideo = document.getElementById("media");
		// elevideo.onloadedmetadata = function() {
  //             		console.log('metadata loaded!');
  //             		console.log(elevideo.duration); //打印时长
  //             		times = elevideo.duration
  //             		this.vtime = elevideo.duration
  //       	};
		// elevideo.addEventListener('loadedmetadata', function () { //加载数据
		// //视频的总长度
		// console.log(elevideo.duration);
		// // 查看格式
		// console.log(typeof(elevideo.duration))
		// // 保留两位小数 结果为str
		// times = elevideo.duration.toFixed(0)
		// // console.log(num)
		// // times = num
		// alert(this.vtime)
		// // 转换为
		// 	        // console.log(Math.floor((elevideo.duration)/60))
		// });
		// console.log("----------")
		// console.log(times)
		// console.log(this.vtime)
		// console.log("----------")
		
		
	},
	 

}

</script>

<style scoped>

@import url("../assets/style.css");


/*video::-webkit-media-controls-timeline {
        display: none;
    }*/

h4{
	color: red;
}

p{
	text-align: center;
	font-size: 50px;
}

</style>