<template>
	<div>

		<!-- <div style="width: 1000px; height: 100%; align:center;"> -->
		<!-- 题目加抽取 -->
		<div style="width: 1550px;">

			<div style="float: left; width: 600px;">
				<div style=" width: 600px;  height: 400px; background-color: white; font-size: 18px;">
					<el-scrollbar height="400px">

						<br>
						<ul style="padding:10px">
							<li v-for="(item, key) in qlist" :key="key">

								<span
									style="padding-left: 20px; color: #00008f">{{ key + 1 }}.&nbsp;<b>{{ item.content }}</b></span>
								<br>
								<span style="padding-left: 20px;" v-for="(i, k) in item.answer"
									:key="k">{{ change_number(k + 1) }}.&nbsp;{{ i.text }}<br></span>
								<br>
								<span v-show="show"
									style="padding-left: 20px; color:red;">正确答案：{{ change_number(item.answer_id) }}</span>
								<br><br>

							</li>
						</ul>
					</el-scrollbar>

				</div>

				<div> <!-- 盲盒专区 -->

					<div style="width: 500px; ">
						<span style="padding-left: 200px;"><b></b></span>

					</div>

					<div style="width: 500px; float: left; padding-top: 10px; padding-bottom: 20px;"> <!-- a1区域 -->

						<div style="padding-left:17px;width: 500px;"> <!-- a1 -->
							<!-- {{a1List}} -->
							<div v-for="(item, key) in aList" style="float: left;">
								<span style="padding-left: 30px;">盲盒{{ key + 1 }}</span>
								<div>

									<div class="box" @click="open(key, item)">
									</div>
								</div>

							</div>

							<br><br>


						</div>
						<div style="width:500px; height:30px;"></div>


						<!-- <div style="width: 600px; margin-top: 20px; ">
								<span style="padding-left: 260px;" ><b>多啦A梦</b></span>
				
							</div> -->


						<!-- <div style="width:300px; float:left; padding-left: 155px; padding-top:15px;">				

											
								<div v-for="(item,key) in bList" style="float: left;">
										<span style="padding-left: 29px;">盲盒{{key+1}}</span>
										<div>

													<div  class="box" @click="open(key+12,item)">
													</div>
										</div>
									
								</div>

								
							</div> -->

						<!-- <div style="float:left; width:250px; height:250px;padding-left: 155px; padding-top:10px;">
								<span style="padding-left:100px"><b>答题王大礼包</b></span>
								<div v-for="(item,key) in cList">

											<div @click="open(key+15,item)" class="box" style="width:250px;height: 250px; background-size:250px 250px;" >
											
										</div>
									
								</div>
								
							</div> -->


					</div>

					<div style="width: 500px; float: left;">

						<div v-for="(item, key) in imglist" style="float: left;">
							<span style="padding-left: 30px;">盲盒{{ key + 1 }}</span>
							<div>
								123123213
								<!-- <div style="width: 80px; height:80px;float: left;">
									<el-button>兑换</el-button>
								</div> -->

								<div class="box" @click="open(key, item)">
								</div>
							</div>

						</div>

					</div>

				</div>

			</div>

			<div style="  float: left; padding-left: 10px; width: 250px;">
				<!-- 按钮区域 -->
				<div>

					<el-select v-model="nid" placeholder="请选择抽取类型">

						<el-option label="销售部" value="6" />
						<el-option label="研发部" value="8" />
						<el-option label="工艺部" value="9" />
						<el-option label="中试部" value="2954" />
						<el-option label="技术部" value="7" />
						<el-option label="财务部" value="2955" />
						<el-option label="市场部" value="2956" />
						<el-option label="综合部" value="2957" />
						<el-option label="部门间流程" value="3036" />
						<el-option label="制度培训" value="11" />
						<el-option label="创想宣传视频" value="2679" />
						<el-option label="常用工具介绍" value="2960" />
						<el-option label="小创讲应用（39期）" value="3307" />
						<el-option label="执行力学习" value="3341" />

						<el-option label="学习提升模块" value="2221" />

					</el-select>
					<br><br>

					<el-input placeholder="请输入抽取问题数量" v-model="number">
						<template #suffix>
							<el-button @click="getqlist" type="text" style="padding-right: 10px;">点击抽取</el-button>
						</template>
					</el-input>
					<br><br>

					<el-input placeholder="请输入查询名称" v-model="name">
						<template #suffix>
							<el-button @click="getsocre" type="text" style="padding-right: 10px;">点击查询</el-button>
						</template>
					</el-input>
					<br><br>

					<span style="font-size: 18px;" v-show="score_show">
						用户：{{ name }} &nbsp;&nbsp;<br>
						当前学习成长值：{{ score }}<br>
						当前诲人不倦值：{{ points }}<br><br>
					</span>

					<div style="padding-left: 10px;">
						<el-button type="primary" @click="yesanswer">答案</el-button>
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
						<!-- <button class="yesbutton" @click="qlist = ''">清空</button> -->
						<el-button type="danger" @click="reset">重置</el-button>
						<br><br>
						<el-button type="primary" @click="enter">录入公司详情</el-button>
					</div>
				</div>

				<div>
					<ul>
						<li v-for="(item, key) in infolist">
							<!-- {{item}} -->
							<span>{{ key + 1 }}.奖品</span><br>
							<span>
								<el-image @click="imgs(item)" style="width: 150px; height: 150px;" fit="cover"
									:src="'http://101.200.139.27:8000/static/img/' + item.img" />
							</span><br>
							<span>名称：<b>{{ item.goods }}</b></span><br>
							<span>所需学习成长值：<b>{{ item.score }}</b></span><br>
							<span>所需诲人不倦值：<b>{{ item.points }}</b></span><br>
							<span style="padding-left: 120px;"><el-button @click="all(item)"
									type="success">是否兑换</el-button></span>
							<br>
						</li>
					</ul>
				</div>

				<!-- 奖品区域 -->



			</div>

			<!-- <div>

			</div> -->



			<div v-if="data" style="float: left; width:390px; padding-left: 20px;">
				<span style="padding-left: 100px; padding-top: 3px; color: #909399;">获奖名单</span>
				<el-table style="margin-top: 10px; width:390px;" :data="data">

					<el-table-column width="60" label="序号">
						<template #default="scope">
							{{ scope.row.id }}
						</template>
					</el-table-column>
					<el-table-column width="70" prop="name" label="用户" />
					<el-table-column width="100" prop="good" label="奖品" />
					<el-table-column prop="score" label="兑换积分" />
					<el-table-column width="" label="类型">
						<template #default="scope">
							{{ change_info(scope.row.event) }}
						</template>
					</el-table-column>

					<!-- <el-table-column prop="score" label="突飞猛进值" /> -->

				</el-table>
			</div>





		</div>

		<!-- 盲盒抽奖区域 -->


		<el-dialog v-model="ftext" title="兑换" width="30%">
			<span>
				{{ name }}是否兑换{{ info.goods }}？<br>
				<el-radio style="padding-right: 10px;" v-model="event" :label="1" size="large">学习成长值:{{ delscore }}</el-radio>
				<el-radio style="padding-right: 10px;" v-model="event" :label="2"
					size="large">诲人不倦值:{{ delpoints }}</el-radio>

				<br><br>
				<!-- <el-input placeholder="请输入扣除积分" v-model="delscore"/> -->
			</span>
			<template #footer>
				<span class="dialog-footer">
					<el-button @click="conversion">确认</el-button>
					<el-button type="primary" @click="ftext = false">取消</el-button>
				</span>
			</template>
		</el-dialog>

		<el-dialog v-model="imgshow" title="商品" width="500px">
			<span>

				<el-image style="background-size: 500px 600px;" fit="cover"
					:src="'http://101.200.139.27:8000/static/img/' + imgurl" />

			</span>

		</el-dialog>



		<!-- </div> -->










		<!-- <el-button @click="AllreverSal">全部反转</el-button> -->

		<el-dialog v-model="dialogVisible" title="录入公司详情"  width="40%" :before-close="handleClose">
			<div>
				<div style="font-weight: bold;">公司选择</div>
				<el-radio-group v-model="form.company" class="ml-4">
					<el-radio v-for="item in companyOptions" :label="item.label" size="large">{{ item.label }}</el-radio>
				</el-radio-group>
				<div style="font-weight: bold;margin-top:10px;">用户选择</div>
				<el-select v-model="form.username" style="width:100%;margin-top: 10px;" clearable filterable placeholder="请选择用户选择">
                    <el-option v-for="item in options" :key="item.id" :label="item.username"  :value="item.username" />
                </el-select>
				<div style="font-weight: bold;margin-top:10px;">类型</div>
				<el-radio-group v-model="form.state" class="ml-4">
					<el-radio label="1" size="large">正确</el-radio>
					<el-radio label="2" size="large">错误</el-radio>
				</el-radio-group>
				<div style="font-weight: bold;margin-top:10px;">备注</div>
				<el-input v-model="form.msg" type="textarea" style="margin-top:10px;" autosize placeholder="请输入备注" />
			</div>
			<template #footer>
				<span class="dialog-footer">
					<el-button @click="dialogVisible = false">取消</el-button>
					<el-button type="primary" @click="submitInfo">确认</el-button>
				</span>
			</template>
  		</el-dialog>
	</div>
</template>


<script>
// import { url } from 'inspector'

// import { takeRightWhile } from 'lodash'
import {GetUserList,companyEnter} from "../utils/api";

export default {
	// 注册组件
	components: {

	},
	// 数据变量
	data() {
		return {

			number: "",
			qlist: "",
			show: false,
			imglist: [],

			// 查询名字
			name: "",
			//弹窗
			ftext: false,
			// 获奖人信息
			uid: "",
			goods: "",
			score: "",
			points: "",
			score_show: false,
			//菜单id
			nid: "",
			data: "",
			infolist: [],
			info: { "uid": "", "name": "", "goods": "", "score": "" },
			delscore: "",
			imgshow: false,
			imgurl: "",
			// 奖品区域
			aList: "",
			bList: "",
			cList: "",
			dList: "",
			// message:"",
			event: "",
			delpoints: "",
			dialogVisible:false,
			companyOptions:[
				{ label: '北京公司', id: '1' },
				{ label: '济南公司', id: '2' },
				{ label: '长沙公司', id: '3' },
				{ label: '成都公司', id: '4' },
				{ label: '嘉兴公司', id: '5' },
				{ label: '淄博公司', id: '6' },
				{ label: '南京公司', id: '7' },
			],
			options:[

			],
			form:{
				company:'',
				state:'',
				msg:'',
				username:"",
			}

		}

	},
	// 方法
	methods: {
		submitInfo(){
			companyEnter(this.form).then((res)=>{
				if(res.code == 200){
					this.$message({type:'success',message:'操作成功'})
					this.dialogVisible = false;
					this.form = {};
				}
			})
		},
		enter(){
			this.dialogVisible = true;
		},

		imgs(item) {
			// alert(this.imgurl)
			console.log(item)
			this.imgshow = true
			this.imgurl = item.img;


		},

		//获取获奖信息
		getprize() {
			this.myaxios("/extract/", "delete", { "flag": 2 })
				.then(data => {
					// console.log(data)
					var lists = data.msg.info

					for (let i in lists) {
						lists[i]["id"] = Number(i) + 1
					}

					this.data = lists
				})
		},

		//兑换
		conversion() {

			if (this.uid == "") {

				// this.$toast.fail("请先查询用户积分")
				return false
			}

			if (this.event == "") {
				return false
			}

			let sc = ""

			if (this.event == 1) {
				sc = this.delscore
			} else {
				sc = this.delpoints
			}

			this.myaxios("/extract/", "put", { "uid": this.uid, "goods": this.info.goods, "score": sc, "event": this.event })
				.then(data => {
					if (data.code == 200) {
						this.$toast.success("恭喜您，兑换成功")
						this.ftext = false
						this.reset()
					}
				})

		},

		//重置
		reset() {

			this.chaos()
			this.qlist = ""
			this.info = { "uid": "", "name": "", "goods": "", "score": "" }
			this.number = ""
			this.name = ""
			this.uid = ""
			this.score_show = false
			this.show = false
			this.delscore = ""
			this.delpoints = ""
			this.event = ""
			this.infolist = []
			this.nid = ""
			console.log(this.infolist)
			this.getprize();
			// alert(this.infolist)

		},

		// 弹窗
		all(item) {

			if (this.uid == "") {
				this.$toast.fail("请先查询用户信息")
				return false
			}

			this.ftext = true
			this.goods = item.name
			this.info = item
			this.delscore = item.score
			this.delpoints = item.points


		},

		change_info(value) {
			if (value == 1) {
				return "学习成长"
			}
			if (value == 2) {
				return "诲人不倦"
			}
		},


		// 选项过滤
		change_number(value) {

			if (value == 1) {
				return "A"
			}
			if (value == 2) {
				return "B"
			}
			if (value == 3) {
				return "C"
			}
			if (value == 4) {
				return "D"
			}
			if (value == 5) {
				return "E"
			}
			if (value == 6) {
				return "F"
			}
			if (value == 7) {
				return "J"
			}
			if (value == 8) {
				return "H"
			}
			if (value == 9) {
				return "I"
			}
			if (value == 10) {
				return "G"
			}


		},

		// 全部反转
		AllreverSal() {

			let a = document.getElementsByClassName("box")
			// 正则
			let name = 'newbox'
			let reg = new RegExp("\\b" + name + "\\b")
			for (var i = 0; i < a.length; i++) {
				// 是否有反转
				if (!reg.test(a[i].className)) {
					console.log(a[i])
					a[i].classList.add(name)

				}

			}

		},

		// 打乱顺序
		orderList(lists) {
			var l = lists.length
			var arr = lists
			var index, temp
			while (l > 0) {
				index = Math.floor(Math.random() * l)
				temp = arr[l - 1]
				arr[l - 1] = arr[index]
				arr[index] = temp
				l--
			}
			return arr
		},

		// 获取奖品信息
		getjson() {
			this.myaxios("/extract/", "delete", { "flag": 1 })
				.then(data => {
					console.log(data)
					this.aList = this.orderList(data.msg["a"])
					this.bList = this.orderList(data.msg["b"])
					this.cList = this.orderList(data.msg["c"])
					this.dList = this.orderList(data.msg["d"])

					// this.imglist = this.orderList(data.msg["500"])
					// console.log(data.msg["500"])
				})
		},

		// 打乱顺序
		chaos() {

			// this.getjson()

			let a = document.getElementsByClassName("box")
			// 正则解析class名
			let name = "newbox"
			let reg = new RegExp("\\b" + name + "\\b")


			// 循环判断删除帧
			for (let i = 0; i < a.length; i++) {
				// console.log(reg.test)
				if (reg.test(a[i].className)) {
					// alert(i)
					a[i].classList.remove(name)
					a[i].style.backgroundImage = 'url("http://101.200.139.27:8000/static/img/盲盒照片5.jpeg")'

				}
			}
			console.log("1201--------------")
			console.log(a)
			// 数量-1
			a[9].classList.remove("bigbox")
			a[9].style.backgroundImage = 'url("http://101.200.139.27:8000/static/img/盲盒照片5.jpeg")'



			this.getjson()

			this.imglist = this.orderList(this.imglist)




		},

		//点击反转
		open(k, item) {

			console.log(k)
			console.log(item)

			// 人员选择信息
			// this.info.name = this.name
			this.info.goods = item.goods
			this.info.score = item.score



			let a = document.getElementsByClassName("box")[k]


			if (k == 15) {
				// alert("1")
				a.classList.add("bigbox")
			} else {
				// alert(k)
				a.classList.add("newbox")
			}





			// console.log(a)
			// a.style.cssText = `transform: rotateY(-179deg);`
			// a.classList.remove("box")

			// a.style.animation = 'jelly 0.5s'
			console.log(item)
			console.log(item['img'])
			// alert(item)
			item["state"] = true
			let url = 'url(http://101.200.139.27:8000/static/img/' + item['img'] + ')'
			console.log(url)
			a.style.backgroundImage = 'url(http://101.200.139.27:8000/static/img/' + item['img'] + ')'
			console.log(a.style.backgroundImage)
			this.$toast.success("恭喜您抽到了" + item.goods + "🎉🎉🎉")
			// console.log(a)
			console.log(this.info)

			// for(let i in this.infolist){

			// 	if()

			// }

			console.log(this.infolist.indexOf(item, 0))

			if (this.infolist.indexOf(item, 0) != -1) {

				this.$toast.fail("不要再点我啦,快去答题。")
				return false

			}

			this.infolist.push(item)
			console.log(this.infolist)




			// console.log(a.style.background-image)


		},


		// 答案隐藏显示
		yesanswer() {
			if (this.show == false) {
				this.show = true
			} else {
				this.show = false
			}
		},

		// 抽取问题
		getqlist: function () {

			if (this.nid == "") {

				this.$toast.fail("请先选择抽取类型")
				return
			}

			this.myaxios("/extract/", "post", { "number": this.number, "nid": this.nid })
				.then(data => {
					this.show = false
					var lists = data.data
					for (let i = 0; i < lists.length; i++) {

						// alert(lists[i].answer)
						console.log(lists[i].answer)

						lists[i].answer = JSON.parse(lists[i].answer)

						// for(let j=0; j<lists[i].answer.length; j++){
						// 	// alert(lists[i].answer[j].id)
						// 	if(lists[i].answer_id == lists[i].answer[j].id){

						// 		lists[i].answer_id = lists[i].answer[j].text
						// 		// alert(lists[i].answer_id)
						// 		// alert()
						// 	}

						// }


					}


					this.qlist = lists
					this.number = ""
					console.log(this.qlist)
				})

		},
		// 查询积分
		getsocre: function () {

			this.myaxios("/extract/", "get", { "name": this.name })
				.then(data => {
					if (data.code == 200) {
						this.uid = data.data.id
						// this.info.name = this.name
						this.score = data.data.score
						this.points = data.data.points
						this.score_show = true
					} else {
						this.$toast.fail("抱歉，该用户不存在")
					}


				})

		}



	},
	// 生命周期钩子函数
	// 用来在一个实例被创建之后执行
	created() {
		GetUserList().then(data=>{this.options = data.data})
	},
	//模板中的html渲染到了html页面中，一般用作于axios请求
	mounted() {
		this.getjson();
		this.getprize();
	},

}

</script>

<style scoped>
@import url("../assets/style.css");

.yesbutton {
	z-index: 1;
	position: relative;
	font-size: inherit;
	font-family: inherit;
	color: white;
	padding: 0.5em 1em;
	outline: none;
	border: none;
	background-color: hsl(236, 32%, 26%);
}

.yesbutton:hover {
	cursor: pointer;
}

.yesbutton::before {
	content: '';
	z-index: -1;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	border: 4px solid hsl(236, 32%, 26%);
	transform-origin: center;
	transform: scale(1);
}

.yesbutton:hover::before {
	transition: all 0.75s ease-in-out;
	transform-origin: center;
	transform: scale(1.75);
	opacity: 0;
}


.card {
	width: 100px;
	height: 50px;
	background: #e67e22;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 5px;
	color: whitesmoke;
	position: relative;

}

.card::before,
.card::after {
	transition: 1s;
	background: #e74c3c;
	line-height: 4em;
}

.card::before {
	content: '新年';
	width: 50px;
	height: 100%;
	left: 0px;
	top: 0;
	text-align: right;
	position: absolute;
	transform-origin: left bottom;
}

.card::after {
	content: '快乐';
	width: 50px;
	height: 100%;
	left: 50px;
	top: 0;
	position: absolute;
	transform-origin: right bottom;
}




.box {
	width: 80px;
	height: 80px;
	float: left;
	/* padding-left: 200px; */
	background-image: url("http://101.200.139.27:8000/static/img/盲盒照片5.jpeg");
	background-size: 80px 80px;
	margin-left: 10px;
	margin-top: 10px;

}

/* .box:hover {
		cursor: pointer;
		animation: jelly 0.5s;
	} */


.newbox {
	/* background-image: url("../assets/box.png"); */
	width: 80px;
	height: 80px;
	float: left;
	/* padding-left: 200px; */
	/* background-image: url("http://192.168.2.74:8000/static/img/盲盒照片5.jpeg"); */
	background-size: 80px 80px;
	margin-left: 10px;
	margin-top: 10px;
	animation-name: hd;
	animation-duration: 3s;
}


.bigbox {
	/* background-image: url("../assets/box.png"); */
	width: 80px;
	height: 80px;
	float: left;
	/* padding-left: 200px; */
	/* background-image: url("http://192.168.2.74:8000/static/img/盲盒照片5.jpeg"); */
	background-size: 80px 80px;
	margin-left: 10px;
	margin-top: 10px;
	animation-name: bighd;
	animation-duration: 3s;
}


@keyframes hd {

	0% {
		background-image: url("http://101.200.139.27:8000/static/img/盲盒照片5.jpeg");
		background-size: 80px 80px;
	}

	100% {
		transform: rotateY(-180deg);
		/* transform: rotate3d(1,0,1,-360deg); */
		/* background-color: blue; */
	}

}


@keyframes bighd {

	0% {
		background-image: url("http://101.200.139.27:8000/static/img/盲盒照片5.jpeg");
		background-size: 250px 250px;
	}

	100% {
		transform: rotateY(-180deg);
		/* transform: rotate3d(1,0,1,-360deg); */
		/* background-color: blue; */
	}

}




.rbutton {
	z-index: 1;
	font-size: inherit;
	font-family: inherit;
	color: white;
	padding: 0.5em 1em;
	outline: none;
	border: none;
	background-color: hsl(236, 32%, 26%);
}

.rbutton:hover {
	cursor: pointer;
	animation: jelly 0.5s;
}

@keyframes jelly {

	0%,
	100% {
		transform: scale(1, 1);
	}

	25% {
		transform: scale(0.9, 1.1);
	}

	50% {
		transform: scale(1.1, 0.9);
	}

	75% {
		transform: scale(0.95, 1.05);
	}
}
</style>