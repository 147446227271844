import requests from "../utils/axios";

const HTTP = requests.requests

// 获取首次菜单及下菜单
export const GetLeftMenu = (data) => {
    return HTTP("/menu/menuhandler/","get",data)
} 

// 获取视频信息
export const GetVideo = (nid) => {
    return HTTP(`/video/videohandler/${nid}/`,"get")
}

// 上传视频实体
export const AddQuestion = (data) => HTTP("/audit/audithandler/","post",data)

// 上传表单
export const PostForm = (data) => HTTP("/upload/","put",data)

// 已存在视频上传习题
export const PutForm = (data) => HTTP("/upload/","put",data)

// 上传时新视频试题下拉菜单获取
export const GetMenus = () => HTTP("/menumsg/","delete")

// 上传时视频替换下拉列表获取
export const GetUploadVideo = (data) => HTTP("/menuaction/","get",data)

// 获取草稿内容
export const GetInnserContent = (data) => HTTP("/jifen/","get",data)

// 预存方法
export const PostInnserContent = (data) => HTTP("/jifen/","post",data)

// 上传文件前查重
export const fileSelect = (data) => HTTP("/uploadinfo/","put",data)

// 获取人员积分
export const GetUserScore = (data) => HTTP("/UserHandler/","get",data)

// 获取用户列表 UserHandler
export const GetUserList = () => HTTP("/UserHandler/","get")

// 获取奖品列表
export const GetNewShop = (data) => HTTP("/UserHandler/","post",data)

// 获取抽奖后奖品列表
export const PostNewShop = (data) => HTTP("/ShopSelect/","post",data)

// 记录抽奖结果
export const PutNewShop = (data) => HTTP("/ShopSelect/","put",data)

// 获取抽奖记录
export const GetNewShopInfo = () => HTTP("/ShopSelect/","delete")

// 获取抽奖记录
export const getCompanyList = (data) => HTTP("/UserHandler/","delete",data)

// 公司录入
export const companyEnter = (data) => HTTP("/KManageHandelr/","put",data)

// 复制链接
export const copyShareURL = () => HTTP("/UserHandler/","put")

// 审核视频播放
export const GetVideoUrl = (data) => HTTP("/AuditVideoHandler/","get",data)

// 获取2024总榜
export const GetRankList = (data) => HTTP("/ranklist/","put",data)

// 收藏视频
export const PostVideoCollection = (data) => HTTP("/VideoCollection/","post",data)

// 查看收藏夹
export const GetVideoCollection = (data) => HTTP("/VideoCollection/","get",data)

// 查看该视频是否被自己收藏
export const showColleciton = (data) => HTTP("/VideoCollection/","delete",data)