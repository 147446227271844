<template>
<!-- <div style="background-image: radial-gradient(at top right, #B7B6BB 0%, #E8EAE9 100%);"> -->
<div class="unicorn">
    <div class="m-main">
        <div class="left"></div>
        <div class="main">
            <img id="image" :src="url" width="960" height="720">
            <div class="mag">
                <button class="primary" @click="rotateImage">旋转</button>
            </div>

            <!-- 曝光滑块 -->
            <div class="slider-msg">
                <el-slider @change="vidoeManage('exposure')"  v-show="sliderSelect" style="z-index: 10;" v-model="sliderValue" />
            </div>


            <div class="text-msg" id="text-msg">
                <p>当前曝光值: <span id="value">{{ sliderValue }}</span></p>
            </div>

            <div class="button-msg">
                <button @click="sliderShow" class="primary">曝光</button>
                <button @click="soildShowClick" class="primary">检测线</button>
            </div>

            <!-- 检测线与中心线 -->
            <div v-show="solidShow" class="box" id="box"></div>
            <div v-show="solidShow" class="crosshair-msg">
                <div class="crosshair">
                    <div class="horizontal"></div>
                    <div class="vertical"></div>
                </div>
            </div>
        </div>
        <div class="right">
            <!-- 检测线滑块 -->
            <el-slider vertical v-show="solidShow" style="height: 710px;" @input="leftChange" v-model="topValue" range :max="720" />
            
        </div>
    </div>

    <div class="m-main">
        <!-- 检测线滑块 -->
        <el-slider v-show="solidShow" style="width: 950px" @input="leftChange" v-model="leftValue" range :max="960" />
    </div>

    <div>
        <!-- <input type="text" id="inputFlag" placeholder="falg" /> -->
        <!-- <input type="text" id="inputNum" placeholder="num" />
        <button id="submitButton">Submit</button> -->
        
        
    </div>
</div>
</template>
        
    
    
<script setup>
import qs from 'qs'
import axios from "axios";
import { ref,onMounted } from "vue";
import { useRoute } from 'vue-router';
import { Base64 } from 'js-base64';
// import rightVue from '../right.vue';
// const host = "http://192.168.2.89:5000/"    
// const host = "http://192.168.2.2:5000/"    
// const host = "http://127.0.0.1:5000/"   
const host = ref("") 
const datalist = ref([])
const url = ref("")
const sliderValue = ref(50)
const sliderSelect = ref(false)
const leftValue = ref([0,960])
const topValue = ref([0,720])
const solidShow = ref(false)
const route = useRoute();


let rotationAngle = 0;
// 旋转按钮
const rotateImage = function() {
    rotationAngle += 90; // 每次点击旋转90度
    document.getElementById('image').style.transform = `rotate(${rotationAngle}deg)`;
}

// 检测线显示
const soildShowClick = function(){
    solidShow.value = !solidShow.value
}

const sliderShow = function(){
    if(sliderSelect.value){
        document.getElementById("text-msg").style.display = 'none';
    }else{
        document.getElementById("text-msg").style.display = 'block';
    }
    sliderSelect.value = !sliderSelect.value
}

// 设置视频参数
const vidoeManage = function(flag) {
    axios({
        url:`${host.value}video_manage`,
        method:"post",
        data:qs.stringify({
            flag:flag,
            num:sliderValue.value,
        }),
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
    }).then(({data})=>{
        console.log(data) 
    })
}

// 
const leftChange = function(){



    const box = document.getElementById("box").style

    // const top = parseInt(topValue.value[0]);
    // const bottom = parseInt(topValue.value[1]);
    const v_left = parseInt(leftValue.value[0]);
    const v_right = parseInt(leftValue.value[1]);
    const v_top = parseInt(topValue.value[0]);
    const v_bottom = parseInt(topValue.value[1]);
    console.log(topValue.value)
    if (v_left > 480){
        leftValue.value[0] = 480
        return 
    }
    else if(v_right < 480){
        leftValue.value[1] = 480
        return
    }


    else if(v_top > 360){
        topValue.value[0] = 360
        return
    }
    else if(v_bottom < 480){
        topValue.value[1] = 360
        return
    }

    const left = 480 - v_left
    const right = v_right - 480
    const top = v_bottom - 360 
    const bottom = 360 - v_top 

    // const top = parseInt(b_top.value);
    // const bottom = parseInt(b_bottom.value);
    // const left = parseInt(b_left.value);
    // const right = parseInt(b_right.value);

    const width = left + right
    const height = top + bottom
    
    

    // box.top = top + 'px';
    box.left = (480 - left) + 'px';
    box.right = (480 - right) + 'px';
    box.top = (360 - top) + 'px';
    box.bottom = (360 - bottom) + 'px';

    box.width = width + 'px'
    box.height = height + 'px'
    // box.height = (720 - top - bottom) + 'px';
    
    // let left = leftValue.value[0]
    // let right = leftValue.value[1]

    // if(left>480){

    // }

    // box.width = `${leftValue.value[0]}px`;
    // // box.width = `${leftValue.value[0]}px`;
    // console.log(leftValue.value)
}

// 获取当前曝光值
const getVideoInfo = function() {
    console.log(`${host.value}video_info`)
    axios({
        url:`${host.value}video_info`,
        method:"get",
    }).then(({data})=>{
        console.log(data)
        sliderValue.value = data.info.bunm
    })
}

onMounted(()=>{

    // URL 解码
    const encodedIp = decodeURIComponent(route.query.ip);
      
      // 解码 Base64 编码的数据
    let _host = Base64.decode(encodedIp);

    // let _host = Base64.decode(route.query.ip)
    console.log("host",_host)
    host.value = `http://${_host}:5555/`
    url.value = `http://${_host}:5555/video_feed`
    getVideoInfo()
    // console.log("执行了吗")
})



        //     ('/video_manage', {
        //         method: 'POST',
        //         headers: {
        //             'Content-Type': 'application/json',
        //         },
        //         body: JSON.stringify({ flag: flag,num:num }),
        //     })
        //     .then(response => response.json())
        //     .then(data => {
        //         console.log("Data",data)
        //         // document.getElementById('response').innerText = data.message;
        //     });
        // }

        // document.getElementById('submitButton').addEventListener('click', function() {
        //     // const flag = document.getElementById('inputFlag').value;
        //     const flag = "exposure"
        //     const num = document.getElementById('inputNum').value;
    
        //     fetch('/video_manage', {
        //         method: 'POST',
        //         headers: {
        //             'Content-Type': 'application/json',
        //         },
        //         body: JSON.stringify({ flag: flag,num:num }),
        //     })
        //     .then(response => response.json())
        //     .then(data => {
        //         console.log("Data",data)
        //         // document.getElementById('response').innerText = data.message;
        //     });
        // });

        // 设置相机参数
        

        // 滑块------------------------------------------------------------------------------------------------
        // const slider = document.getElementById("myRange");
        // const output = document.getElementById("value");
        // const toggleBtn = document.getElementById("toggleBtn");
        // const leftslider = document.getElementById("left-myRange");

        // // 初始化背景颜色
        // function updateBackground() {
        //     const percentage = (slider.value - slider.min) / (slider.max - slider.min) * 100;
        //     slider.style.setProperty('--value', percentage + '%');
        // }

        // // 显示默认滑块值
        // output.innerHTML = slider.value;

        // // 每次拖动滑块手柄时更新当前滑块值
        // slider.oninput = function() {
        //     console.log("曝光值：",this.value)
        //     output.innerHTML = this.value;
        //     updateBackground(); // 更新背景颜色
        //     vidoeManage("exposure",this.value)
        // }

        // // 页面加载时初始化背景颜色
        // updateBackground();

        // // 切换滑块显示与隐藏
        // function  sliderShow() {
        //     if (slider.classList.contains('show')) {
        //         slider.classList.remove('show');
        //         document.getElementById("text-msg").style.display = 'none';
        //     } else {
        //         slider.classList.add('show');
        //         document.getElementById("text-msg").style.display = 'block';
        //     }
        // }
        // // ------------------------------------------------------------------------------------------------

        // // 检测线
        // function soildShow() {
        //     slider.style.width = '48%'
        //     if (slider.classList.contains('show')) {
        //         slider.classList.remove('show');
        //         document.getElementById("text-msg").style.display = 'none';
        //     } else {
        //         slider.classList.add('show');
        //         document.getElementById("text-msg").style.display = 'block';
        //     }

        // }

    
</script>

    
        
<style scoped>

        
/* @import url("../assets/style.css"); */

.unicorn {
    background: 
        linear-gradient(to bottom, #E1E6EC 0%, #F0F4F7 100%), 
        linear-gradient(to bottom, #E1E6EC 0%, #F0F4F7 100%);
    background-blend-mode: normal,color-burn;
}

.crosshair-msg{
    position: absolute;
    top: 50%;
    left: 50%;
    /* width: 100%;
    height: 100%; */
    transform: translate(-50%, -50%);
    pointer-events: none; /* 使十字线不影响点击事件 */    
    
}

.crosshair {
    position: relative;
    width: 50px;
    height: 50px;
}

.horizontal, .vertical {
    position: absolute;
    background-color: pink;
}

.horizontal {
    top: 50%;
    left: 0;
    width: 100%;
    height: 1px; /* 调整线的粗细 */
    transform: translateY(-50%);
}

.vertical {
    top: 0;
    left: 50%;
    width: 1px; /* 调整线的粗细 */
    height: 100%;
    transform: translateX(-50%);
}


.m-main{
    display: flex;
    justify-content: center;
    align-content: center;
    /* background-color: white; */
}

.main {
    display: flex;
    justify-content: center;
    align-content: center;
    position: relative; /* 添加相对定位 */
    width: 960px;  /* 设置与图片一致的宽度 960 */  
    height: 720px; /* 设置与图片一致的高度 720 */
}

.primary{
    background-color: #3f85ed;
    color: white;
    border: none;
    padding: 5px;
    cursor: pointer;
    border-radius: 5px;
    margin-left: 5px;
}

.mag{
    position: absolute;
    top: 10px;  /* 距离顶部的距离 */
    right: 10px; /* 距离右边的距离 */
    width: auto; /* 自动调整宽度 */
    height: auto; /* 自动调整高度 */
    z-index: 2;
}

img{
    box-shadow:  0 0 10px 0 rgba(0, 0, 0, 0.3);
    border-radius: 0.5em 0.5em 0.5em 0.5em;
}

.box{
    width: 960px;
    height: 720px;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0); /* 可选：设置背景颜色和透明度 */
    border-radius: 5px; /* 可选：设置圆角 */
    border-color: red;
    border-style: dotted;
    z-index: 1;
    pointer-events: none; /* 使十字线不影响点击事件 */    
}

.right{
    height: 100%;
    display: flex;
}

.slider-msg{
    position: absolute;
    bottom: 35px;  /* 距离顶部的距离 */
    right: 0; /* 距离右边的距离 */
    width: 100%; /* 自动调整宽度 */
    height: auto; /* 自动调整高度 */

}

.button-msg {
    position: absolute;
    bottom: 10px;  /* 距离顶部的距离 */
    right: 10px; /* 距离右边的距离 */
    width: auto; /* 自动调整宽度 */
    height: auto; /* 自动调整高度 */
    z-index: 2; /* 确保滑块位于顶部 */
}


        /* 滑块样式------------------------------------------------------------------------------------------------------------ */

        

        .text-msg {
            display: none;
            position: absolute;
            bottom: 1px;  /* 距离顶部的距离 */
            left: 3px; /* 距离右边的距离 */
            width: 100%; /* 自动调整宽度 */
            height: auto; /* 自动调整高度 */
            color: red;

        }

        

        .slider-wrapper {
            /* position: relative; */
            width: 100%;
            height: 50px; /* 增加高度以适应手柄 */
            padding: 15px 0; /* 上下内边距以确保手柄可见 */
            box-sizing: border-box;
            overflow: hidden;
        }

        .slider {
            -webkit-appearance: none;
            appearance: none;
            width: 98%;
            height: 15px; /* 设置滑块轨道的高度 */
            background: #d3d3d3;
            outline: none;
            opacity: 0.7;
            border-radius: 5px;
            position: absolute;
            left: 0;
            transform: scaleX(0); /* 初始状态缩放为0，即不可见 */
            transform-origin: right; /* 从右侧开始动画 */
            transition: transform 0.5s ease-in-out, opacity 0.2s; /* 平滑的动画 */
        }

        

        .slider::-webkit-slider-runnable-track {
            height: 15px; /* 设置滑块轨道的高度 */
            background: linear-gradient(to right, #3f85ed 0%, #3f85ed var(--value), #d3d3d3 var(--value), #d3d3d3 100%);
            border-radius: 5px;
        }

        .slider::-webkit-slider-thumb {
            -webkit-appearance: none;
            appearance: none;
            width: 25px; /* 设置滑块手柄宽度 */
            height: 25px; /* 设置滑块手柄高度 */
            background: #3f85ed; /* 绿色背景 */
            cursor: pointer;
            border-radius: 50%; /* 圆形手柄 */
            position: relative;
            top: -5px; /* 调整手柄位置，确保其中心对齐 */
        }

        .slider::-moz-range-thumb {
            width: 25px;
            height: 25px;
            background: #3f85ed;
            cursor: pointer;
            border-radius: 50%;
        }

        .toggle-btn {
            padding: 10px 20px;
            background-color: #3f85ed;
            color: white;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            margin-top: 20px;
            font-size: 16px;
        }

        .toggle-btn:hover {
            background-color: #3f85ed;
        }

        /* 滑块样式------------------------------------------------------------------------------------------------------------ */

</style>