<template>
<div>
	
    <div>

        <el-tabs type="border-card" v-model="menu" @tab-click="changemenu">
            <el-tab-pane name="1" label="商品管理">

                <el-row>
                    <el-col :span="24">
                    
                        <el-table :data="shoplist" style="width: 100%">
                                <el-table-column label="商品名称" prop="name" />


                                <el-table-column label="实物图片"  width="120" >
                                    <template #default="scope">
                                        <el-image
                                        flt="cover"
                                        :src="'http://101.200.139.27:8000'+scope.row.url"
                                        class="image"
                                        style="width: 120px; height: 120px"
                                            />
                                        
                                    </template>
                                </el-table-column>

                                <el-table-column label="商品类型" prop="info" />
                                <el-table-column label="诲人不倦值" prop="points" />
                                <el-table-column label="学习成长值" prop="score" />
                                <el-table-column label="共兑换次数" prop="num" />
                                <el-table-column label="剩余兑换次数" prop="using_num" />
                                <el-table-column label="个人兑换次数" prop="self_num" />
                                <el-table-column label="是否上架">
                                    <template #default="scope">
                                        <span v-if="scope.row.status == 1">上架中</span>
                                        <span v-else>已下架</span>
                                        
                                        
                                    </template>
                                </el-table-column>

                                <el-table-column align="right">
                                <template #header>
                                    <el-button type="success"  @click="addShow=true">添加商品</el-button>
                                </template>
                                <template #default="scope">
                                    <el-button size="small" type="primary"  @click="upShowTrue(scope.row)">修改</el-button>
                                    <el-button size="small" type="danger" @click="delShowTrue(scope.row)">下架</el-button>
                                </template>
                                </el-table-column>
                        </el-table>
                    
                    </el-col>
                </el-row>

            </el-tab-pane>
            <el-tab-pane name="2" label="兑换记录">

                <el-table :data="shoplist" height="80vh" style="width: 100%">
                                <el-table-column label="用户" prop="username" />
                                <el-table-column label="商品名称" prop="name" />
                                <el-table-column label="图片"  width="200" >
                                    <template #default="scope">
                                        <el-image
                                        flt="cover"
                                        :src="'http://101.200.139.27:8000'+scope.row.url"
                                        class="image"
                                        style="width: 120px; height: 120px"
                                        />
                                        
                                    </template>
                                </el-table-column>

                                <el-table-column label="兑换时间" prop="create_time" />
                                <el-table-column label="花需积分" prop="score" />
                                <el-table-column label="积分类型" prop="event" />



                                <!-- <el-table-column align="right">
                                <template #header>
                                    <el-button  @click="handleEdit(scope.$index, scope.row)"
                                    >添加商品</el-button
                                    >
                                </template>
                                <template #default="scope">
                                    <el-button  @click="handleEdit(scope.$index, scope.row)"
                                    >修改</el-button
                                    >
                                    <el-button
                                    size="small"
                                    type="danger"
                                    @click="handleDelete(scope.$index, scope.row)"
                                    >删除</el-button
                                    >
                                </template>
                                </el-table-column> -->
                        </el-table>



            </el-tab-pane>
            <el-tab-pane name="3" label="评论记录">
                <div class="comment-age">
                    <div class="comment" v-for="item in shoplist">
                        <div class="title">
                            <el-tag effect="dark">{{ item.username }}</el-tag><el-tag type="primary" effect="plain">{{ item.create_time }}</el-tag>
                        </div>
                        <div class="content">
                            {{ item.content }}
                        </div>
                        <div v-if="item.child" class="child">
                            <div v-for="i in item.child">
                                <el-tag effect="dark" type="success">{{ i.username }}</el-tag><el-tag type="success" effect="plain">{{ i.create_time }}</el-tag>
                                <div class="content">
                                    {{ i.content }}
                                </div>
                            </div>
                        </div>
                        <div v-else style="margin-top: 10px;">
                            <el-button type="success" style="width: 100%;" @click="IsRemarkShow(item)">回复</el-button>
                        </div>
                    </div>
                </div>
            </el-tab-pane>
            
            <el-dialog
            v-model="addShow"
            title="添加商品"
            width="50%"
            append-to-body
            destroy-on-close
          >
            <span>

                <el-form v-model="shopForm">
                    <el-form-item label="商品名称">
                        <el-input v-model="shopForm.name"  :rows="4" placeholder="请输入商品名称" />
                    </el-form-item>



                    <el-form-item label="商品类型">
                        <el-select
                            v-model="shopForm.info"
                            filterable
                            allow-create
                            default-first-option
                            :reserve-keyword="false"
                            placeholder="请选择或输入商品类型"
                            
                        >
                            <el-option
                            v-for="item in eventList"
                            :key="item.info"
                            :label="item.info"
                            :value="item.info"
                            />
                        </el-select>
                        
                    </el-form-item>

                    <el-form-item label="诲人不倦值">
                        <el-input v-model="shopForm.points" type="number"  placeholder="请输入需兑换诲人不倦值" />
                    </el-form-item>
                    
                    <el-form-item label="学习成长值">
                        <el-input v-model="shopForm.score"  type="number" :rows="4" placeholder="请输入需兑换学习成长值" />
                    </el-form-item>

                    <el-form-item label="共计兑换次数">
                        <el-input v-model="shopForm.all_num" type="number" :rows="4" placeholder="请输入共兑换次数" />
                    </el-form-item>

                    <el-form-item label="个人兑换次数">
                        <el-input v-model="shopForm.self_num" type="number" :rows="4" placeholder="请输入个人兑换次数" />
                    </el-form-item>

                    <el-form-item label="商品链接">
                        <el-input v-model="shopForm.url_address"  :rows="4" placeholder="请输入复制商品链接（无则建议输入官网：http://www.crwnt.com/）" />
                    </el-form-item>
                    
                </el-form>

                
                

                <el-upload 
                    ref="upload"
                    class="upload-demo"
                    :on-success="(res,file)=>{successfile(res,file)}"
                    name="file"
                    :limit="1"
                    :on-exceed="handleExceed"
                    :on-remove="beforeRemove"
                    :before-upload="beforeAvatarUpload"
                    :http-request="nFile"
                    :headers="myHeaders"
                    :file-list="newlist"
                    multiple
                >
                    <el-button  type="primary">点击上传文件</el-button>
                    
                    <template #tip>
                    
                    </template>
                </el-upload>

            </span>
            <template #footer>
              <span class="dialog-footer">
                <el-button @click="addShop()">确认</el-button>
                <el-button type="primary" @click="addShow = false"
                  >取消</el-button
                >
              </span>
            </template>
          </el-dialog>


          <el-dialog
            v-model="upShow"
            title="修改商品"
            width="50%"
            append-to-body
            destroy-on-close
          >
            <span>
                
                <el-form v-model="upForm">
                    <el-form-item label="商品名称">
                        <el-input v-model="upForm.name"  :rows="4" placeholder="请输入商品名称" />
                    </el-form-item>



                    <el-form-item label="商品类型">
                        <el-select
                            v-model="upForm.info"
                            filterable
                            allow-create
                            default-first-option
                            :reserve-keyword="false"
                            placeholder="请选择或输入商品类型"
                        >
                            <el-option
                            v-for="item in eventList"
                            :key="item.info"
                            :label="item.info"
                            :value="item.info"
                            />
                        </el-select>
                        
                    </el-form-item>

                    <el-form-item label="诲人不倦值">
                        <el-input v-model="upForm.points" type="number"  placeholder="请输入需兑换诲人不倦值" />
                    </el-form-item>
                    
                    <el-form-item label="学习成长值">
                        <el-input v-model="upForm.score"  type="number" :rows="4" placeholder="请输入需兑换学习成长值" />
                    </el-form-item>

                    <el-form-item label="共计兑换次数">
                        <el-input v-model="upForm.all_num" type="number" :rows="4" placeholder="请输入共兑换次数" />
                    </el-form-item>

                    <el-form-item label="剩余兑换次数">
                        <el-input v-model="upForm.using_num" type="number" :rows="4" placeholder="请输入剩余兑换次数" />
                    </el-form-item>

                    <el-form-item label="个人兑换次数">
                        <el-input v-model="upForm.self_num" type="number" :rows="4" placeholder="请输入个人兑换次数" />
                    </el-form-item>

                    <el-form-item label="商品链接">
                        <el-input v-model="upForm.url_address"  :rows="4" placeholder="请输入复制商品链接（无则建议输入官网：http://www.crwnt.com/）" />
                    </el-form-item>
                    
                </el-form>

                
                <br/><br/>
                <el-upload
                ref="uploadup"
                class="upload-demo"
                :on-success="(res,file)=>{successfile(res,file)}"
                name="file"
                :limit="1"
                :on-exceed="handleExceed"
                :on-remove="beforeRemove"
                :before-upload="beforeAvatarUpload"
                :http-request="nFile"
                :headers="myHeaders"
                :file-list="upnewlist"
                multiple
                >
                    <el-button  type="primary">点击上传文件</el-button>
                    
                    <template #tip>
                    
                    </template>
                </el-upload>

            </span>
            <template #footer>
              <span class="dialog-footer">
                <el-button @click="goUpShop()">确认</el-button>
                <el-button type="primary" @click="upShow = false"
                  >取消</el-button
                >
              </span>
            </template>
          </el-dialog>


          <el-dialog
            v-model="delShow"
            title="删除商品"
            width="50%"
            append-to-body
            destroy-on-close
          >
            <span>
                
                是否确认删除该商品。

            </span>
            <template #footer>
              <span class="dialog-footer">
                <el-button @click="delShop()">确认</el-button>
                <el-button type="primary" @click="delShow = false"
                  >取消</el-button
                >
              </span>
            </template>
          </el-dialog>

          
        </el-tabs>

        

        <!-- 回复弹窗 -->
        <el-dialog  v-model="RemarkShow" title="回复" width="50%">
            <div>
                <el-input type="textarea" :rows="6" v-model="RemarkForm.text" placeholder="请输入回复内容，回复后会以机器人的身份进行钉钉通知" />
            </div>
            
            <template #footer>
              <span class="dialog-footer">
                <el-button @click="PostShopRemark()">确认</el-button>
                <el-button type="primary" @click="RemarkShow = false"
                  >取消</el-button
                >
              </span>
            </template>
          </el-dialog>


    </div>

</div>	
</template>



<script>
	
export default{
    
	// 注册组件
	components:{

	},
	// 数据变量
	data() {
		return{

			shoplist:"",
            upShow:false,
            addShow:false,
            delShow:false,

            name:"",
            info:"",
            points:"",
            score:"",
            url:"",
            newlist:[],

            upname:"",
            upinfo:"",
            uppoints:"",
            upscore:"",
            upurl:"",
            upnewlist:[],
            uppid:"",
            

            event:"1",

            delid:"",

            infoList:"",

            menu:"1",

            shopForm:{},
            eventList:[],

            upForm:{},

            RemarkShow:false,
            RemarkForm:{},



		}

	},
	// 方法
	methods:{

        // 回复弹窗
        IsRemarkShow(item){
            this.RemarkForm.fid = item.id
            this.RemarkForm.send_name = item.username
            this.RemarkShow = true
        },

        // 回复评价
        PostShopRemark(){
            if(this.RemarkForm.text == "" || this.RemarkForm.text == undefined){
                this.$message({
                    showClose: true,
                    message:'请输入回复内容',
                    type: 'error',
                })
                return 
            }

            this.myaxios("/ShopCommint/","put",{
                fid:this.RemarkForm.fid,
                send_name:this.RemarkForm.send_name,
                uid:localStorage.getItem("uid"),
                text:this.RemarkForm.text,
            }).then(data=>{
                if(data.code == 200){
                    this.$message({
                        showClose: true,
                        message:'已成功进行回复',
                        type: 'success',
                    })
                    this.getShop(3)
                    this.RemarkForm = {}
                    this.RemarkShow = false
                }else{
                    this.$message({
                        showClose: true,
                        message:data.msg,
                        type: 'error',
                    })
                }
            })
        },


        // 获取商品筛选项
        getShopOptions(){
            this.myaxios("/ShopCommint/","delete")
            .then(data=>{
                this.eventList = data.data
            })
        },

        changemenu(){

            // alert(this.menu)

            if(this.menu == "1"){
                this.getShop(2)
            }
            if(this.menu == "2"){
                this.getShop(1)
            }
            if(this.menu == "3"){
                this.getShop(3)
            }

        },

        

        delShowTrue:function(info){

            this.delShow = true
            this.delid = info.id

        },

        delShop:function(){
            
            this.myaxios("/shopview/","delete",{"pid":this.delid})
            .then(data=>{
                if(data.code == 200){

                    this.delShow = false
                    this.$message({
                        showClose: true,
                        message:'下架成功',
                        type: 'success',
                    })
                    this.getShop(2)
                    

                }
            })

        },


        upShowTrue:function(info){

            this.upForm = info
            this.upForm.all_num = info.num
            // this.upnewlist.push(info.url)
            this.upShow = true
            this.event = "2"


        },

        goUpShop:function(){

            if(this.upForm.name == "" || this.upForm.name == undefined){
                this.$message({
                    showClose: true,
                    message:'商品名称为必填项',
                    type: 'error',
                })
                return 
            }

            if(this.upForm.info == "" || this.upForm.info == undefined){
                this.$message({
                    showClose: true,
                    message:'商品类型为必填项',
                    type: 'error',
                })
                return 
            }

            if(this.upForm.points == "" || this.upForm.points == undefined){
                this.$message({
                    showClose: true,
                    message:'诲人不倦为必填项',
                    type: 'error',
                })
                return 
            }

            if(this.upForm.score == "" || this.upForm.score == undefined){
                this.$message({
                    showClose: true,
                    message:'学习成长为必填项',
                    type: 'error',
                })
                return 
            }

            if(this.upForm.all_num == "" || this.upForm.all_num == undefined){
                this.$message({
                    showClose: true,
                    message:'共兑换次数为必填项',
                    type: 'error',
                })
                return 
            }

            if(this.upForm.using_num == "" || this.upForm.using_num == undefined){
                this.$message({
                    showClose: true,
                    message:'剩余兑换次数为必填项',
                    type: 'error',
                })
                return 
            }

            if(this.upForm.self_num == "" || this.upForm.self_num == undefined){
                this.$message({
                    showClose: true,
                    message:'个人兑换次数为必填项',
                    type: 'error',
                })
                return 
            }

            if(this.upForm.url_address == "" || this.upForm.url_address == undefined){
                this.$message({
                    showClose: true,
                    message:'商品链接为必填项',
                    type: 'error',
                })
                return 
            }

            if(this.upForm.url == "" || this.upForm.url == undefined){
                this.$message({
                    showClose: true,
                    message:'请上传实物图片',
                    type: 'error',
                })
                return 
            }

            this.myaxios("/shopview/","put",
                {
                    "pid":this.upForm.id,
                    "name":this.upForm.name,
                    "info":this.upForm.info,
                    "points":this.upForm.points,
                    "score":this.upForm.score,
                    "all_num":this.upForm.all_num,
                    "self_num":this.upForm.self_num,
                    "using_num":this.upForm.using_num,
                    "url_address":this.upForm.url_address,
                    "url":this.upForm.url,
                }
            )
            .then(data=>{
                if(data.code == 200){
                    this.upForm = {}
                    this.upShow = false
                    this.event = "1"
                    this.getShop(2)
                    this.$refs.uploadup.clearFiles()
                    this.upnewlist = []
                    this.$message({
                        showClose: true,
                        message:'修改成长',
                        type: 'success',
                    })
                }      
            })

        },



        // 添加商品
        addShop:function(){

            console.log(this.shopForm)
            if(this.shopForm.name == "" || this.shopForm.name == undefined){
                this.$message({
                    showClose: true,
                    message:'商品名称为必填项',
                    type: 'error',
                })
                return 
            }

            if(this.shopForm.info == "" || this.shopForm.info == undefined){
                this.$message({
                    showClose: true,
                    message:'商品类型为必填项',
                    type: 'error',
                })
                return 
            }

            if(this.shopForm.points == "" || this.shopForm.points == undefined){
                this.$message({
                    showClose: true,
                    message:'诲人不倦为必填项',
                    type: 'error',
                })
                return 
            }

            if(this.shopForm.score == "" || this.shopForm.score == undefined){
                this.$message({
                    showClose: true,
                    message:'学习成长为必填项',
                    type: 'error',
                })
                return 
            }

            if(this.shopForm.all_num == "" || this.shopForm.all_num == undefined){
                this.$message({
                    showClose: true,
                    message:'共兑换次数为必填项',
                    type: 'error',
                })
                return 
            }

            if(this.shopForm.self_num == "" || this.shopForm.self_num == undefined){
                this.$message({
                    showClose: true,
                    message:'个人兑换次数为必填项',
                    type: 'error',
                })
                return 
            }

            if(this.shopForm.url_address == "" || this.shopForm.url_address == undefined){
                this.$message({
                    showClose: true,
                    message:'商品链接为必填项',
                    type: 'error',
                })
                return 
            }

            if(this.shopForm.url == "" || this.shopForm.url == undefined){
                this.$message({
                    showClose: true,
                    message:'请上传实物图片',
                    type: 'error',
                })
                return 
            }
            
            this.myaxios("/shopview/","post",
                {
                    "name":this.shopForm.name,
                    "info":this.shopForm.info,
                    "points":this.shopForm.points,
                    "score":this.shopForm.score,
                    "all_num":this.shopForm.all_num,
                    "self_num":this.shopForm.self_num,
                    "url_address":this.shopForm.url_address,
                    "url":this.shopForm.url,
                }
            )
            .then(data=>{
                if(data.code == 200){
                    this.shopForm = {}
                    this.addShow = false
                    this.getShop(2)
                    this.$refs.upload.clearFiles()
                    this.newlist = []

                }      
            })

        },

        // 上传图片
        nFile(file){

            this.text = ""
            this.text = "录入中"
                        
            let data = new FormData()
            console.log(file.file)
            data.append('file',file.file)
            data.append('event',"1")
            data.append("oldfile",this.video_name)
            data.append("uid",localStorage.getItem("uid"))


            console.log(file.file)
            console.log(data)

            const axiosinstance = this.axios.create({withCredentials:false})

            axiosinstance({

                method:'post',
                url:'http://101.200.139.27:8000/shopmag/',
                // url:'http://127.0.0.1:8000/shopmag/',
                data:data

            }).then(data=>{
                            

                if(data.data.code === 403){
                                // this.flag = 1
                    this.$toast.fail(data.data.msg)

                }else{
                    this.text = "录入成功"
                    if(this.event == "1" || this.event == 1){
                        this.shopForm.url = data.data.url
                    }else if(this.event == "2" || this.event == 2){
                        this.upForm.url = data.data.url
                    }
                    
                    // alert(this.url)
                    this.$message({
                        showClose: true,
                        message:'上传成功',
                        type: 'success',
                        })

                    // this.$refs.upload.clearFiles()
                    // this.newlist = []
                    this.text = ""
                    // alert("完成")
                                // this.reload()
                }

            })
                    
                
        },




		test:function(){
			// axios请求     请求地址                 请求方式
			this.myaxios("http://127.0.0.1:8000/test/","get")
			.then(data=>{
				// 打印请求后返回值
				console.log(data)

			})
			
		},
        getShop:function(event){

            this.myaxios("/shopview/","get",{"event":event})
            .then(data=>{
                this.shoplist = data.data
                this.getShopOptions()
            })


        },
	},
	// 生命周期钩子函数
	// 用来在一个实例被创建之后执行
	created(){

	},
	//模板中的html渲染到了html页面中，一般用作于axios请求
	mounted(){
		this.getShop(2)
        // this.getShopOptions()
	},

}

</script>

<style scoped>

@import url("../assets/style.css");

.image {
  width: 100%;
  height: 100%;
  display: block;
}

.comment-age{
    height: 80vh;
    overflow-y: auto;
    overflow-x: hidden;
    /* text-align: center; */
    /* overflow-x: auto; */
    display: flex;
    justify-content: center; /* 水平居中 */
    flex-wrap: wrap;
    /* align-items: center;  */
    margin-top: 10px;
}


.comment{
    background-color: white;
    width: 95%;
    height: auto;
    margin: 10px;
    padding: 10px;
    background-color: #fff; /* 设置背景色 */
    border-radius: 15px; /* 设置圆角半径 */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); /* 设置阴影 */
    
}

.child{
    padding: 10px;
    margin-left: 20px;
    margin-top: 10px;
    border-left : 1px solid rgba(0, 0, 0, 0.3);
    border-bottom : 1px solid rgba(0, 0, 0, 0.3);
}

.comment .title{
    font-weight: 400;
    font-size: 18px;
    margin-top: 20px;
}

.comment .content{
    text-indent: 2em;
    margin-top: 2px;
}

.comment-age::-webkit-scrollbar-thumb {
    border-radius: 8px !important;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2) !important;
    background: #333 !important;
}

.comment-age::-webkit-scrollbar {
    width: 4px;
}




</style>