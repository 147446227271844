<template>
<div  class="menutree">
    
    <el-menu-item v-for="item in supmenu" :index="item.id" @click="getinfo(item.id)" > 
			  			{{ item.name }}
	</el-menu-item>
    
</div>
</template>


<script>

import general from "./general.vue"
	
export default{

	name:"general",
	props:["data"],

	components:{
		"general" : general,
	},

	data() {
		return{

			
			supmenu:""

		}

	},


	// 父子组件传参
	setup(props,{emit}){

		function getinfo(val){

			emit("val",val)
			

		}
		return{

			getinfo

		}

	},



	methods:{

		go_out(){
			// 删除浏览器存储的信息
			localStorage.removeItem("username")
			localStorage.removeItem("uid")
			// location.reload()
			this.$router.push({path:"/login"})

		},

		
		// 请求子集菜单
		test:function(topid){
			// axios请求     请求地址                 请求方式
			this.myaxios("/supermenu/","post",{"uid":1,"topid":localStorage.getItem("menus")})
			.then(data=>{
				// 打印请求后返回值
				this.supmenu = data.data

			})
			
		},

		

		// 积分获取
		get_score:function(){
			this.myaxios("/integral/","get",{"uid":localStorage.getItem("uid")})
			.then(data=>{
				console.log(data.data)

				this.score = data.data.score
				this.points = data.data.points
			})
		},
		handleNodeClick:function(data,node){

			// console.log(node)
			console.log(data)
		},
		add:function(e){

			console.log(e)

		},

		// getinfo(id){

		// 	console.log(id)
		// 	this.resetSetItem('nid',id)
		// 	// localStorage.setItem("nid",id)
		// 	// alert(id)

		// },
		
	},
	created(){

		this.test()

	},
	mounted(){
		
	},

}

</script>

<style scoped>

@import url("../assets/style.css");


/*.font {

	font-size: 20px;
	color: black;

}*/

.el-sub-menu is-opened{

	/*margin-bottom: 50px;*/
	/*margin-left: 50px;*/
	/*cursor: pointer;*/
	height: auto;

}

.go{

	margin-bottom: 50px;
	margin-left: 50px;

}

/*.tac{
	width: 100%;
}*/



</style>