<template>
<div>
	<van-image class="add" style="width: 100px; height: 30px;" src="http://116.62.145.126:8000/static/logo.png"/>


</div>	
</template>


<script>
	
export default{
	// 注册组件
	components:{

	},
	// 数据变量
	data() {
		return{

			list_1 : [1,2,3,4,5,6,7,8,9,10],
			checked1:0,
			checked2:0,
			iptList:[
			   {label:'位1',key:''},
			   {label:'位2',key:''},
			   {label:'位3',key:''},
			   {label:'位4',key:''}
			]

		}

	},
	// 方法
	methods:{

		test:function(){
			// axios请求     请求地址                 请求方式
			this.myaxios("http://127.0.0.1:8000/test/","get")
			.then(data=>{
				// 打印请求后返回值
				console.log(data)

			})
			
		}
	},
	// 生命周期钩子函数
	// 用来在一个实例被创建之后执行
	created(){

	},
	//模板中的html渲染到了html页面中，一般用作于axios请求
	mounted(){
		this.test()
	},

}

</script>

<style scoped>

@import url("../assets/style.css");




</style>