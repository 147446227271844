<template>
<div>

	<el-radio-group @change="chflag" v-model="flag">
        <el-radio-button :label="1">用户权限</el-radio-button>
        <el-radio-button :label="2">部门权限</el-radio-button>
    </el-radio-group>

    <div v-if="flag == 2">
    	<el-table height="800" :data="depts">
    		<el-table-column prop="name" label="部门名称" />
            

            <el-table-column label="操作">

            	<template #header>
	                <el-button @click="addShow = true" type="success">添加部门</el-button>
	            </template>

                <template #default="scope">

                	<el-button @click="dept_Show(scope.row)">分配权限</el-button>
                	
                    <!-- {{scope.row}} -->

                </template>
            </el-table-column>
    	</el-table>
    </div>

	<div v-if="flag == 1">
     <el-table height="800" :data="data" >
            <el-table-column prop="username" label="用户名"  />

 			<el-table-column prop="name" label="所属公司" />


            <el-table-column prop="postion" label="所属岗位岗位" />
            <el-table-column label="状态">

            	<template #default="scope">

                	{{changes(scope.row.state)}}

                </template>


            </el-table-column>
            

            <el-table-column label="操作">

            	<template #header>
	                <el-input  @change="putUser" v-model="search" size="small" placeholder="请输入关键字" />
	            </template>

                <template #default="scope">

                	<el-button @click="Is_show(scope.row)">分配权限</el-button>
                	<el-button @click="study_Show(scope.row)">分配视频权限</el-button>
                    <!-- {{scope.row}} -->

                </template>
            </el-table-column>


        </el-table>
    </div>


        <el-dialog
		    v-model="show"
		    title="Tips"
		    width="60%"
		    destroy-on-close
		    :before-close="handleClose"
		  >
		    <div style="text-align: center">
		    	<p>给用户配置部门权限</p>
		    	<el-transfer
			      v-model="deptValue"
			      style="text-align: left; display: inline-block"
			      filterable
			      filter-placeholder="请输入部门名称"
			      :left-default-checked="[2, 3]"
			      :right-default-checked="[1]"
			      :render-content="renderFunc"
			      :titles="['未授权', '已授权']"
			      :button-texts="['减权', '授权']"
			      :format="{
			        noChecked: '${total}',
			        hasChecked: '${checked}/${total}',
			      }"
			      :props="{key:'id',label:'name'}"
			      :data="dept"
			      @change="handleChange"
			    >
			      <template #left-footer>
			        <!-- <el-button class="transfer-footer" size="small">Operation</el-button> -->
			      </template>
			      <template #right-footer>
			        <!-- <el-button class="transfer-footer" size="small">Operation</el-button> -->
			      </template>
			    </el-transfer>


		    </div>
		    <template #footer>
		      <span class="dialog-footer">
		        <el-button @click="show = false">取消</el-button>
		        <el-button type="primary" @click="deptUpdate"
		          >保存</el-button
		        >
		      </span>
		    </template>
		  </el-dialog>

		<el-dialog
		    v-model="studyShow"
		    title="Tips"
		    width="60%"
		    destroy-on-close
		  >
		    <div style="text-align: center">
		    	<p>给用户配置视频观看权限</p>
		    	<el-transfer
			      v-model="studyValue"
			      style="text-align: left; display: inline-block"
			      filterable
			      filter-placeholder="请输入配置菜单名称"
			      :render-content="renderFunc"
			      :titles="['未授权', '已授权']"
			      :button-texts="['减权', '授权']"
			      :format="{
			        noChecked: '${total}',
			        hasChecked: '${checked}/${total}',
			      }"
			      :props="{key:'id',label:'name'}"
			      :data="studyList"
			      @change="handleChange"
			    >
			      <template #left-footer>
			        <!-- <el-button class="transfer-footer" size="small">Operation</el-button> -->
			      </template>
			      <template #right-footer>
			        <!-- <el-button class="transfer-footer" size="small">Operation</el-button> -->
			      </template>
			    </el-transfer>


		    </div>
		    <template #footer>
		      <span class="dialog-footer">
		        <el-button @click="studyShow = false">取消</el-button>
		        <el-button type="primary" @click="studyUpdate"
		          >保存</el-button
		        >
		      </span>
		    </template>
		  </el-dialog>


		  <el-dialog
		    v-model="deptShow"
		    title="Tips"
		    width="60%"
		    destroy-on-close
		  >
		    <div style="text-align: center">
		    	<p>给用户配置视频观看权限</p>
		    	<el-transfer
			      v-model="deptOld"
			      style="text-align: left; display: inline-block"
			      filterable
			      filter-placeholder="请输入配置菜单名称"
			      :render-content="renderFunc"
			      :titles="['未授权', '已授权']"
			      :button-texts="['减权', '授权']"
			      :format="{
			        noChecked: '${total}',
			        hasChecked: '${checked}/${total}',
			      }"
			      :props="{key:'id',label:'name'}"
			      :data="deptList"
			      @change="handleChange"
			    >
			      <template #left-footer>
			        <!-- <el-button class="transfer-footer" size="small">Operation</el-button> -->
			      </template>
			      <template #right-footer>
			        <!-- <el-button class="transfer-footer" size="small">Operation</el-button> -->
			      </template>
			    </el-transfer>


		    </div>
		    <template #footer>
		      <span class="dialog-footer">
		        <el-button @click="deptShow = false">取消</el-button>
		        <el-button type="primary" @click="deptUpadd"
		          >保存</el-button
		        >
		      </span>
		    </template>
		  </el-dialog>

		  <el-dialog
		  	v-model="addShow"
		    title="添加"
		    width="30%"
		    destroy-on-close
		  >

		  	<span>
		  		<el-input v-model="name" placeholder="请输入添加名称" />
		  	</span>
		  	
		  	<template #footer>
		      <span class="dialog-footer">
		        <el-button @click="addShow = false">取消</el-button>
		        <el-button type="primary" @click="addDept"
		          >确认</el-button
		        >
		      </span>
		    </template>

		  </el-dialog>



</div>	
</template>


<script>
	
export default{
	// 注册组件
	components:{

	},
	// 数据变量
	data() {
		return{
			// 部门权限
			data:"",
			show:false,
			dept:[],
			deptValue:[],
			postuid:"",
			// 视频权限
			studyShow:false,
			studyList:[],
			studyValue:[],

			search:"",
			flag:1,
			// 部门操作
			depts:"",
			deptList:[],
			deptOld:[],
			deptShow:false,
			deptId:"",
			addShow:false,

			name:"",



		}

	},




	// 方法
	methods:{

		changes(value){

			if(value == 0){
				return "已离职"
			}
			if(value == 1){
				return "在职"
			}

		},

		chflag(){

			if(this.flag == 1){
				this.getUser()
			}else if(this.flag == 2){
				this.getDeptlist()
			}

		},

		handleChange  (
		  value,
		  direction,
		  movedKeys
		){
		  console.log(value, direction, movedKeys)
		  console.log("******")
		  console.log(this.deptValue)
		},

		// 用户检索
		putUser(){

			this.myaxios("/authorization/","put",{"search":this.search})
			.then(data=>{
				this.data = data.data
			})
		},

		// 提交部门修改
		deptUpdate:function(){

			this.myaxios("/authorization/","post",{"uid":this.postuid,"lists":JSON.stringify(this.deptValue)})
			.then(data=>{
				this.show = false
				this.$toast.success("成功")
			})

		},

		// 提交视频修改
		studyUpdate:function(){

			this.myaxios("/videorbac/","post",{"uid":this.postuid,"lists":JSON.stringify(this.studyValue)})
			.then(data=>{
				this.studyShow = false
				this.$toast.success("成功")
			})

		},

		// 提交部门修改
		deptUpadd:function(){

			this.myaxios("/deptupdate/","put",{"did":this.deptId,"lists":JSON.stringify(this.deptOld)})
			.then(data=>{
				this.deptShow = false
				this.$toast.success("成功")
			})

		},




		
		study_Show(info){

			this.postuid = info.id
			this.getStudy(info.id)
			this.studyShow = true


		},

		dept_Show(info){

			this.getDeptUpload(info.id)

			this.deptShow = true

			this.deptId = info.id

		},

		Is_show(info){
			// alert(info.id)
			this.getDept(info.id)
			this.show = true
			this.postuid = info.id

		},

		

		// 获取视频权限
		getStudy:function(uid){
			this.myaxios("/videorbac/","get",{"uid":uid})
			.then(data=>{
				this.studyList = data.new
				this.studyValue = data.old
			})
		},

		// 获取部门权限
		getDeptUpload:function(uid){
			this.myaxios("/deptupdate/","delete",{"did":uid})
			.then(data=>{
				this.deptList = data.new
				this.deptOld = data.old
			})
		},

		// 获取用户拥有权限
		getDept:function(uid){

			this.myaxios("/authorization/","delete",{"uid":uid})
			.then(data=>{
				// 打印请求后返回值
				this.dept = data.new
				this.deptValue = data.old

				console.log(this.leftValue)
				console.log(this.dept)

			})
		},

		// 获取用户
		getUser:function(){
			// axios请求     请求地址                 请求方式
			this.myaxios("/authorization/","get")
			.then(data=>{
				// 打印请求后返回值
				this.data = data.data

			})
			
		},

		// 获取部门
		getDeptlist(){
			this.myaxios("/deptupdate/","get")
			.then(data=>{
				// 打印请求后返回值
				this.depts = data.data

			})
		},

		addDept(){

			this.myaxios("/deptupdate/","post",{"name":this.name})
			.then(data=>{

				this.addShow = false

				this.$toast.success("成功")
				this.flag = 2
				this.getDeptlist()

			})

		}

	},
	// 生命周期钩子函数
	// 用来在一个实例被创建之后执行
	created(){

	},
	//模板中的html渲染到了html页面中，一般用作于axios请求
	mounted(){

		this.getUser()
		
	},

}

</script>

<style scoped>

@import url("../assets/style.css");




</style>