<template>
  <div id="app">
    <el-config-provider :locale="locale">
      <router-view v-if="isRouterAlive"></router-view>
    </el-config-provider>
  </div>
</template>

<script>

import { removeWatermark,setWaterMark } from './components/filters/watermark'
import { ElConfigProvider } from "element-plus";
import zhCn from "element-plus/lib/locale/lang/zh-cn";

export default{

  components: {
    [ElConfigProvider.name]: ElConfigProvider
  },

  setup() {
    let locale = zhCn;
    return {
      locale,
    };
  },
  
  name:'app',
  provide(){
    return{
      reload:this.reload
    }
    
  },
  data(){
    return{
      isRouterAlive:true,
      
    }
  },
  methods:{




    reload(){
      this.isRouterAlive = false
      this.$nextTick(function(){
        this.isRouterAlive = true
      })
    },

    





  },


  unmounted() {//vue可换为destroyed()生命周期，不过这个也可以用
   
  },
  mounted() {
    // let times = this.$tools.timeFormat(new Date)
    let username = localStorage.getItem("username")
    setWaterMark(username,"创想智控")
  },




}  


</script>