<template>
    <div class="container">
        <div style="width:100%;height: 100%;">

            <div v-if="imagesShows">
                <el-image
                        style="width: 100%; aspect-ratio: 9 / 4;"
                        src="http://crnt.oss-cn-qingdao.aliyuncs.com/%E5%86%85%E6%8E%A8%E6%B5%B7%E6%8A%A5.png"
                        :zoom-rate="1.2"
                        :max-scale="7"
                        :min-scale="0.2"
                        :initial-index="4"
                        fit="cover"
                    />
            </div>

            <div class="grid-container" id="scrolling-container">
                <div v-for="i in NewPriceList">
                    <div class="grid-item">
                        <!-- {{ i }} -->
                        
                        <div>
                            <el-image
                                style="width: 150px; height: 150px"
                                :src="'http://101.200.139.27:8000'+i.url"
                                :zoom-rate="1.2"
                                :max-scale="7"
                                :min-scale="0.2"
                                :initial-index="4"
                                fit="cover"
                            />
                            <br/>
                            <div class="grid-font">
                                {{ i.username }}({{ i.create_time }})<br/>
                            {{ i.name }}
                            </div>
                            
                        </div>
                        <!-- <div>
                            {{ i.name }}
                        </div> -->
                    </div>
                </div>
            </div>


            <div class="lst">

                <div>
                    <div style="color:#606266;text-align: center;padding-bottom: 10px;">七天实时学习成长榜</div>
                    <div class="item">
                        <span>排名</span>
                        <span class="w60">人员</span>
                        <span class="w60">积分</span>
                    </div>
                    <div class="scrollBar">
                        <div v-for="(item, idx) in elist" class="item" :class="[idx + 1 > 3 ? '' : 'bg' + (idx + 1), '']">
                            <span :class="[idx + 1 > 3 ? 'ml10' : 'index_' + (idx + 1)]">{{ idx + 1 > 3 ? idx + 1 : '' }}</span>
                            <span class="w60">{{ item.name }}</span>
                            <span class="w60">{{ item.score }}</span>
                        </div>
                    </div>  
                </div>

                <div>
                    <div style="color:#606266;text-align: center;padding-bottom: 10px;">七天实时诲人不倦榜</div>
                        <div class="item">
                            <span>排名</span>
                            <span>人员</span>
                            <span>积分</span>
                        </div>
                        <div class="scrollBar">
                            <div v-for="(item, idx) in pdata" class="item" :class="[idx + 1 > 3 ? '' : 'bg' + (idx + 1), '']">
                                <span :class="[idx + 1 > 3 ? 'ml10' : 'index_' + (idx + 1)]">{{ idx + 1 > 3 ? idx + 1 : ''
                                }}</span>
                                <span>{{ item.username }}</span>
                                <span>{{ item.score }}</span>
                            </div>
                        </div>
                </div>

                <div>
                    <div style="color:#606266;text-align: center;padding-bottom: 10px;">2024学习成长总榜</div>
                        <div class="item">
                            <span>排名</span>
                            <span>人员</span>
                            <span>积分</span>
                        </div>
                        <div class="scrollBar">
                            <div v-for="(item, idx) in NewScoreRankList" class="item" :class="[idx + 1 > 3 ? '' : 'bg' + (idx + 1), '']">
                                <span :class="[idx + 1 > 3 ? 'ml10' : 'index_' + (idx + 1)]">{{ idx + 1 > 3 ? idx + 1 : ''
                                }}</span>
                                <span>{{ item.username }}</span>
                                <span>{{ item.score }}</span>
                            </div>
                        </div>
                </div>

                <div>
                    <div style="color:#606266;text-align: center;padding-bottom: 10px;">2024诲人不倦总榜</div>
                        <div class="item">
                            <span>排名</span>
                            <span>人员</span>
                            <span>积分</span>
                        </div>
                        <div class="scrollBar">
                            <div v-for="(item, idx) in NewPointsRankList" class="item" :class="[idx + 1 > 3 ? '' : 'bg' + (idx + 1), '']">
                                <span :class="[idx + 1 > 3 ? 'ml10' : 'index_' + (idx + 1)]">{{ idx + 1 > 3 ? idx + 1 : ''
                                }}</span>
                                <span>{{ item.username }}</span>
                                <span>{{ item.score }}</span>
                            </div>
                        </div>
                </div>
                <div>
                    <div style="min-width:250px;">
                        <div style="color:#606266;padding-bottom: 10px;text-align: center;">公司学习成长榜</div>
                        <div class="item">
                            <span>排名</span>
                            <span class="w60">人员</span>
                            <span class="w60">积分</span>
                            <span class="w60">可用积分</span>
                        </div>
                        <div v-for="(item, idx) in alist" class="item" :class="[idx + 1 > 3 ? '' : 'bg' + (idx + 1), '']">
                            <span :class="[idx + 1 > 3 ? 'ml10' : 'index_' + (idx + 1)]">{{ idx + 1 > 3 ? idx + 1 : ''
                            }}</span>
                            <span class="w60">{{ item.name }}</span>
                            <span class="w60">{{ item.score }}</span>
                            <span class="w60">{{ item.ave }}</span>
                        </div>
                    </div>
                </div>

                <div>
                    <div style="color:#606266;margin-top:10px;text-align: center;">公司诲人不倦榜</div>
                    <div class="item">
                        <span>排名</span>
                        <span class="w60">人员</span>
                        <span class="w60">积分</span>
                        <span class="w60">可用积分</span>
                    </div>
                    <div v-for="(item, idx) in blist" class="item" :class="[idx + 1 > 3 ? '' : 'bg' + (idx + 1), '']">
                        <span :class="[idx + 1 > 3 ? 'ml10' : 'index_' + (idx + 1)]">{{ idx + 1 > 3 ? idx + 1 : '' }}</span>
                        <span class="w60">{{ item.name }}</span>
                        <span class="w60">{{ item.points }}</span>
                        <span class="w60">{{ item.ave }}</span>
                    </div>
                </div>

                <div>
                    <div style="color:#606266;padding-bottom: 10px;text-align: center;">学习成长总榜-共计:{{ sumscore }}</div>
                    <div class="item">
                        <span>排名</span>
                        <span class="w60">人员</span>
                        <span class="w60">积分</span>
                        <span class="w60">可用积分</span>
                    </div>
                    <div class="scrollBar">
                        <div v-for="(item, idx) in slist_score" class="item"
                            :class="[idx + 1 > 3 ? '' : 'bg' + (idx + 1), '']">
                            <span :class="[idx + 1 > 3 ? 'ml10' : 'index_' + (idx + 1)]">{{ idx + 1 > 3 ? idx + 1 : ''
                            }}</span>
                            <span class="w60">{{ item.username }}</span>
                            <span class="w60">{{ item.score }}</span>
                            <span class="w60">{{ item.use_score }}</span>
                        </div>
                    </div>
                </div>

                <div style="">
                    <div style="min-width:250px;">
                        <div style="color:#606266;padding-bottom: 10px;text-align: center;">诲人不倦总榜-共计:{{ sumpoints }}</div>
                        <div class="item">
                            <span>排名</span>
                            <span class="w60">人员</span>
                            <span class="w60">积分</span>
                            <span class="w60">可用积分</span>
                        </div>
                        <div class="scrollBar">
                            <div v-for="(item, idx) in slist_points" class="item"
                                :class="[idx + 1 > 3 ? '' : 'bg' + (idx + 1), '']">
                                <span :class="[idx + 1 > 3 ? 'ml10' : 'index_' + (idx + 1)]">{{ idx + 1 > 3 ? idx + 1 : ''
                                }}</span>
                                <span class="w60">{{ item.username }}</span>
                                <span class="w60">{{ item.points }}</span>
                                <span class="w60">{{ item.use_points }}</span>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <!-- 战利品 -->
            <div>
                
            </div>

            <!-- ******************************************************************************************************************************** -->

            <!-- <div style="display: flex;justify-content: space-between;align-items: flex-start;flex-wrap: wrap;">
                <div >

                    <div style="color:#606266;padding-bottom: 10px;text-align: center;">学习成长总榜-共计:{{ sumscore }}</div>
                    <div class="item">
                        <span>排名</span>
                        <span class="w60">人员</span>
                        <span class="w60">积分</span>
                        <span class="w60">可用积分</span>
                    </div>
                    <div class="scrollBar">
                        <div v-for="(item, idx) in slist_score" class="item"
                            :class="[idx + 1 > 3 ? '' : 'bg' + (idx + 1), '']">
                            <span :class="[idx + 1 > 3 ? 'ml10' : 'index_' + (idx + 1)]">{{ idx + 1 > 3 ? idx + 1 : ''
                            }}</span>
                            <span class="w60">{{ item.username }}</span>
                            <span class="w60">{{ item.score }}</span>
                            <span class="w60">{{ item.use_score }}</span>
                        </div>
                    </div>
                    
                </div>
                <div style="">
                    
                    <div style="min-width:250px;">
                        <div style="color:#606266;padding-bottom: 10px;text-align: center;">诲人不倦总榜-共计:{{ sumpoints }}</div>
                        <div class="item">
                            <span>排名</span>
                            <span class="w60">人员</span>
                            <span class="w60">积分</span>
                            <span class="w60">可用积分</span>
                        </div>
                        <div class="scrollBar">
                            <div v-for="(item, idx) in slist_points" class="item"
                                :class="[idx + 1 > 3 ? '' : 'bg' + (idx + 1), '']">
                                <span :class="[idx + 1 > 3 ? 'ml10' : 'index_' + (idx + 1)]">{{ idx + 1 > 3 ? idx + 1 : ''
                                }}</span>
                                <span class="w60">{{ item.username }}</span>
                                <span class="w60">{{ item.points }}</span>
                                <span class="w60">{{ item.use_points }}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div style="">
                    
                    <div style="min-width:250px;">
                        <div style="color:#606266;padding-bottom: 10px;text-align: center;">学习成长榜</div>
                        <div class="item">
                            <span>排名</span>
                            <span class="w60">人员</span>
                            <span class="w60">积分</span>
                            <span class="w60">可用积分</span>
                        </div>
                        <div v-for="(item, idx) in alist" class="item" :class="[idx + 1 > 3 ? '' : 'bg' + (idx + 1), '']">
                            <span :class="[idx + 1 > 3 ? 'ml10' : 'index_' + (idx + 1)]">{{ idx + 1 > 3 ? idx + 1 : ''
                            }}</span>
                            <span class="w60">{{ item.name }}</span>
                            <span class="w60">{{ item.score }}</span>
                            <span class="w60">{{ item.ave }}</span>
                        </div>
                    </div>


                    
                    <div style="color:#606266;margin-top:10px;text-align: center;">诲人不倦榜</div>
                    <div class="item">
                        <span>排名</span>
                        <span class="w60">人员</span>
                        <span class="w60">积分</span>
                        <span class="w60">可用积分</span>
                    </div>
                    <div v-for="(item, idx) in blist" class="item" :class="[idx + 1 > 3 ? '' : 'bg' + (idx + 1), '']">
                        <span :class="[idx + 1 > 3 ? 'ml10' : 'index_' + (idx + 1)]">{{ idx + 1 > 3 ? idx + 1 : '' }}</span>
                        <span class="w60">{{ item.name }}</span>
                        <span class="w60">{{ item.points }}</span>
                        <span class="w60">{{ item.ave }}</span>
                    </div>

                </div>

                <div>
                    
                    <div style="color:#606266;text-align: center;padding-bottom: 10px;">七天内实时成长积分榜</div>
                    <div class="item">
                        <span>排名</span>
                        <span class="w60">人员</span>
                        <span class="w60">积分</span>
                    </div>
                    <div v-for="(item, idx) in elist" class="item" :class="[idx + 1 > 3 ? '' : 'bg' + (idx + 1), '']">
                        <span :class="[idx + 1 > 3 ? 'ml10' : 'index_' + (idx + 1)]">{{ idx + 1 > 3 ? idx + 1 : '' }}</span>
                        <span class="w60">{{ item.name }}</span>
                        <span class="w60">{{ item.score }}</span>
                    </div>
                    <div style="">
                        
                        <div style="color:#606266;margin-top:10px;text-align: center;">七天内实时诲人不倦榜</div>
                        <div class="item">
                            <span>排名</span>
                            <span>人员</span>
                            <span>积分</span>
                        </div>
                        <div v-for="(item, idx) in pdata" class="item" :class="[idx + 1 > 3 ? '' : 'bg' + (idx + 1), '']">
                            <span :class="[idx + 1 > 3 ? 'ml10' : 'index_' + (idx + 1)]">{{ idx + 1 > 3 ? idx + 1 : ''
                            }}</span>
                            <span>{{ item.username }}</span>
                            <span>{{ item.score }}</span>
                        </div>
                    </div>
                </div>
                <div style="min-width:320px;">
                    
                    <div style="color:#606266;text-align: center;padding-bottom: 10px;">战利品榜</div>
                    <div class="item">
                        <span>人员</span>
                        <span class="w80">获得奖品</span>
                        <span class="w80">兑换积分</span>
                        <span class="w80">类型</span>
                    </div>
                    <div class="scrollBar">
                        <div v-for="(item, idx) in plist" class="item" :class="[idx + 1 > 3 ? '' : 'bg' + (idx + 1), '']">
                            <span>{{ item.name }}</span>
                            <span class="w80" :title="item.good">{{ item.good.length > 5 ? item.good.slice(0, 5) + '...' : item.good
                            }}</span>
                            <span class="w80">{{ item.score }}</span>
                            <span class="w80">{{ change_info(item.event) }}</span>
                        </div>
                    </div>
                </div>
            </div> -->



            <!-- 右侧榜单 -->
            <div>

                <!-- 七天实时诲人不倦榜 -->
                <!-- <div style="float:right; padding-left:20px;">
                    <span style="color:#606266; padding-left:18px;padding-bottom: 10px;">七天内实时诲人不倦榜</span>
                    <el-table style="margin-top: 10px; width:200px;"  :data="pdata"  >
                                
                        <el-table-column width="60"  label="排名">
                            <template #default="scope">
                                    {{scope.row.id}}
                            </template>
                        </el-table-column>
                        <el-table-column prop="username" label="人员" />
                        <el-table-column prop="score" label="积分" />

                        
                       </el-table>
                </div> -->

                <!-- 端午榜单 -->
                <!-- <div style="float:right; padding-left:20px;">

                    <span style="color:#606266; padding-left:18px;padding-bottom: 10px;">端午学习成长实时榜</span>
                    <el-table style="margin-top: 10px; width:200px;"  :data="dragonboatlist_1"  >
                                
                        <el-table-column width="60"  label="排名">
                            <template #default="scope">
                                    {{scope.row.id}}
                            </template>
                        </el-table-column>
                        <el-table-column prop="username" label="人员" />
                        <el-table-column prop="allscore" label="积分" />

                        
                       </el-table>
                </div>

                <div style="float:right; padding-left:20px;">
                    <span style="color:#606266; padding-left:18px;padding-bottom: 10px;">端午诲人不倦实时榜</span>
                    <el-table style="margin-top: 10px; width:200px;"  :data="dragonboatlist_2"  >
                                
                        <el-table-column width="60"  label="排名">
                            <template #default="scope">
                                    {{scope.row.id}}
                            </template>
                        </el-table-column>
                        <el-table-column prop="username" label="人员" />
                        <el-table-column prop="allscore" label="积分" />

                        
                       </el-table>
                </div> -->



            </div>

        </div>

    </div>
    <el-dialog v-model="dialogVisible" title="视频列表" width="50%">
        <div>
            <div class="flex-container">
                <div class="item" v-for="item in swiperDialog" @click="videoCoverClick(item)" :title="item.name">
                    <el-image style="width: 100%; height: 100px;" :src="item.img_url" />
                    <p >{{ item.name.length>10?item.name.slice(0,10)+'...':item.name }}</p>
                </div>
            </div>
        </div>
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="dialogVisible = false">取消</el-button>
            </span>
        </template>
    </el-dialog>
</template>

<script setup>
    import {GetRankList} from "../utils/api"
    import {ref } from "vue";
    
    const NewScoreRankList = ref([])
    const NewPointsRankList = ref([])
    const NewPriceList = ref([])
    
    // 2024学习成长总榜
    GetRankList({"event":3}).then(data=>{
        NewScoreRankList.value = data.data
    })

    // 2024诲人不倦总榜
    GetRankList({"event":4}).then(data=>{
        NewPointsRankList.value = data.data
    })

    // 获取战利品榜单
    GetRankList({"event":5}).then(data=>{
        NewPriceList.value = data.data
    })

    
    document.addEventListener("DOMContentLoaded", function() {
        // 获取滚动容器的引用
        const scrollingContainer = document.getElementById('scrolling-container');

        // 设置滚动速度和方向
        const scrollSpeed = 2; // 可根据需要调整
        const direction = 1; // 向左滚动

        function autoScroll() {
            // 输出当前滚动位置
            // console.log("Current ScrollLeft:", scrollingContainer.scrollLeft,scrollingContainer.scrollWidth);

            // 计算新的滚动位置
            const newScrollLeft = scrollingContainer.scrollLeft + direction * scrollSpeed;

            // 检查是否超出边界，超出时重新开始
            // if (newScrollLeft <= 0 || newScrollLeft >= scrollingContainer.scrollWidth) {
            //     scrollingContainer.scrollLeft = 0;
            // } else {
            //     scrollingContainer.scrollLeft = newScrollLeft;
            // }

            // 重置滚动位置，模拟到达最右侧
            if (newScrollLeft >= scrollingContainer.scrollWidth - 1500) {
                scrollingContainer.scrollLeft = 0;
            } else {
                scrollingContainer.scrollLeft = newScrollLeft;
            }

            // 强制触发滚动条重新开始
            scrollingContainer.scrollTo({ left: scrollingContainer.scrollLeft });

            // 循环调用自身
            requestAnimationFrame(autoScroll);
        }

        // 启动自动滚动
        autoScroll();
    });

    // document.addEventListener("DOMContentLoaded", function() {
    //     // 获取滚动容器的引用
    //     const scrollingContainer = document.getElementById('scrolling-container');

    //     // 设置滚动速度和方向
    //     const scrollSpeed = 2; // 可根据需要调整
    //     const direction = 1; // 向左滚动

    //     function autoScroll() {
    //     // 计算新的滚动位置
    //     const newScrollLeft = scrollingContainer.scrollLeft + direction * scrollSpeed;

    //     // 检查是否超出边界，超出时回到起始位置
    //     if (newScrollLeft <= 0) {
    //         scrollingContainer.scrollLeft = scrollingContainer.scrollWidth;
    //     } else {
    //         scrollingContainer.scrollLeft = newScrollLeft;
    //     }

    //     // 循环调用自身
    //     requestAnimationFrame(autoScroll);
    //     }

    //     // 启动自动滚动
    //     autoScroll();
    // })
    // document.addEventListener("DOMContentLoaded", function() {
    //     // 获取滚动容器的引用
    //     const scrollingContainer = document.getElementById('scrolling-container');

    //     // 设置滚动速度和方向
    //     const scrollSpeed = 2; // 可根据需要调整
    //     const direction = 1; // 向左滚动

    //     // 确保内容足够宽以启动滚动
    //     if (scrollingContainer.scrollWidth > scrollingContainer.clientWidth) {
    //         function autoScroll() {
    //             // 计算新的滚动位置
    //             const newScrollLeft = scrollingContainer.scrollLeft + direction * scrollSpeed;

    //             // 检查是否超出边界，超出时回到起始位置
    //             if (newScrollLeft >= scrollingContainer.scrollWidth) {
    //                 scrollingContainer.scrollLeft = 0;
    //             } else {
    //                 scrollingContainer.scrollLeft = newScrollLeft;
    //             }

    //             // 循环调用自身
    //             requestAnimationFrame(autoScroll);
    //         }

    //         // 启动自动滚动
    //         autoScroll();
    //     }
    // });

    // document.addEventListener("DOMContentLoaded", function() {
    //     // 获取滚动容器的引用
    //     const scrollingContainer = document.getElementById('scrolling-container');

    //     // 设置滚动速度和方向
    //     const scrollSpeed = 2; // 可根据需要调整
    //     const direction = 1; // 向左滚动

    //     // 确保内容足够宽以启动滚动
    //     if (scrollingContainer.scrollWidth > scrollingContainer.clientWidth) {
    //         function autoScroll() {
    //             // 计算新的滚动位置
    //             const newScrollLeft = scrollingContainer.scrollLeft + direction * scrollSpeed;
    //             console.log("xxs",newScrollLeft)
    //             // 检查是否超出边界，超出时回到起始位置
    //             if (newScrollLeft >= scrollingContainer.scrollWidth) {
    //                 scrollingContainer.scrollLeft = 0;
    //             } else {
    //                 scrollingContainer.scrollLeft = newScrollLeft;
    //             }

    //             // 循环调用自身
    //             requestAnimationFrame(autoScroll);
    //         }

    //         // 启动自动滚动
    //         autoScroll();
    //     }
    // });
    

</script>

<script>
  
</script>

<script>
export default {
    data() {
        return {

            datalist: "",
            data: "",
            elist: "",
            plist: "",
            slist_score: "",
            slist_points: "",
            sumscore: "",
            sumpoints: "",
            pdata: "",
            alist: "", // 公司榜单
            blist: "",

            dragonboatlist_1: "",
            dragonboatlist_2: "",

            dialogVisible: false,
            swiperData: [],
            swiperDialog: [],
            isFinish:false,
            page:1,
            isLoad:true,
            imagesShows:false
        }
    },
    methods: {
        getSwiperData() {
            this.myaxios("/HomeSelectHandler/", "get",{page:'1'}).then((res) => {

                if (res.code == 200) {
                    this.swiperData = res.data;
                }
            })
        },
        getDialogData(){
            this.isLoad = false;
            this.myaxios("/HomeSelectHandler/", "get",{page:this.page}).then((res) => {

                if (res.code == 200) {
                    this.swiperDialog.push(...res.data)
                    if(res.next_page){
                        this.page ++;
                        this.isFinish = false;
                    }else{
                        this.isFinish = true;
                    }
                    setTimeout(()=>{
                        this.isLoad = true;
                    },300)
                }
            })
        },
        videoCoverClick(val) {
            this.$emit('videoClick', '2', val.nid)
        },
        swiperClick(val) {
            this.dialogVisible = true;
            
            this.$nextTick(() => {
                let dom = document.querySelector(".flex-container");
                console.log(dom);
                if (!dom) return
                dom.addEventListener("scroll", () => {
                    const scrollDistance = dom.scrollHeight - dom.scrollTop - dom.clientHeight;
                    if (scrollDistance <= 10) {
                        console.log('到底啦，开始加载下一页');
                        if (!this.isFinish) {
                            if(this.isLoad){
                                this.getDialogData()
                            }
                        } else {
                            this.$message({ type: 'warning', message: '到底啦~~~' })
                        }

                    }
                });
            })
        },
        change_info(value) {
            if (value == 1) {
                return "学习成长"
            }
            if (value == 2) {
                return "诲人不倦"
            }
        },
        // 诲人不倦榜
        orderscore() {
            this.myaxios("/question/", "delete", { "event": 2 })
                .then(data => {
                    var lists = data.data
                    // this.data = data.data

                    for (let i in lists) {

                        lists[i]["id"] = Number(i) + 1

                    }

                    this.datalist = lists

                })



        },
        // 诲人不倦榜单
        handleClick() {

            this.myaxios("/question/", "delete", { "event": 1 })
                .then(data => {
                    var lists = data.data
                    // this.data = data.data

                    for (let i in lists) {

                        lists[i]["id"] = Number(i) + 1
                    }

                    this.data = lists

                })

        },

        // 突飞猛进榜
        EnterList() {
            this.myaxios("/ranklist/", "get")
                .then(data => {

                    var lists = data.data
                    // this.data = data.data

                    for (let i in lists) {

                        console

                        lists[i]["id"] = Number(i) + 1
                    }

                    // this.data = lists

                    this.elist = lists

                    // console.log(this.elist)
                })
        },

        // 突飞猛进榜
        PdateList() {
            this.myaxios("/ranklist/", "delete")
                .then(data => {

                    var lists = data.data
                    // this.data = data.data

                    for (let i in lists) {

                        console

                        lists[i]["id"] = Number(i) + 1
                    }

                    // this.data = lists

                    this.pdata = lists

                    // console.log(this.elist)
                })
        },

        // 战利品榜单
        getprize() {
            this.myaxios("/extract/", "delete", { "flag": 3 })
                .then(data => {
                    // console.log(data)
                    var lists = data.msg.info

                    for (let i in lists) {
                        lists[i]["id"] = Number(i) + 1
                    }

                    this.plist = lists
                })
        },

        // 总和
        sum_score() {

            this.myaxios("/question/", "delete", { "event": 3 })
                .then(data => {
                    var lists = data.data
                    // this.data = data.data

                    for (let i in lists) {

                        lists[i]["id"] = Number(i) + 1
                    }

                    this.slist_score = lists
                    this.sumscore = data.order["sum(score)"]

                })

        },

        // 总和
        sum_points() {

            this.myaxios("/question/", "delete", { "event": 4 })
                .then(data => {
                    var lists = data.data
                    // this.data = data.data
                    // console.log(lists)
                    for (let i in lists) {

                        lists[i]["id"] = Number(i) + 1
                    }

                    this.slist_points = lists
                    this.sumpoints = data.order["sum(points)"]

                })

        },

        // 公司榜单
        getAlist() {
            this.myaxios("/ranklist/", "put", { "event": 1 })
                .then(data => {
                    this.alist = data.data
                })
        },

        getBlist() {
            this.myaxios("/ranklist/", "put", { "event": 2 })
                .then(data => {
                    this.blist = data.data
                })
        },

        getdragonboatlist_1() {
            this.myaxios("/dragonboatlist/", "get", { "event": 1 })
                .then(data => {
                    var lists = data.data

                    for (let i in lists) {
                        // console.log(lists[i])
                        lists[i]["id"] = Number(i) + 1
                    }
                    // console.log("123123")
                    // console.log(this.dragonboatlist_1)
                    this.dragonboatlist_1 = lists
                })
        },

        getdragonboatlist_2() {
            this.myaxios("/dragonboatlist/", "get", { "event": 2 })
                .then(data => {

                    var lists = data.data

                    for (let i in lists) {
                        lists[i]["id"] = Number(i) + 1
                    }

                    this.dragonboatlist_2 = lists


                })
        },



    },

    mounted() {
        // this.orderscore()
        // this.handleClick()

        var company = localStorage.getItem("company")
        if( company.indexOf("北京") !== -1 ||  company.indexOf("济南") !== -1){
            this.imagesShows = true
        }

        this.EnterList()
        this.getprize()
        this.sum_score()
        this.sum_points()
        this.PdateList()
        this.getAlist()
        this.getBlist()
        this.getdragonboatlist_1()
        this.getdragonboatlist_2()
        // this.getSwiperData();
        // this.getDialogData();
    },

    created() {
        var uid = localStorage.getItem("uid")
        if (uid == 127 || uid == '127') {
            window.location.href = "http://www.crwnt.com";
        }
    }
}
</script>
<style>



    .grid-container {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      grid-template-rows: 200px,200px;
      gap: 20px;
      overflow-x: auto;
      white-space: nowrap;
      grid-auto-flow: column; 
      max-height: 420px; 
      margin-bottom: 10px;
    }

    

    .grid-container::-webkit-scrollbar {
        height: 6px;
    }

    .grid-container::-webkit-scrollbar-track {
        background-color: #ecf5ff; /* 轨道的颜色 */
    }

    .grid-container::-webkit-scrollbar-thumb {
        border-radius: 8px !important;
        box-shadow: inset 0 0 5px #ecf5ff !important;
        background: #ecf5ff !important;
    }

    .grid-container::-webkit-scrollbar-thumb:hover {
        background-color: #ecf5ff;
    }

    .grid-font{
        max-width: 180px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    

    .grid-item {
        max-width: 200px;
        flex: 1; 
        height: 200px;
        /* background-color: #ddd; */
        text-align: center;
        /* line-height: 200px; */
        border-top: 1px solid #ddd; 
        border-right: 1px solid #ddd; 
        box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2); 
      
    }


   

    



    

.lst {
    display: grid;
    /*  声明列的宽度  */
    grid-template-columns: repeat(4, 22%);
    /*  声明行间距和列间距  */
    grid-gap: 3%;
    /*  声明行的高度  */
    grid-template-rows: 800px ;

    /* justify-items: center; */
    justify-content: space-between;
    overflow: hidden;
}


.lst .scrollBar {
    height: 700px;
    overflow-y: auto;
}

.lst .scrollBar::-webkit-scrollbar-thumb {
    border-radius: 8px !important;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2) !important;
    background: #333 !important;
}

.lst .scrollBar::-webkit-scrollbar {
    width: 4px;
}




.demonstration {
    color: var(--el-text-color-secondary);
}

.el-carousel__item h3 {
    color: #475669;
    opacity: 0.75;
    line-height: 150px;
    margin: 0;
    text-align: center;
}

.el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
}

.flex-container {
    display: flex;
    flex-wrap: wrap;
    height: 400px;
    overflow: auto;
}

.flex-container .item {
    width: 200px;
    display: flex;
    flex-direction: column;
    text-align: center;
    cursor: pointer;
    margin: 10px;
}

.flex-container .item img {
    /* width: 120px;
    height: 60px; */
}

.container .item {
    height: 50px;
    line-height: 50px;
    border-bottom: 1px solid #dcdfe6;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    background: #fff;
    font-size: 14px;
}

.container .item .w60 {
    display: inline-block;
    width: 70px;
    text-align: center;

}

.container .item .w80 {
    display: inline-block;
    width: 100px;
    text-align: center;

}

.container .scrollBar {
    height: 700px;
    overflow-y: auto;
}

.container .scrollBar::-webkit-scrollbar-thumb {
    border-radius: 8px !important;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2) !important;
    background: #333 !important;
}

.container .scrollBar::-webkit-scrollbar {
    width: 4px;
}

.ml10 {
    margin-left: 10px;
}

.bg1 {
    color: #fec412;
    background-color: rgba(254, 196, 18, .1) !important;
}

.bg2 {
    color: #b4c0c7;
    background-color: rgba(180, 192, 199, .1) !important;
}

.bg3 {

    color: #714e3a;
    background-color: rgba(113, 78, 58, .1) !important;
}

.index_1 {
    display: inline-block;
    text-align: center;
    width: 30px;
    height: 30px;
    background-size: cover;
    background-image: url('../assets/one.png');
}

.index_2 {
    display: inline-block;
    text-align: center;
    width: 30px;
    height: 30px;
    background-size: cover;
    background-image: url('../assets/two.png');
}

.index_3 {
    display: inline-block;
    text-align: center;
    width: 30px;
    height: 30px;
    background-size: cover;
    background-image: url('../assets/three.png');
}</style>