<template>
<div>
	
	<div class="container">
		<div class="top" style="align-self: flex-start;"><top/></div>

		<div class="item_colume">


			<div class="left">
				
				<left :data="menulist" @val=get_val />

			</div>
			
			<div class="right">
			
				<!-- <right  /> -->
				<!-- <div v-if="did === 1">

					<right/>

				</div> -->

				<div v-if="did === 3">

					<detail/>

				</div>

				<div v-if="stuname != '' " >

					<right />
					
				</div>

				

			</div>
			
		</div>

	</div>	

</div>	
</template>


<script>
import left from './left.vue'
import right from './right.vue'
import top from './top.vue'
import detail from './detail.vue'
export default{
	components:{

		"left":left,
		"right":right,
		"top":top,
		"detail":detail,

	},

	data() {
		return{

			infoid:1,
			menulist:"",
			did:"",
			stuname:""

			
		}

	},




	


	methods:{


		// 阶段跳跃判断
		study:function(){

			alert("123")

			
		},


		// 获取子组件传递参数
		get_val(val){

			console.log(val)

			if(val.id){

				this.stuname = val.label

				
				this.resetSetItem('stuname',this.stuname)


			}else{

				this.stuname = ''
				this.did = val.did


			}

		},

		// 获取菜单数据
		get_menu(){

			this.myaxios("/menu/","get",{"uid":localStorage.getItem("uid")})
			.then(data=>{
				this.menulist = data.data
			})

		},

		// 获取新菜单数据
		get_newMenu(){

			this.myaxios("/newmenu/","get",{"uid":localStorage.getItem("uid")})

			.then(data=>{
				this.menulist = data.data
			})

		},

		//当前用户进度
		get_userinfo(){

			this.myaxios("/newmenu/","post",{"uid":localStorage.getItem("uid")})

			.then(data=>{
				this.stuname = data.name
				this.resetSetItem('stuname',this.stuname)
			})

		}
		
	},
	created(){

		console.log(this.$route.query)

		var username = this.$route.query.username
		var did = this.$route.query.did
		var uid = this.$route.query.uid

		if(username,did,uid){

			localStorage.setItem("username",username)
			localStorage.setItem("did",did)
			localStorage.setItem("uid",uid)

			// alert("123")
		}else{
			// alert("789")
		}

		this.get_userinfo()

		console.log(username,did,uid)

		// alert(this.$route.query)

	},
	mounted(){


		// this.get_menu()
		this.get_newMenu()
		
	},

}

</script>

<style scoped>

@import url("../assets/style.css");






</style>