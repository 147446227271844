<template>
<!-- <div class="add">
			
			<div class="son">
				<h1 align="center">登录</h1>
				<van-cell-group>
					<van-field v-model="username"  label="用户名:" placeholder="请输入用户名"/>
					<br>

					<van-field v-model="password" type="password" label="密码:" placeholder="请输入密码"/>
					<br>
					<svg @click="dingding" style="padding-left: 85%;  width: 30px;height: 30px;" t="1650251476846" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3115" width="200" height="200"><path d="M504.3712 513.1264m-450.816 0a450.816 450.816 0 1 0 901.632 0 450.816 450.816 0 1 0-901.632 0Z" fill="#59ADF8" p-id="3116"></path><path d="M665.6512 688.2816h89.1392c-55.4496 77.5168-108.8 152.064-162.1504 226.6112l-3.84-1.3824c11.6224-48.9472 23.296-97.8944 35.4304-148.9408H554.496c7.936-36.1472 15.36-70.0928 23.5008-107.2128-19.8144 5.9904-37.12 9.4208-52.9408 16.2816-34.1504 14.8992-64.8704 8.5504-92.6208-13.4656-18.3296-14.592-36.3008-30.4128-51.2-48.3328-15.5648-18.7392-10.7008-29.3888 13.0048-33.4336 50.2272-8.448 100.608-15.9232 151.0912-25.2928-10.5472 0-21.0944 0.1024-31.6928 0-43.4176-0.4096-86.8864-1.1264-130.304-1.28-26.6752-0.1024-45.4656-13.9264-61.5424-33.2288-20.7872-24.9344-34.5088-53.4016-42.24-84.7872-4.9152-19.8656 0.512-25.344 21.0432-20.5824 51.8656 12.0832 103.68 24.576 155.5456 36.7104 26.5216 6.1952 53.1456 11.9808 80.4352 15.4112-31.488-10.3936-63.2832-19.9168-94.3616-31.4368-47.36-17.5616-94.1056-36.6592-141.312-54.6816-14.336-5.4784-23.7568-15.0016-29.7984-28.7744-18.432-42.0352-32.2048-85.2992-32.5632-131.7376-0.1024-16.9472 5.5296-21.0432 20.0704-14.0288 148.1216 71.6288 300.9024 131.9424 454.3488 190.8224 14.7456 5.632 28.7232 13.9776 41.9328 22.7328 25.4976 16.9472 33.792 38.3488 20.5824 65.1776-27.0848 55.1424-56.8832 108.9024-85.8112 163.1232-7.0656 13.4656-15.104 26.368-24.0128 41.728z" fill="#FFFFFF" p-id="3117"></path></svg>

					<br><br>
					
					<van-button @click="login" round style="width: 100%; background-color: #00508f; color: white;">登录</van-button>
					<br>
				</van-cell-group>
			</div>
</div>	 -->


	<div style="padding: 30px;background-color: #ecf5ff;" >
		<h1>抱歉，根据地已转移，请前往钉钉工作台中搜索学以致用进行查看😁😁😁</h1>
		<h2>1. 点击钉钉左侧工作台</h2>
		<img width="600" height="600" src="../assets/save1.png" />
		<h2>2. 点击右侧搜索框，搜索学以致用</h2>
		<img style="width: 100%;height: 100%;" src="../assets/save2.png" />
		<h2>3. 建议收藏，方便下次找着</h2>
		<h2>3.1 工作台中点击添加</h2>
		<img width="1100" height="500" src="../assets/save3.png" />
		<h2>3.2 在右侧搜索学以致用进行添加，添加后后续无需搜索直接查看</h2>
		<img width="800" height="600" src="../assets/save4.png" />
	</div>

</template>


<script>

	

	 var userAgent = navigator.userAgent;
	 if (userAgent.indexOf("Firefox") > -1) {//判断是否Firefox浏览器
	 	console.log("ffff")

	 	while(true){

	 		// this.$toast.success("444")
	 		alert("请更换浏览器")
	 		

	 	}

        // return "FF";
    }
	
export default{
	components:{

	},

	data() {
		return{

			username:"",  //用户名
			password:"", //密码
			department:"", //部门
			options:[
				{"text":"研发部","id":0},
				{"text":"销售部","id":1},
				{"text":"综合部","id":2},
			]
			
			
		}

	},
	methods:{
		// 注册请求
		login:function(){
			// alert(this.department)
			this.myaxios("/login/","get",{"username":this.username,"password":this.password})
			.then(data=>{

				if(data.code === 200){
					this.$toast.success("登录成功")
					//存入至localstorage
					localStorage.setItem("uid",data.uid)
					localStorage.setItem("username",data.username)
					// localStorage.setItem("did",data.department)
					//跳转用户信息界面
					this.$router.push({path:'/test'})
				}else{
					//提示错误信息
					this.$toast.fail(data.msg)
				}					

			})
		},
		dingding:function(){

			// this.$toast.success("123123")
			this.myaxios("/login/dingding_back","post")
			.then(data=>{

				window.location.href=data.data

			})

		},

		go_register:function(){
			//跳转注册页面
			this.$router.push({path:"/test"})
		},


		

	},
	created(){


		

		var username = this.$route.query.username
		var uid = this.$route.query.uid
		var company = this.$route.query.company
		// let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
		// // alert(flag)
		if(username,uid,company){

			localStorage.setItem("username",username)
			localStorage.setItem("uid",uid)
			localStorage.setItem("company",company)
			this.$router.push({path:'/test'})
		// 	if(flag){
		// 		this.$router.push({path:'/phoneVideo'})
		// 	}else{
		// 		this.$router.push({path:'/test'})
		// 	}
		}


			

		// 	// alert("123"
		// }else{
		// 	// alert("789")
		// }


	},
	mounted(){
		
	},

	beforeCreate: function() {
		// console.log(document.getElementsByTagName("body"))
        // document.getElementsByTagName("body")[0].setAttribute("style","background-image: ../assets/login.gif");
    },

}

</script>

<style >

/*@import url("../assets/style.css");*/

.add {
		position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        overflow-y: auto;
        /*background-color: #fff;*/
        background-image: url(../assets/login.gif);


}



.son{
	/*display: flex;*/
	width: 30%;
	height: 30%;
	position: absolute;
    top: 20%;
    bottom: auto;
    left: 0;
    right: 0;
    margin: auto;
    /*margin-top: auto;*/
    background-color: pink;
}



</style>