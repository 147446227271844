<template>
<div>
    
    <el-radio-group @change="chflag" v-model="flag">
        <el-radio-button :label="3">审核中</el-radio-button>
        <el-radio-button :label="1">审核通过</el-radio-button>
        <el-radio-button :label="2">审核拒绝</el-radio-button>
    </el-radio-group>



     <el-table height="800" :data="data" >
            <!-- <el-table-column prop="video_name" label="视频名称"  /> -->
            {{}}

            <el-table-column label="视频名称">
                <template #default="scope">
                    
                    <el-link @click="getVideomsg(scope.row)" >{{scope.row.video_name}}</el-link>

                </template>
            </el-table-column>


            <el-table-column prop="topname" label="上级菜单名称" />
            
            <el-table-column  label="详情">

                <template #default="scope">

                    <el-link v-if="scope.row.atype == 3"  @click="questioninfo(scope.row)" >查看原视频</el-link>

                    <el-link v-else @click="questioninfo(scope.row)" >查看问题</el-link>

                </template>
                
            </el-table-column>

            


            <el-table-column label="审核类型">
                <template #default="scope">

                    {{change_state(scope.row.atype)}}

                </template>
            </el-table-column>

            <el-table-column label="视频大小" >
                <template #default="scope">

                    {{scope.row.filetime}} <br> {{scope.row.filesize}}

                </template>


            </el-table-column>  


            <el-table-column prop="state" label="审核状态" />

                

            <el-table-column prop="audit" label="审核人" />
            <!-- <el-table-column prop="username" label="上传人" /> -->
            <el-table-column prop="msg" label="拒绝描述" />
            
            <el-table-column width="300"  align="right">
              <template #header>
                <el-input  @change="postmenu" v-model="search" size="small" placeholder="请输入关键字" />
              </template>

              <template #default="scope">
                
              	
                <div v-if="scope.row.state == '审核拒绝' && scope.row.atype == 4 || scope.row.atype == 5 ">
                	<el-button @click="AnewInfo(scope.row)">重新提交</el-button>
                    
                </div>
                
                <div v-if="scope.row.state == '审核通过'">
                    已通过
                </div>
                <div v-if="scope.row.state == '审核中'">
                    审核中
                </div>

                <div v-if="scope.row.state == '审核拒绝' && scope.row.atype == 0">
                	请前往上传页面重新上传
                </div>
                
          </template>

             </el-table-column>
        </el-table>


        <el-dialog
            v-model="dialogVisible"
            title="视频播放 "
            width="50%"
          >
            <span><video style="width:100%;" :src="urls" controls="controls"></video></span>
            <!-- <template #footer>
              <span class="dialog-footer">
                <el-button @click="dialogVisible = false">Cancel</el-button>
                <el-button type="primary" @click="dialogVisible = false"
                  >Confirm</el-button
                >
              </span>
            </template> -->
          </el-dialog>


          <el-dialog
            v-model="qinfo"
            title="问题查看"
            width="50%"
            destroy-on-close
            style="text-align: center;"
          >



            <div v-if="atype == 3">

                <span><video style="width:100%;" :src="qlist" controls="controls"></video></span>

            </div>
            <div v-if="atype == 0">
                
                <span v-for="(i,k) in qlist">

                问题{{k+1}}：{{i.content}}<br>


                <span v-for="(j,key) in i.answer">
                    选项{{key+1}}：{{j.text}}<br>
                </span>
                正确答案：选项{{i.answer_id}}<br>
                <br>
            </span>


            </div>


            

            
          </el-dialog>



          <el-dialog
            v-model="form_show"
            title="提交"
            width="50%"
            style="text-align: center;"
            destroy-on-close
          >



            <div v-for="(item,key) in form">

                <el-form :model="item">

                    <el-form-item :label="'问题'+(key+1)">
                        <el-input v-model="item.content" />
                    </el-form-item>
                    <div v-for="(j,k) in item.answer">
                    <el-form-item :label="'选项'+(k+1)">
                        <el-input v-model="j.text" />
                    </el-form-item>
                    </div>
                    <el-form-item label="正确答案">
                        
                        <span v-for="j in item.answer">
                            <el-radio style="padding-right: 10px;" v-model="item.answer_id" :label="j.id" size="large">选项{{j.id}}</el-radio>
    
                        </span>
                        
                        
                    </el-form-item>
                    <div style="text-align: center;">
                    <el-button @click="addAnswer(key)">
                        +&nbsp;添加选项
                    </el-button>
                    <el-button @click="delAnswer(key)">
                        -&nbsp;删除选项
                    </el-button>
                    </div>
                </el-form>

            </div>
            <br>
            <el-button @click="addQuestion">+&nbsp;添加问题</el-button>
            <el-button @click="delQuestion">-&nbsp;删除问题</el-button>
            <br><br>

            <template #footer>
              <span class="dialog-footer">
                <el-button @click="AnewMsg()">提交</el-button>
                <el-button type="primary" @click="form_show = false"
                  >取消</el-button
                >
              </span>
            </template>


            

            
          </el-dialog>









       

          




</div>  
</template>


<script>
import { da } from "element-plus/es/locale";

    
export default{
    // 注册组件
    components:{

    },
    // 数据变量
    data() {
        return{


            data:"",
            userList:"",
            stateList:[],
            content:"",
            // 视频弹出状态
            dialogVisible:false,

            urls:"",
            ftext:false,

            aid:"",
            // 诲人不倦值状态
            fscore:false,
            // 诲人不倦值
            score:"",
            nflag:"",
            qinfo:false, // 问题弹窗
            qlist:"", // 问题列表
            flag:"",  // 切换标志
            atype:"", // 审核类型

            form:"",//重新提交表单
            form_show:false,


        }

    },

    // 方法
    methods:{


    	AnewMsg(){


    		for(var item in this.form){

                console.log(this.form[item])

                if(this.form[item].content === "" || this.form[item].answer_id === ""){

                    // this.$toast.fail("")
                    this.$message({
                            showClose: true,
                            message:'抱歉，请填写完整信息',
                            type: 'error',
                            })
                    return

                }

                var flag = 0

                for(var i in this.form[item].answer){

                    if(this.form[item].answer[i].text === ""){
                        this.$message({
                            showClose: true,
                            message:'抱歉，答案未填写完整',
                            type: 'error',
                            })

                        // this.$toast.fail("")
                        return

                    }
                    if(this.form[item].answer[i].id != this.form[item].answer_id){

                            flag+=1

                            console.log(this.form[item].answer[i].text)

                            console.log(this.form[item].answer_id)

                            
                    }

                }



                if(flag != (this.form[item].answer.length -1)){
                    this.$message({
                            showClose: true,
                            message:'抱歉，选项中未存在该答案',
                            type: 'error',
                            })

                    

                    return 

                }


                if(this.form[item].answer.length <= 1){

                    this.$message({
                            showClose: true,
                            message:'抱歉，至少有两个选项',
                            type: 'error',
                            })


                    // this.$toast.fail("")
                    return

                }


            }


    		this.myaxios("/msginfo/","post",{"aid":this.aid,"question":JSON.stringify(this.form)})

    		.then(data=>{


    			this.$toast.success("请等待审核")

    			this.form_show = false


    		})


    	},



    	AnewInfo(info){


    		this.aid = info.id
    		this.myaxios("/msginfo/","get",{"aid":info.id})
                .then(data=>{

                    this.atype = 0

                    var lists = data.data
                    for(let i=0; i<lists.length; i++){

                        // alert(lists[i].answer)
                        console.log(lists[i].answer)

                        lists[i].answer = JSON.parse(lists[i].answer)
                    }

                    this.form = lists
                    this.form_show = true

                })



    	},


        questioninfo(info){

            if(info.atype == 3 ){

                this.atype = 3

                this.myaxios("/msginfo/","get",{"aid":info.id,"atype":3})
                .then(data=>{

                    this.qlist = data.data
                    this.qinfo = true

                })

            }else{

                this.myaxios("/msginfo/","get",{"aid":info.id})
                .then(data=>{

                    this.atype = 0

                    var lists = data.data
                    for(let i=0; i<lists.length; i++){

                        // alert(lists[i].answer)
                        console.log(lists[i].answer)

                        lists[i].answer = JSON.parse(lists[i].answer)
                    }

                    this.qlist = lists
                    this.qinfo = true

                })

            }


            
            

        },

        chflag(){

            this.getMessage(this.flag)

        },

        
        

        


        // 播放视频

        getVideomsg(info){

            if(info.atype == 1){

                this.urls = "http://101.200.139.27:8000/static/audit/" + info.video_name

            }

            else if(info.atype == 2){

                this.urls = "http://101.200.139.27:8000/static/video/" + info.video_name

            }

            else if(info.atype == 3){

                this.urls = "http://101.200.139.27:8000/static/replace/" + info.video_name

            }


            this.dialogVisible = true

        },

        // 过滤
        change_state(value){


            // alert(value)

            if(value == 0){

                return "请重新上传"

            }
            else if(value == 1){

                return "新视频新试题"
            }
            else if(value == 2){

                return "已存在视频上传试题"

            }

            else if(value == 3){

                return "视频替换"

            }

            else if(value == 4){

                return "已拒绝"

            }


        },


        handleEdit(e,a){
            console.log(e)
            console.log(a)
            // this.drawer = true

        },

        // 获取审核信息
        getMessage(flag){

            this.myaxios("/audit/","get",{"uid":localStorage.getItem("uid"),"flag":flag})
            .then(data=>{


                this.data = data.data

                console.log(data)

            })

        },




        //添加问题字段
        addQuestion:function(){
            var dicts = {
                question:"",
                answer:[{id:1,text:""}],
                answer_id:""
            }

            this.form.push(dicts)
        },

        // 添加答案字段
        addAnswer:function(k){

            console.log(k)

            var ks = this.form[k].answer.length

            var dicts = {id:ks+1,text:""}

            this.form[k].answer.push(dicts)

        },

        // 删除答案字段
        delAnswer:function(k){

            // var index = this.form[k].answer.indexOf(k)

            // console.log(this.form[k].answer.length)

            if(this.form[k].answer.length === 1){

                this.$toast.fail("不能删了，再删就没有了")

                return

            }else{
                console.log(this.form[k].answer)
                this.form[k].answer.splice((this.form[k].answer.length)-1,1)
            }

            // console.log(index)

            

        },

        // 删除问题字段
        delQuestion:function(){

            if(this.form.length === 1){

                return

            }else{

                this.form.splice((this.form.length)-1,1)
            }

            // console.log(index)

        },

        // 下拉菜单请求
		getmenus:function(){

			this.myaxios("/menumsg/","delete")

			.then(data=>{

					this.menus = data.data

			})

		},
        

        

       


       

       
    },
    // 生命周期钩子函数
    // 用来在一个实例被创建之后执行
    created(){

    },
    //模板中的html渲染到了html页面中，一般用作于axios请求
    mounted(){

    	this.flag = 3
        
        this.getMessage(3)
        

    },

}

</script>

<style scoped>

@import url("../assets/style.css");

</style>