<template>
  <div class="box-wrapper">
    <!-- 盲盒大转盘 -->
    <div v-for="item in contentList">
      <div
        class="content-item"
        :class="{ picked: item.order === curIndex }"
        @click="handleClick(item.order)"
      >
        <!-- <img :src="item.content_img" alt="" class="content-img" /> -->
        <div class="content-img">{{item}}</div>
      </div>
    </div>
  </div>
</template>

<script>
// import jsBook from '../assets/imgs/jsBook.jpeg'
// import vue from '../assets/imgs/vue.webp'
// import react from '../assets/imgs/react.webp'
// import css from '../assets/imgs/css.webp'
// import perf from '../assets/imgs/perf.webp'
// import node from '../assets/imgs/node.png'
// import alg from '../assets/imgs/alg.webp'
// import build from '../assets/imgs/build.webp'
// import box from '../assets/imgs/box.png'

export default {
  name: "Box",
  data() {
    return {
      // 学习内容列表
      contentList: [
        {
          name: 'JS 红宝书',
          // content_img: jsBook,
          content_id: 'jsBook',
          order: 1,
          isPicked: true,
        },
        {
          name: 'Vue',
          // content_img: vue,
          content_id: 'vue',
          order: 2,
          isPicked: false,
        },
        {
          name: 'React',
          // content_img: react,
          content_id: 'react',
          order: 3,
          isPicked: false,
        },
        {
          name: 'CSS',
          // content_img: css,
          content_id: 'css',
          order: 8,
          isPicked: false,
        },
        {
          name: '抽取学习盲盒',
          // content_img: box,
          content_id: 'box',
          order: 0,
          isPicked: false,
        },
        {
          name: '前端性能或安全',
          // content_img: perf,
          content_id: 'perf',
          order: 4,
          isPicked: false,
        },
        {
          name: '前端工程化',
          // content_img: build,
          content_id: 'build',
          order: 7,
          isPicked: false,
        },
        {
          name: 'Node',
          // content_img: node,
          content_id: 'node',
          order: 6,
          isPicked: false,
        },
        {
          name: '算法',
          // content_img: alg,
          content_id: 'alg',
          order: 5,
          isPicked: false,
        },
      ],
      // 选中的内容
      pickedContent: '',
      // 选中的内容index
      pickedIndex: 1,
      // 当前的内容
      curIndex: 1,
      speed: 100,
      totalSteps: 0,
      hasStarted: false
    };
  },
  methods: {
    handleClick(order) {
      if (order !== 0 || this.hasStarted) return
      // 只处理中间按钮的点击事件
      this.start()
    },
    start() {
      // 随机生成一个步数,大概3～6圈
      this.totalSteps = Math.floor(Math.random() * 24) + 24
      // 已经走的步数
      let hasRun = 0
      this.move(hasRun)
    },
    move(hasRun) {
      const len = this.contentList.length - 1
      setTimeout(() => {
        if (hasRun <= this.totalSteps) {
          if (this.totalSteps - hasRun < 2 * len) {
            // 放慢速度
            this.speed = 300
          } else if (this.totalSteps - hasRun < len) {
            // 最后一轮，再慢一点
            this.speed = 600
          }
          this.curIndex = hasRun % len + 1
          hasRun++
          this.move(hasRun)
        } else {
          // 抽到了！
          this.speed = 100
          this.hasStarted = false
          this.pickedIndex = this.curIndex
          // 获取抽到的内容，用于弹窗提示
          this.contentList.forEach(item => {
            if (item.order === this.pickedIndex) {
              this.pickedContent = item.name
            }
          })
          // 弹窗通知
          this.$alert(`今天可以学一学 ${this.pickedContent} 哦！`, {
            confirmButtonText: '知道啦',
            type: 'success',
            callback: () => {
              this.curIndex = 1
            }
          });
        }
      }, this.speed)
    }
  }
}
</script>

<style scoped>
.box-wrapper {
  width: 500px;
  height: 500px;
  margin: 0 auto;
  margin-top: 100px;
  overflow-y: hidden;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
}
.content-item {
  border-radius: 2px;
  width: 150px;
  height: 150px;
  border: 4px solid transparent;
  cursor: pointer;
}
.content-img {
  width: 150px;
  height: 150px;
  object-fit: cover;
}
.picked {
  border: 4px solid rgb(224, 216, 106);
  opacity: 0.8;
}
</style>

