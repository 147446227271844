<template>
	<div v-if="showFalg" class="common-layout">
		<el-container style="width: 100%; height:100%;">
			<el-header style="background-color: #ffffff;">
				<el-menu class="el-menu-demo" mode="horizontal" :default-active="active" :ellipsis="false">
					<div class="logott">
						<van-image style="padding-top: 10px; width: 100px; height: 30px;"
							src="http://101.200.139.27:8000/static/logo.png" />
					</div>
					<el-menu-item v-for="item in supmenu" :index="item.id" @click="asd(item.id)">
						{{ item.name }}
					</el-menu-item>
				</el-menu>
			</el-header>

			<div v-if="this.menus === 1 || this.menus === 3041 || this.menus === 3769 || this.menus == 5240" >

				<el-main style="background-color: #ecf5ff;">
					<div v-if="this.menus === 1">
						<home @videoClick="videoClick" />
					</div>
					<div v-if="this.menus === 3041">
						<blindbox />
					</div>
					<div v-if="this.menus === 3769">
						<shop />
					</div>
					<div v-if="this.menus === 5240">
						<lotteryDraw  />
					</div>
				</el-main>

			</div>

			<!-- <div v-if="this.menus === 1">
      		
      		<el-main>
      			
      			
      			
      		</el-main>


      	</div>

			<div v-if="this.menus === 2708">
      		
      		<el-main style="background-color:  #ecf5ff;">
      			
      			<blindbox/>

      		</el-main>


      	</div> -->

			<el-container v-else>


				<el-aside style="background-color: #fff;" height="800px" width="300px">

					<el-scrollbar height="800px">


						<div v-if="this.menus === 3 || this.menus === 2 || this.menus === 2221 || this.menus === 3722">
							<el-menu background-color="#fff" text-color="#333" active-text-color="#4a5dff"
								class="el-menu-demo" @open="subMenuOpen">
								<treemenu :data="menu_data"></treemenu>
							</el-menu>
						</div>

						<div v-else>
							<el-menu background-color="#fff" text-color="#333" active-text-color="#4a5dff"
								class="el-menu-demo" style="height: 800px;">
								
								<el-menu-item v-for="item in menu_data" :index="item.id" @click="qqq(item.id)">
									{{ item.name }}
								</el-menu-item>

							</el-menu>


						</div>

					</el-scrollbar>


				</el-aside>
				<el-main style="background-color:  #ecf5ff; width: calc(100% - 300px);">
					<el-scrollbar style="height: calc(800px - 10px);">
						<div v-if="this.menus === 4">

							<div v-if="this.rightid === 2662">
								<userupload />
							</div>

							<div v-if="this.rightid === 2663">

								<uploadinfo />

							</div>

							<div v-if="this.rightid === 2661">

								<selfinfo />

							</div>

							<div v-if="this.rightid === 3769">

								<shop />

							</div>

							<div v-if="this.rightid === 4890">
								<self_skill />
							</div>

							<div v-if="this.rightid === 6743">
								<videoCollection/>
							</div>


						</div>

						<!-- 后台管理 -->
						<div v-if="this.menus === 5">
							<!-- <el-scrollbar height="800px"> -->

							<div v-if="this.rightid === 2665">
								<menumanage />
							</div>

							<div v-if="this.rightid === 2664">

								<audit />

							</div>

							<div v-if="this.rightid === 3571">

								<rbac_manage />

							</div>

							<div v-if="this.rightid === 3666">

								<videomsg />

							</div>

							<div v-if="this.rightid === 3770">

								<shopMag />

							</div>



							<!-- </el-scrollbar> -->

						</div>


						<div v-if="this.menus === 2666">
							<!-- <el-scrollbar height="800px"> -->

							<div v-if="this.rightid === 2667">
								<leaderAudit />
							</div>

							<div v-if="this.rightid === 2668">
								<Lscore />
							</div>

							<!-- </el-scrollbar> -->

						</div>



						<div v-if="this.menus === 3 || this.menus === 2 || this.menus === 2221 || this.menus === 3722">

							<newright v-if="isShow" />

						</div>

						<!-- <van-button @click="login">点我</van-button> -->

					</el-scrollbar>
				</el-main>

			</el-container>
		</el-container>
	</div>


	<div style="padding: 30px;background-color: #ecf5ff;" v-else>
		<h1>抱歉，根据地已转移，请前往钉钉工作台中搜索学以致用进行查看😁😁😁</h1>
		<h2>1. 点击钉钉左侧工作台</h2>
		<img width="600" height="600" src="../assets/save1.png" />
		<h2>2. 点击右侧搜索框，搜索学以致用</h2>
		<img style="width: 100%;height: 100%;" src="../assets/save2.png" />
		<h2>3. 建议收藏，方便下次找着</h2>
		<h2>3.1 工作台中点击添加</h2>
		<img width="1100" height="500" src="../assets/save3.png" />
		<h2>3.2 在右侧搜索学以致用进行添加，添加后后续无需搜索直接查看</h2>
		<img width="800" height="600" src="../assets/save4.png" />
	</div>

</template>


<script>

import * as dd from 'dingtalk-jsapi'

import treemenu from "./treemenu.vue"
import newright from "./newright.vue"
import userdetail from "./userdetail.vue"
import general from "./general.vue"
import menumanage from "./menumanage.vue"
// import userupload from "./userupload"
import audit from "./audit"
import leaderAudit from "./leaderAudit"
import Lscore from "./Lscore"
import uploadinfo from "./uploadinfo"
import selfinfo from "./selfinfo"
import blindbox from "./blindbox"
import home from "./home"
import rbac_manage from "./rbac_manage"
import videomsg from "./videomsg"
import shop from "./shop"
import shopMag from "./shopMag"
import self_skill from "./self_skill"

import lotteryDraw from './lotteryDraw'
import userupload from './newView/userupload'
import videoCollection from './newView/videoCollection'



// var userAgent = navigator.userAgent;
// if (userAgent.indexOf("Firefox") > -1) {//判断是否Firefox浏览器
// 	console.log("ffff")

// 	while (true) {

// 		// this.$toast.success("444")
// 		alert("请更换浏览器")


// 	}

// 	// return "FF";
// }

// SET NODE_OPTIONS=--openssl-legacy-provider && 

import { mapGetters } from 'vuex';

export default {
	// 注册组件
	components: {
		"treemenu": treemenu,
		"newright": newright,
		"userdetail": userdetail,
		"general": general,
		"menumanage": menumanage,
		"userupload": userupload,
		"audit": audit,
		"leaderAudit": leaderAudit,
		"Lscore": Lscore,
		"uploadinfo": uploadinfo,
		"selfinfo": selfinfo,
		"blindbox": blindbox,
		"home": home,
		"rbac_manage": rbac_manage,
		"videomsg": videomsg,
		"shop": shop,
		"shopMag": shopMag,
		"self_skill": self_skill,
		lotteryDraw,
		videoCollection
	},

	watch:{

		// seach:{
		// 	seach:function(newVal,oldVal){
		// 		this.resetSetItem('nid',newVal)
		// 	},
		// 	// deep:true,
		// 	// immediate:true,
		// // }

		superNid:function(newVal,oldVal){
			// console.log("newVal",newVal)
			// console.log("oldVal",oldVal)
			// this.nid = newVal
			// this.getVideo(this.nid)
			this.$store.commit('setNid', newVal);
			this.active = 3
			this.menus = 3
		}

	},

	computed: {
		// 使用计算属性获取 nid
		...mapGetters(['getMenuid']),
		superNid() {
			return this.getMenuid;
		}
	},

	// 数据变量
	data() {
		return {
			active:'1',
			menu_data: "",
			// supmenu: [{ "id": 1, "name": "学习进度" }, { "id": 2, "name": "个人中心" }],
			// ,{name:"轮盘抽奖",id:5240},{ "name": "惊喜盲盒", "id": 3041,"soted": 9},
			// SET NODE_OPTIONS=--openssl-legacy-provider &&
			supmenu:[
				// {
				// 	"name": "首页",
				// 	"id": 1,
				// 	"soted": 1
				// },
				// {
				// 	"name": "通用模块",
				// 	"id": 2,
				// 	"soted": 2
				// },
				// {
				// 	"name": "部门培训",
				// 	"id": 3,
				// 	"soted": 3
				// },
				// {
				// 	"name": "学习提升模块",
				// 	"id": 2221,
				// 	"soted": 4
				// },
				// {
				// 	"name": "管理提升模块",
				// 	"id": 3722,
				// 	"soted": 5
				// },
				// {
				// 	"name": "个人中心",
				// 	"id": 4,
				// 	"soted": 6
				// },
				// {
				// 	"name": "后台管理",
				// 	"id": 5,
				// 	"soted": 7
				// },
				// {
				// 	"name": "数据检索",
				// 	"id": 2666,
				// 	"soted": 8
				// },
				// {
				// 	"name": "惊喜盲盒",
				// 	"id": 3041,
				// 	"soted": 9
				// },
				// {
				// 	"name": "积分商城",
				// 	"id": 3769,
				// 	"soted": 10
				// }
			],
			nid: "",
			menus: "",
			images: [
				'https://cdn.jsdelivr.net/npm/@vant/assets/apple-1.jpeg',
				'https://cdn.jsdelivr.net/npm/@vant/assets/apple-2.jpeg',
			],

			rightid: "",
			showFalg:true,

			isShow:true,

		}

	},
	// 方法
	methods: {
		ssss(){
			console.log('ssssssssssssssssssssss')
		},	
		videoClick(val,nid){
			this.resetSetItem('nid',nid)
			this.active = val;
			this.asd(val)
		},
		subMenuOpen(nid,index,type){
			
			// console.log('index-------->',nid,type);
			this.myaxios("/supermenu/", "post", { "uid": localStorage.getItem("uid"),topid:nid}).then(data => {
				if(type){
					this.menu_data = data.data
				}else{
					this.recursion(this.menu_data,data.data)
				}
			})
		},
		recursion(menu,data){
			for (let i = 0; i < menu.length; i++) {
				if(menu[i].child && menu[i].child.length){
					this.recursion(menu[i].child,data)
				}else{
					menu[i].child = [];
					for (let j = 0; j < data.length; j++) {
						if(data[j].topid == menu[i].id){
							menu[i].child.push(data[j])
						}
					}
				}
				
			}
		},

		tests: function () {
			this.myaxios("/supermenu/", "get", { "uid": localStorage.getItem("uid") }).then(data => {
				this.supmenu = data.data;
			})
		},

		get_val(val) {

			alert(val)

		},


		// 请求子集菜单
		test: function (topid) {
			// axios请求     请求地址                 请求方式
			this.myaxios("/supermenu/", "post", { "uid": localStorage.getItem("uid"), "topid": topid })
				.then(data => {
					// 打印请求后返回值
					this.menu_data = data.data

				})

		},
		

		asd: function (e) {
			this.menus = e;
			console.log(e)
			this.test(e)
		},

		login: function () {

			this.myaxios("/login/dingding_back", "post").then(data => {
				window.location.href = data.data;
			})
		},
		qqq(id) {

			console.log(id)
			this.rightid = id

		},

		dingLogin: function (callback) {
			let corpId = 'dingfacc734f359f60ed35c2f4657eb6378f' // 企业id，可以到钉钉后台去查看
			if (dd.env.platform !== 'notInDingTalk') { // 判断是不在钉钉（pc、手机）内进行请求
				dd.ready(() => {
					//使用SDK 获取免登授权码
					dd.runtime.permission.requestAuthCode({
						corpId: corpId,
						onSuccess: info => {
							// 根据钉钉提供的api 获得code后,再次调用这个callback方法
							// 由于是钉钉获取code是异步操作,不知道什么时候执行完毕
							// callback 函数会等他执行完毕后在自己调用自己
							callback(info.code)
						},
						onFail: err => {
							alert('fail')
							alert(JSON.stringify(err))
							this.code = err
						}
					})
				})
			}else{
				var username = localStorage.getItem("username")
				var uid = localStorage.getItem("uid")
				if(username && uid){
					this.menus = 1
					this.tests()
				}else{
					this.showFalg = false
				}

			}
		},

		// 跳转至浏览器login路由
		GoToChome(username,uid,company){
			let url = `http://101.200.139.27/#/login?username=${username}&uid=${uid}&company=${company}`
			window.open(url, '_blank');
		},


		getUserInfo(code){
			this.myaxios("/UserDingLogin/","get",{"code":code})
			.then(data=>{
				console.log(data)
				localStorage.setItem("username", data.username)
				localStorage.setItem("uid", data.uid)
				localStorage.setItem("company", data.company)
				this.menus = 1
				this.tests()
				this.GoToChome(data.username,data.uid,data.company)
			})
		}





	},
	// 生命周期钩子函数
	// 用来在一个实例被创建之后执行
	created() {

		this.dingLogin(code=>{
			this.getUserInfo(code) 
		})

		// if(process.env.NODE_ENV == 'development'){
		// 	localStorage.setItem("username", '任达')
		// 	localStorage.setItem("uid", '17')
		// 	localStorage.setItem("company", '北京公司')
		// 	this.menus = 1
		// 	this.tests()
		// }else{
		// 	this.dingLogin(code=>{
		// 		this.getUserInfo(code) 
		// 	})
		// }

		

		// 

		

		// this.subMenuOpen(2221,'',true)



	},

	//离开页面时清空缓存进行跳转



	//模板中的html渲染到了html页面中，一般用作于axios请求
	mounted() {


		// console.log(this.$route.query)

		// var username = this.$route.query.username
		// var uid = this.$route.query.uid

		// if (username, uid) {
		// 	localStorage.setItem("username", username)
		// 	localStorage.setItem("uid", uid)

		// 	// alert("123"
		// } else {
		// 	// alert("789")
		// }

		// this.menus = 1
		// this.tests()






	},

}

</script>

<style >
.el-aside {
	/*width: 800px;*/
	height: auto;
}

.logott {}

.logott:hover {
	/*-webkit-transform: rotate(666turn);*/
	transform: rotate(666turn);
	/*动作*/
	transition-delay: 1s;
	/*开始前等待*/
	transition-property: all;
	/*浏览器支持*/
	transition-duration: 59s;
	/*持续时间*/
	transition-timing-function: cubic-bezier(.34, 0, .84, 1)
		/*速度曲线限制*/
}



/*@import url("../assets/style.css");*/
</style>