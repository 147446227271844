import { createRouter, createWebHistory,createWebHashHistory } from 'vue-router'


// 引入页面
import test from '../components/test'
import register from '../components/register'
import userinfo from '../components/userinfo'
import login from '../components/login'
import logins from '../components/logins'
import menutree from '../components/menutree'
import left from '../components/left'
import menumanage from '../components/menumanage'
import newleft from '../components/newleft'
import treemenu from '../components/treemenu'
import userupload from '../components/userupload'
import demo from '../components/demo'
import audit from '../components/audit'
import leaderAudit from '../components/leaderAudit'
import selfinfo from '../components/selfinfo'
import Sweepstakes from '../components/Sweepstakes'
import sweep from '../components/sweep'
import text1 from '../components/test/text1'
import rbac_manage from '../components/rbac_manage'
import videomsg from '../components/videomsg'
import shop from '../components/shop'
import shopMag from '../components/shopMag'
import phoneVideo from '../components/phoneVideo'
import lotteryDraw from '../components/lotteryDraw'
import main from '../components/video/main'
// import phoneVideo from '../components/'

// import blindbox from '../components/blindbox'

// Vue.use(Router)

var routes = [
        
        // 路由

        {
          path:'/test', // 路径
          name:'test',
          component:test, // 组件
          // beforeEnter:(to, from, next) => {
          //   if (!localStorage.getItem("uid")) {
          //     next({ path:'/login'})
          //   } else {
          //     next()
          //   }
          // }
        },
        {
          path:'/phoneVideo', // 路径
          name:'phoneVideo',
          component:phoneVideo // 组件
        },
        {
          path:'/videomsg', // 路径
          name:'videomsg',
          component:videomsg // 组件
        },
        {

          path:'/Sweepstakes/',
          name:'Sweepstakes',
          component:Sweepstakes

        },
        {

          path:'/rbac_manage/',
          name:'rbac_manage',
          component:rbac_manage

        },
        {

          path:'/text1/',
          name:'text1',
          component:text1

        },
        {

          path:'/sweep/',
          name:'sweep',
          component:sweep

        },
        {

          path:'/audit/',
          name:'audit',
          component:audit

        },
        {

          path:'/selfinfo/',
          name:'selfinfo',
          component:selfinfo

        },
        {

          path:'/leaderAudit/',
          name:'leaderAudit',
          component:leaderAudit

        },
        {
          path:'/demo', // 路径
          name:'demo',
          component:demo // 组件
        },
        {
          path:'/treemenu', // 路径
          name:'treemenu',
          component:treemenu // 组件
        },
        {
          path:'/userupload', // 路径
          name:'userupload',
          component:userupload // 组件
        },

        {
          path:'/menumanage', // 路径
          name:'menumanage',
          component:menumanage // 组件
        },

        {
          path:'/left', // 路径
          name:'left',
          component:left // 组件
        },

        {
          path:'/newleft', // 路径
          name:'newleft',
          component:newleft // 组件
        },

        {
          path:'/menutree', // 路径
          name:'menutree',
          component:menutree // 组件
        },

        {
          path:'/register', // 路径
          name:'register',
          component:register // 组件
        },

        {
          path:'/userinfo', // 路径
          name:'userinfo',
          component:userinfo // 组件
        },

        {
          path:'/login', // 路径
          name:'login',
          component:login // 组件
        },
        {
          path:'/logins', // 路径
          name:'logins',
          component:logins // 组件
        },
        {
          path:'/shopMag', // 路径
          name:'shopMag',
          component:shopMag // 组件
        },
        {
          path:'/shop', // 路径
          name:'shop',
          component:shop // 组件
        },
        {
          path:'/lotteryDraw', // 路径
          name:'lotteryDraw',
          component:lotteryDraw
        },
        {
          path:'/main', // 路径
          name:'main',
          component:main
        }

        


]





const router = new createRouter({
  history: createWebHashHistory(),
  // base: '/add/',
  routes,
})
// router.afterEach((to,from,next)=>{
//   	if (!localStorage.getItem("uid")) {
// 	    next({
//         path:'/login'
//       })
//   	} else {
//     	next()
//   	}
// })


export {
    router
}
