<template>
<div>
		
		<h1 align="center">欢迎您</h1>
		<div class="go" v-if="username == '' ">
			<!-- <router-link to="/login" class="font">去登录</router-link>
			<br>
			<router-link to="/register" class="font">去注册</router-link> -->

			<ul>
				<li><router-link to="/login" class="font">去登录</router-link></li>
				<li><router-link to="/register" class="font">去注册</router-link></li>
			</ul>
			
		</div>
		<div v-else>
				<h4 align="center">{{ username }}来到学以致用平台</h4>
				<h4 align="center">学习成长值：{{score}}</h4>
				<h4 align="center">诲人不倦值：{{points}}</h4>
				<h4 align="center"><van-button @click="go_out" round style="width: 50%; background-color: #00508f; color: white;">登出</van-button></h4>


				<menutree @val="get_val" :data="menulist">
					
				</menutree>

				      <!-- </el-menu>
				    </el-col>
				</el-row> -->

		</div>

		
		{{}}

	
</div>	
</template>


<script>

import menutree from "./menutree.vue"
	
export default{

	name:"left",
	props:["data"],

	components:{
		"menutree" : menutree,
	},

	data() {
		return{

			username : "",
			menulist:"",
			score:"",
			points:"",
			did : "",
			stuid:"",

		}

	},

	setup(){

		function get_val(val){
			console.log(val)
		}

	},


	methods:{


		get_val(val){

			console.log(val)

			if(val.id){

				this.stuid = val.id

			}else{

				this.did = val.did

			}

		},


		go_out(){
			// 删除浏览器存储的信息
			localStorage.removeItem("username")
			localStorage.removeItem("uid")
			// location.reload()
			this.$router.push({path:"/login"})

		},

		// 获取菜单数据
		get_menu(){

			this.myaxios("/menu/","get",{"uid":localStorage.getItem("uid")})
			.then(data=>{
				this.menulist = data.data
			})

		},

		// 获取新菜单数据
		get_newMenu(){

			this.myaxios("/newmenu/","get",{"uid":localStorage.getItem("uid")})

			.then(data=>{
				this.menulist = data.data
			})

		},

		// 积分获取
		get_score:function(){
			this.myaxios("/integral/","get",{"uid":localStorage.getItem("uid")})
			.then(data=>{
				console.log(data.data)

				this.score = data.data.score
				this.points = data.data.points
			})
		}
		
	},
	created(){

	},
	mounted(){
		// 判断浏览器中是否已存在用户名
		if(localStorage.getItem("username") == null ){

			this.username = ""

		}else{

			this.username = localStorage.getItem("username")
			// this.get_menu()
			this.get_newMenu()
			this.get_score()



		}
	},

}

</script>

<style scoped>

@import url("../assets/style.css");


/*.font {

	font-size: 20px;
	color: black;

}*/

li{

	margin-bottom: 50px;
	/*margin-left: 50px;*/
	cursor: pointer;

}

.go{

	margin-bottom: 50px;
	margin-left: 50px;

}

/*.tac{
	width: 100%;
}*/




</style>