<template>
	<div>
		
	
		<el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
			<el-tab-pane label="员工积分详情" name="1"></el-tab-pane>
			<el-tab-pane label="员工报表" name="2"></el-tab-pane>
		</el-tabs>
	
		<div v-if="activeName == 1">
			<el-table :data="data">
				<el-table-column prop="username" label="姓名" />
				<el-table-column prop="postion" label="岗位" />
				<el-table-column prop="points" label="诲人不倦" />
				<el-table-column prop="score" label="学习成长" />
				<el-table-column prop="name" label="公司" 
					:filters="[
									{ text: '嘉兴公司', value: '嘉兴公司' },
									{ text: '深圳公司', value: '深圳公司' },
									{ text: '成都公司', value: '成都公司' },
									{ text: '济南公司', value: '济南公司' },
									{ text: '长沙公司', value: '长沙公司' },
									{ text: '北京公司', value: '北京公司' },
									{ text: '郑州公司', value: '郑州公司' },
									{ text: '淄博公司', value: '淄博公司' },
								]"
								:filter-method="filterTag"
				/>
				<el-table-column prop="make1" label="自制视频" />
				<el-table-column prop="make2" label="非自制视频" />
				
				<el-table-column align="right">
					<template #header>
						<!-- <el-button @click="addTrue(0,1)"> 添加菜单 </el-button> -->
					</template>
					<template #default="scope">
						
						<el-button
						
						type="success"
						@click="getUserinfoShow(scope.row.id)">
						查看
						</el-button>
	
						
	
	
					</template>
				</el-table-column>
	
	
			</el-table>
		</div>
	
		<div v-if="activeName == 2">
			<br>
			起始日期:<input type="date" v-model="start_time" name=""/>&nbsp;
			结束日期:<input type="date" v-model="end_time" name="">&nbsp;
			查询类型:<el-select v-model="event" >
						  <el-option label="学习成长值" :value="1" />
						  <el-option label="诲人不倦值" :value="2" />
						  <el-option label="自制视频次数" :value="3" />
						  <el-option label="非自制视频次数" :value="4" />
						  <el-option label="总上传视频" :value="5" />
					  </el-select>&nbsp;
				<el-button @click="PostImg">点击查询</el-button>
	
				<div id="myChart" style="width: 800px; height: 400px;">
					
				</div>
			
		</div>
	
	
		
	
	
		
	
	
			<el-dialog
				v-model="infoShow"
				title="查看"
				width="75%"
				:before-close="handleClose"
				destroy-on-close
			>
				<el-tabs v-model="event" class="demo-tabs" @tab-click="getUserinfo">
					<el-tab-pane label="学习成长" name="1"></el-tab-pane>
					<el-tab-pane label="诲人不倦" name="2"></el-tab-pane>
				</el-tabs>
	
				<el-table :data="info">
					<el-table-column prop="name" label="视频名称" />
					<el-table-column prop="create_time" label="得分时间" />
					<el-table-column prop="score" label="积分" />
					<el-table-column v-if="event == '2'" prop="username" label="打分人" />
	
				</el-table>
				
				
			</el-dialog>
	
	
	</div>	
	</template>
	
	
	<script>
	import { ElLoading } from 'element-plus'
	
	
	export default{
		components:{
	
			
	
		},
	
		data() {
			return{
	
				activeName:"",
				data:"",
				event:"",
				start_time:"2022-01-01",
				end_time:"2022-12-31",
				
				infoShow:false,
				info:"",
				uid:"",
				event:"1",
				is_uid:"",
	
			}
	
		},
	
	
		methods:{
	
			filterTag(value,row){
	
				return row.name === value
	
			},
	
			getUserinfoShow(uid){
				this.infoShow = true
				this.is_uid = uid
				this.getUserinfo()
	
			},
	
			getUserinfo(){
				
				this.myaxios("/integralstatements/","get",{"uid":this.is_uid,"event":this.event})
				.then(data=>{
					
					this.info = data.data.vlist
					this.infoShow = true
					
				})
			},
	
			
	
			handleClick(){
				if(this.activeName == 1){
					this.getData()
	
				}
			},
	
			PostImg(){
	
				console.log(this.start_time)
				console.log(this.end_time)
	
				this.myaxios("/integralstatements/","put",{"uid":localStorage.getItem("uid"),"start_time":this.start_time,"end_time":this.end_time,"event":this.event})
				.then(data=>{
	
					
	
					if(data.code == 403){
	
						this.$toast.fail(data.msg)
	
					}
					else if(data.code == 200){
	
						// console.log(JSON.parse(data.ylist))
						console.log(data.ylist)
	
						let myChart = this.$echarts.init(
								  document.getElementById("myChart")
							  );
	
							  myChart.setOption({
								title:{text:data.title},
								tooltip:{},
								xAxis:{
								  data:data.xlist,
								  // type: 'category',
								  name:"日期",
								  axisLabel:{
									  // show:true,
									  // interval:0
								  }
								},
								yAxis:{
									name:"分数/次数"
								},
								series:[
								  {
									type:"line",
									data:data.ylist,
									lineStyle:{
										normal:{
											color:'#00008f'
										}
									}
								  }
								],
	
							  })
	
	
	
					}
					
	
				})
	
			},
	
	
			getData(){
				this.myaxios("/integralstatements/","delete",{"uid":localStorage.getItem("uid")})
				.then(data=>{
					this.data = data.data
				})
			}
	
	
			
	
		},
		created(){
	
	
			
	
		},
		mounted(){
	
			
			this.getData()
	
	
		},
		 
	
	}
	
	</script>
	
	<style scoped>
	
	@import url("../assets/style.css");
	
	
	/*video::-webkit-media-controls-timeline {
			display: none;
		}*/
	
	h4{
		color: red;
	}
	
	p{
		text-align: center;
		font-size: 50px;
	}
	
	.divps{
		padding-top: 50px;
		padding-left: 144px;
	}
	
	.el-dialog__body {
	
		text-align: center;
	}
	
	/*.comment{
		position: absolute;
			top: 20%;
			bottom: auto;
			left: 0;
			right: 0;
	}*/
	
	</style>